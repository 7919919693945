export const DEFAULT_QUERY_OPTIONS = {
  staleTime: 300000, // 5分
  retry: false
}

export const QUERY_STRING = {
  default: {
    page: 1
  },
  campaigns: {
    page: 1,
    statuses: [10, 30, 40]
  },
  lottery: {
    page: 1,
    isFollower: ''
  },
  instantwin: {
    page: 1,
    isWin: ''
  },
  accountPosts: {
    page: 1,
    publishAtFrom: '',
    publishAtTo: '',
    statuses: [10, 20, 30, 40, 50, 90],
    isAutoReply: 0,
    postCategoryTemplateId: ''
  }
}

type TPAGEING = {
  sm: number
  md: number
  lg: number
  xl: number
  all: number
}

const PER_PAGE = 10
export const PAGING_SIZE: TPAGEING = {
  sm: PER_PAGE, // 10
  md: PER_PAGE * 2, // 20
  lg: PER_PAGE * 2.5, // 25
  xl: PER_PAGE * 5, // 50
  all: 0
}
