import { TRegisterPostCommentOrder, TPostCommentOrder, PostComment } from '../models/PostCommentInfo'
import { TRegisterResult, TError } from 'src/modules/entity/API'
import actionCreatorFactory from 'typescript-fsa'
import { List } from 'immutable'

const actionCreator = actionCreatorFactory('POST_COMMENT')

/***
 * get for saga
 * set for reducer
 */
export const PostCommentActions = {
  initializePostComment: actionCreator('INITIALIZE_POST_COMMENT'),

  /*** comment ***/
  registerPostComment: actionCreator<TRegisterPostCommentOrder>('REGISTER_POST_COMMENT'),
  getIniPostComments: actionCreator<TPostCommentOrder>('GET_INI_POST_COMMENTS'),
  getAddPostComments: actionCreator<TPostCommentOrder>('GET_ADD_POST_COMMENTS'),

  setPostComments: actionCreator<List<PostComment>>('SET_POST_COMMENTS'),
  setMorePostComments: actionCreator<boolean>('SET_MORE_POST_COMMENTS'),
  setPostCommentsNextPage: actionCreator<number>('SET_POST_COMMENTS_PAGE_NUM'),
  setPostCommentsRefresh: actionCreator<boolean>('SET_POST_COMMENTS_REFRESH'),
  setRegisterPostComment: actionCreator<TRegisterResult>('SET_REGISTER_POST_COMMENTS'),

  setError: actionCreator<TError>('SET_ERROR'),
  setLoading: actionCreator<boolean>('SET_LOADING'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
