import isEmpty from 'lodash/isEmpty'
import { TSnses } from 'src/modules/entity/API'

export const basePageUrlPerEnvironments = () => {
  // eslint-disable-next-line
  switch (process.env.REACT_APP_STAGE) {
    case 'stg':
      return 'https://stg.owgi.jp'
    case 'prd':
      return 'https://owgi.jp'
    default:
      return 'http://localhost:8888'
  }
}

const getAccountPath = (segment: string, brandId?: string | number, mId?: string, provider?: TSnses) => {
  const hashes =
    isEmpty(brandId) && isEmpty(mId) && isEmpty(provider)
      ? { brandId: ':brandId', mId: ':mId', provider: ':provider' }
      : { brandId: brandId, mId: mId, provider: provider }
  return `/brand/${hashes.brandId}/account/${hashes.mId}/provider/${hashes.provider}/${segment}`
}

export const ACCOUNT_SEGMENTS = {
  post: 'post',
  managed_posts: 'managed_posts',
  hashtags: 'hashtags',
  posts: 'posts',
  reels: 'reels',
  stories: 'stories',
  mentions: 'mentions',
  comments: 'comments',
  analytics: 'analytics',
  rivals: 'rivals',
  complement: 'complement',
  settings: 'settings',
  taggedPosts: 'tagged_posts'
}

export const ROUTES = {
  organizationTop: () => '/',
  brandTop: (brandId: string) => `/brand/${brandId}`,
  signIn: (redirectTo?: string) => (redirectTo ? `/signin?redirectTo=${redirectTo}` : '/signin'),
  signOut: () => '/signout',
  signUp: () => '/sign/up/:hash', // TODO 新API対応時 '/signup/:hash' に変更 (sign 系URIフォーマット統一)
  forgetPassword: () => '/forget-password',
  passwordReset: () => '/sign/passwordreset/:hash',
  users: (brandId: string) => `/brand/${brandId}/users`,
  userEdit: (brandId: string, userId: string) => `/brand/${brandId}/users/${userId}/edit`,
  brands: () => '/brands',
  brandDetail: (brandId: string) => `/brands/${brandId}`,
  brandNew: () => '/brands/new',
  organizations: () => '/organizations',
  organizationDetail: (organizationSlug: string) => `/organizations/${organizationSlug}`,
  organizationNew: () => '/organizations/new',
  organizationPostCsv: () => '/organizations/post/csv',
  invitation: (brandId: string) => `/brand/${brandId}/invitation`,
  accounts: (brandId: string) => `/brand/${brandId}/accounts`,
  post: (brandId: string | number, postId: string) => `/brand/${brandId}/post/${postId}`,
  accountMngedPosts: (brandId?: number | string, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.managed_posts, brandId, mId, provider),
  accountPosts: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.posts, brandId, mId, provider),
  accountReels: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.reels, brandId, mId, provider),
  accountHashtags: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.hashtags, brandId, mId, provider),
  accountStories: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.stories, brandId, mId, provider),
  // accountMentions: (brandId?: string | number, mId?: string, provider?: TSnses) =>
  //   getAccountPath(ACCOUNT_SEGMENTS.mentions, brandId, mId, provider),
  accountComments: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.comments, brandId, mId, provider),
  accountAnalytics: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.analytics, brandId, mId, provider),
  accountRivalsAnalytics: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.rivals, brandId, mId, provider),
  accountComplement: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.complement, brandId, mId, provider),
  accountSettings: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.settings, brandId, mId, provider),
  accountTaggedPosts: (brandId?: string | number, mId?: string, provider?: TSnses) =>
    getAccountPath(ACCOUNT_SEGMENTS.taggedPosts, brandId, mId, provider),
  accountEmpty: (brandId: string) => `/brand/${brandId}/post/0`,
  accountList: (brandId: string) => `/brand/${brandId}/accounts`,
  library: (brandId: string) => `/brand/${brandId}/library`,
  mediaTags: (brandId: string) => `/brand/${brandId}/mediaTags`,
  oauth: (brandId: string) => `/brand/${brandId}/oauth`,
  oauthCallback: () => '/oauth/callback',
  tools: () => '/tools',
  campaigns: (brandId: string) => `/brand/${brandId}/campaigns`,
  ugcList: (brandId: string) => `/brand/${brandId}/ugc`,
  ugcNew: (brandId: string) => `/brand/${brandId}/ugc/new`,
  ugc: (brandId: string, ugcId: string) => `/brand/${brandId}/ugc/${ugcId}`,
  postCategory: (brandId: string) => `/brand/${brandId}/post-category`,
  activities: (brandId: string) => `/brand/${brandId}/activities`,
  tos: () => '/tos',
  calendar: (brandId: string) => `/brand/${brandId}/calendar`,
  // followerCollect: (brandId: string) => `/brand/${brandId}/follower-collect`,
  brandBoundary: (brandId: string, redirectTo: string) => `/brand-boundary/${brandId}/${redirectTo}`,
  information: () => '/information',
  informationDetail: (infoId: string) => `/information/${infoId}`,
  errorCodeDecoder: () => '/error-code-decoder'
}
