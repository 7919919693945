import dayjs, { Dayjs } from 'dayjs'
import { List, Record } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import { InstagramBusinessAccountEle } from 'src/modules/entity/account'
import { TPaging } from 'src/modules/entity/API'
import { JSObject } from 'types/common'
import { TSnses } from 'src/modules/entity/API'

dayjs.locale('ja')

/*** order models ***/

/**
 * Insight詳細データ
 * from、toデーページング想定だが、役に立たなさそう
 * page? = 未使用
 */
export type TAccountOrder = {
  snsAccount: {
    mId: string
    provider?: string
  }
  insight?: {
    collectAtFrom: string
    collectAtTo: string
  }
}

/**
 * Insight一覧データ
 * pageでページング
 */
export type TAccountsOrder = {
  provider?: TSnses
  paging?: TPaging
}

/**
 * アカウント削除
 */
export type TAbolishOrder = {
  snsAccount: {
    id: string
  }
}

/*** store models
 *  abolish
 *  account
 *  accounts: account[
 *    instagramInsightUser
 *    instagramInsightMedia
 *    twitterInsightUser
 *    twitterInsightMedia
 *  ]
 *  daterange
 ***/
type TAbolish = {
  note: string
  effectedId: string
}
export class Abolish extends Record<TAbolish>({
  note: '',
  effectedId: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Abolish(params)
  }
}

export type TInstagramBusinessAccount = {
  id: number
  name: string
  pictureUrl: string
  instagramBusinessAccountId: string
  facebookAccountId: string
  instagramAccountId: number
  facebookPageId: string
  userAccessToken: string
  pageAccessToken: string
  status: number
  registDate: Dayjs
  updateUserId: string
  createUserId: string
  deleteFlg: boolean
}
export class InstagramBusinessAccount extends Record<InstagramBusinessAccountEle>({
  id: 0,
  name: '',
  pictureUrl: '',
  instagramBusinessAccountId: '',
  facebookAccountId: '',
  instagramAccountId: 0,
  facebookPageId: '',
  userAccessToken: '',
  pageAccessToken: '',
  status: 0,
  registDate: '',
  updateUserId: '',
  createUserId: '',
  deleteFlg: false
}) {
  static fromResponse(params: JSObject) {
    params = { ...params }
    return new InstagramBusinessAccount(params)
  }
  static iniList(params: JSObject[]): List<InstagramBusinessAccount> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => InstagramBusinessAccount.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<InstagramBusinessAccount>) {
    if (isEmpty(params)) {
      return List()
    }
    return exist.concat(params.map(x => InstagramBusinessAccount.fromResponse(x)))
  }
}

export type TInstagramInsightUser = {
  userId: string
  userIgId: string
  username: string
  name: string
  profilePictureUrl: string
  collectAt: Dayjs
  mediaCount: number
  followerCount: number
  followersCount: number
  followsCount: number
  followsCountUpDown: number
  followersCountUpDown: number
  impressions: number
  reach: number
  profileViews: number
  getDirectionsClicks: number
  textMessageClicks: number
  websiteClicks: number
  phoneCallClicks: number
  emailContacts: number
}
export class InstagramInsightUser extends Record<TInstagramInsightUser>({
  userId: '',
  userIgId: '',
  username: '',
  name: '',
  profilePictureUrl: '',
  collectAt: dayjs(),
  mediaCount: 0,
  followerCount: 0,
  followersCount: 0,
  followsCount: 0,
  followsCountUpDown: 0,
  followersCountUpDown: 0,
  impressions: 0,
  reach: 0,
  profileViews: 0,
  getDirectionsClicks: 0,
  textMessageClicks: 0,
  websiteClicks: 0,
  phoneCallClicks: 0,
  emailContacts: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.collectAt = dayjs(params.collectAt)
    return new InstagramInsightUser(params)
  }
  static iniList(params: JSObject[]): List<InstagramInsightUser> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => InstagramInsightUser.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<InstagramInsightUser>): List<InstagramInsightUser> {
    if (isEmpty(params)) {
      return List()
    }
    return exist.concat(params.map(x => InstagramInsightUser.fromResponse(x)))
  }
}

export type TInstagramInsightMedia = {
  userId: string
  userIgId: string
  mediaId: string
  mediaIgId: string
  mediaType: 'VIDEO' | 'CAROUSEL_ALBUM' | 'IMAGE'
  mediaUrl: string
  paths: List<string>
  shortcode: string
  permalink: string
  caption: string
  timestamp: Dayjs
  tags: List<string>
  commentsCount: number
  likeCount: number

  impressions?: number
  reach?: number
  engagement?: number
  saved?: number
  carouselAlbumImpressions?: number
  carouselAlbumEngagement?: number
  carouselAlbumReach?: number
  carouselAlbumSaved?: number
  carouselAlbumVideoViews?: number

  isCommentEnabled: boolean
  collectAt: Dayjs
}
export class InstagramInsightMedia extends Record<TInstagramInsightMedia>({
  userId: '',
  userIgId: '',
  mediaId: '',
  mediaIgId: '',
  mediaType: 'IMAGE',
  mediaUrl: '',
  paths: List(),
  shortcode: '',
  permalink: '',
  caption: '',
  timestamp: dayjs(),
  tags: List(),
  commentsCount: 0,
  likeCount: 0,

  impressions: 0,
  engagement: 0,
  reach: 0,
  saved: 0,
  carouselAlbumImpressions: 0,
  carouselAlbumEngagement: 0,
  carouselAlbumReach: 0,
  carouselAlbumSaved: 0,
  carouselAlbumVideoViews: 0,

  isCommentEnabled: false,
  collectAt: dayjs()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.paths = List(params.paths)
    params.timestamp = dayjs(params.timestamp)
    params.collectAt = dayjs(params.collectAt)
    params.tags = isEmpty(params.tags) ? [] : split(params.tags, ',')
    return new InstagramInsightMedia(params)
  }
  static iniList(params: JSObject[]): List<InstagramInsightMedia> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => InstagramInsightMedia.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<InstagramInsightMedia>): List<InstagramInsightMedia> {
    if (isEmpty(params)) {
      return List()
    }
    return exist.concat(params.map(x => InstagramInsightMedia.fromResponse(x)))
  }
}

export type TTwitterInsightUser = {
  mId: string
  friend: number
  engageRate: number
  likes: number
  share: number
  engage: number
  mediaCount: number
  comments: number
  registDate: Dayjs
  followedBy: number
  followsCountUpDown: number
  followersCountUpDown: number
}
export class TwitterInsightUser extends Record<TTwitterInsightUser>({
  mId: '',
  friend: 0,
  engageRate: 0,
  likes: 0,
  share: 0,
  engage: 0,
  mediaCount: 0,
  comments: 0,
  registDate: dayjs(),
  followedBy: 0,
  followsCountUpDown: 0,
  followersCountUpDown: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.registDate = dayjs(params.registDate)
    return new TwitterInsightUser(params)
  }
  static iniList(params: JSObject[]): List<TwitterInsightUser> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => TwitterInsightUser.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<TwitterInsightUser>): List<TwitterInsightUser> {
    if (isEmpty(params)) {
      return List()
    }
    return exist.concat(params.map(x => TwitterInsightUser.fromResponse(x)))
  }
}

export type TTwitterInsightMedia = {
  id: number
  mId: string
  mediaId: string
  mediaType: 'テキスト' | '画像' | '動画' | 'GIF' | 'photo' | 'video' | 'animated_gif' | 'text' | ''
  tags: List<string>
  caption: string
  link: string
  comments: number
  likes: number
  share: number
  paths: List<string>
  lastUpdateDate: Dayjs
  createdTime: Dayjs
  deleteFlg: boolean
}
export class TwitterInsightMedia extends Record<TTwitterInsightMedia>({
  id: 0,
  mId: '',
  mediaId: '',
  mediaType: '',
  tags: List(),
  caption: '',
  link: '',
  comments: 0,
  likes: 0,
  share: 0,
  paths: List(),
  lastUpdateDate: dayjs(),
  createdTime: dayjs(),
  deleteFlg: false
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.paths = List(params.paths)
    params.tags = params.tags ? List(params.tags.split(',')) : List()
    params.lastUpdateDate = dayjs(params.lastUpdateDate)
    params.createdTime = dayjs(params.createdTime)
    return new TwitterInsightMedia(params)
  }
  static iniList(params: JSObject[]): List<TwitterInsightMedia> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => TwitterInsightMedia.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<TwitterInsightMedia>): List<TwitterInsightMedia> {
    if (isEmpty(params)) {
      return List()
    }
    return exist.concat(params.map(x => TwitterInsightMedia.fromResponse(x)))
  }
}

export type TAccount = {
  accountDetailPictureUrl: string
  accountId: number
  comments: number
  engage: number
  engageRate: number
  followedBy: number
  friend?: number //  only twitter
  isAutoPublish?: boolean
  isBusiness?: boolean
  isCollectMention?: boolean //  only twitter
  isCollectComment?: boolean
  isEnabledComment?: boolean // only Instagram
  lastPostedDate: string
  likes: number
  mId: string
  mediaCount: number
  nickname: string
  provider: TSnses
  replySettings?: 10 | 20 | 30
  share?: number //  only twitter
  snsAccountId: number
  status: number
  uniqueId: string
  instagramBusinessAccount?: List<InstagramBusinessAccount>
  instagramInsightUser?: List<InstagramInsightUser>
  instagramInsightMedia?: List<InstagramInsightMedia>
  twitterInsightUser?: List<TwitterInsightUser>
  twitterInsightMedia?: List<TwitterInsightMedia>
}
export class Account extends Record<TAccount>({
  snsAccountId: 0,
  provider: '',
  mId: '',
  accountId: 0,
  uniqueId: '',
  nickname: '',
  accountDetailPictureUrl: '',
  isAutoPublish: false,
  isCollectMention: false,
  isBusiness: false,
  mediaCount: 0,
  lastPostedDate: '',
  status: 0,
  followedBy: 0,
  friend: 0,
  likes: 0,
  replySettings: 10,
  share: 0,
  comments: 0,
  engage: 0,
  engageRate: 0,
  instagramBusinessAccount: List(),
  instagramInsightUser: List(),
  instagramInsightMedia: List(),
  twitterInsightUser: List(),
  twitterInsightMedia: List()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.lastPostedDate = dayjs(params.lastPostedDate)
    params.instagramBusinessAccount = InstagramBusinessAccount.iniList(params.instagramBusinessAccount)
    params.instagramInsightUser = InstagramInsightUser.iniList(params.instagramInsightUser)
    params.instagramInsightMedia = InstagramInsightMedia.iniList(params.instagramInsightMedia)
    params.twitterInsightUser = TwitterInsightUser.iniList(params.twitterInsightUser)
    params.twitterInsightMedia = TwitterInsightMedia.iniList(params.twitterInsightMedia)
    return new Account(params)
  }
  static getInsights(response: JSObject) {
    const params = response[0]
    params.lastPostedDate = dayjs(params.lastPostedDate)
    params.instagramBusinessAccount = List(
      params.instagramBusinessAccount &&
        params.instagramBusinessAccount.map((item: JSObject) => InstagramBusinessAccount.fromResponse(item))
    )
    params.instagramInsightUser = List(
      params.instagramInsightUser &&
        params.instagramInsightUser.map((item: JSObject) => InstagramInsightUser.fromResponse(item))
    )
    params.instagramInsightMedia = List(
      params.instagramInsightMedia &&
        params.instagramInsightMedia.map((item: JSObject) => InstagramInsightMedia.fromResponse(item))
    )
    params.twitterInsightUser = List(
      params.twitterInsightUser &&
        params.twitterInsightUser.map((item: JSObject) => TwitterInsightUser.fromResponse(item))
    )
    params.twitterInsightMedia = List(
      params.twitterInsightMedia &&
        params.twitterInsightMedia.map((item: JSObject) => TwitterInsightMedia.fromResponse(item))
    )
    return new Account(params)
  }
  static iniList(params: JSObject[]): List<Account> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => Account.fromResponse(x))).sortBy(a => a.snsAccountId)
  }
  static addList(params: JSObject[], exist: List<Account>): { list: List<Account>; hasMore: boolean } {
    if (isEmpty(params)) {
      return { list: exist, hasMore: false }
    }
    return { list: exist.concat(Account.iniList(params).sortBy(a => a.snsAccountId)), hasMore: true }
  }
  static setAccount(params: TAccount): Account {
    if (isEmpty(params)) {
      return new Account()
    }
    return new Account(params)
  }
}
