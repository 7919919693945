import { connectRouter, RouterState } from 'connected-react-router/immutable'
import { History } from 'history'
import { combineReducers } from 'redux'
import ApprovalReducer, { ApprovalState } from 'src/redux/reducers/ApprovalReducer'
import AuthReducer, { AuthState } from 'src/redux/reducers/AuthReducer'
import CalendarReducer, { CalendarState } from 'src/redux/reducers/CalendarReducer'
import DaterangeReducer, { DaterangeState } from 'src/redux/reducers/DatarangeReducer'
import InsightReducer, { InsightState } from 'src/redux/reducers/InsightReducer'
import LibraryReducer, { LibraryState } from 'src/redux/reducers/LibraryReducer'
import OauthReducer, { OauthState } from 'src/redux/reducers/OauthReducer'
import PostCommentReducer, { PostCommentState } from 'src/redux/reducers/PostCommentReducer'
import PostReducer, { PostState } from 'src/redux/reducers/PostReducer'
import SideMenusReducer, { SideMenusState } from 'src/redux/reducers/SideMenuReducer'
import MediasReducer, { MediasState } from './MediasReducer'
export interface State {
  [key: string]: any
  router: RouterState
  Auth: AuthState
  Calendar: CalendarState
  Insight: InsightState
  Post: PostState
  PostComment: PostCommentState
  Approval: ApprovalState
  Oauth: OauthState
  Daterange: DaterangeState
  Library: LibraryState
  SideMenus: SideMenusState
  Medias: MediasState
  _persist: any
}

export const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    Auth: AuthReducer,
    Calendar: CalendarReducer,
    Insight: InsightReducer,
    Post: PostReducer,
    PostComment: PostCommentReducer,
    Approval: ApprovalReducer,
    Oauth: OauthReducer,
    Daterange: DaterangeReducer,
    Library: LibraryReducer,
    SideMenus: SideMenusReducer,
    Medias: MediasReducer
  })
