import { useMutation, UseMutationResult } from 'react-query'
import { ApiClient } from 'src/redux/api/apiClient'
import { TReportDownloadOrder } from 'src/redux/models/ReportInfo'
import { JSObject } from 'types/common'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { Report } from '../entity/report'

const apiClient = new ApiClient({
  responseType: 'blob',
  dataType: 'binary'
})

export class ReportApi {
  static post(path: string, params: JSObject, headers?: TApiHeadersCommon): Promise<{}> {
    return apiClient.post(path, params, headers)
  }
  static getReportExcel = (params: TReportDownloadOrder, headers: TApiHeadersCommon) =>
    ReportApi.post('/reports/excel', params, headers)

  static getReportPptx = (params: TReportDownloadOrder, headers: TApiHeadersCommon) =>
    ReportApi.post('/reports/powerpoint', params, headers)
}

export const useDownloadExcelReportMutation = (): UseMutationResult<{ data?: Report }, any, TReportDownloadOrder> => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useMutation((payload: TReportDownloadOrder) => ReportApi.getReportExcel(payload, { token }), {
    mutationKey: 'getReports'
  })
}

export const useDownloadPPTXReportMutation = (): UseMutationResult<{ data?: Report }, any, TReportDownloadOrder> => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useMutation((payload: TReportDownloadOrder) => ReportApi.getReportPptx(payload, { token }), {
    mutationKey: 'getReports'
  })
}
