import { useMutationWrapper } from 'src/modules/core/mutation'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { UploadVariables } from 'src/modules/entity/upload'
import { CommonApi } from 'src/redux/api/commonApi'

export class UploadApi extends CommonApi {
  static uploadProviderData = (params: UploadVariables, headers: TApiHeadersCommon) => {
    const { upload, provider, mId } = params
    const formData = new FormData()
    formData.append('upload', upload)
    return UploadApi.postForm(`/upload/${provider}/${mId}`, formData, headers)
  }
}

export const useUploadMutation = () => {
  return useMutationWrapper<UploadVariables>({
    req: (payload, { token }) => UploadApi.uploadProviderData(payload, { token })
  })
}
