import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import { DEFAULT_QUERY_OPTIONS } from 'src/constants/query'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { useErrors } from 'src/modules/core/errors'
import { QUERY_KEYS } from 'src/modules/core/queryKeys'
import { ReactQueryResult } from 'src/modules/entity/API'
import { State } from 'src/redux/reducers'
import { AxiosResponse } from 'types/axios'

type Props<T> = {
  queryKey?: QUERY_KEYS
  deps?: QueryKey
  options?: UseQueryOptions
  req: ({ token }: { token: string }) => Promise<AxiosResponse<T>>
}

/**
 * 全てのリクエストにブランドID、JWTを追加します
 * reduxのState Auth.userからtokenを取り出して設定します
 * reduxのState Auth.userからcurrentBrand.idを取り出して設定します
 * brandId, queryKey, deps で変化を判定します。
 */
export const useQueryWrapper = <QueryResultType>({
  queryKey,
  deps = [],
  options,
  req
}: Props<QueryResultType>): ReactQueryResult<QueryResultType> => {
  const { setError } = useErrors()
  const token = useShallowEqualSelector((state: State) => state.Auth.getIn(['user', 'token']))
  const currentBrand = useShallowEqualSelector((state: State) => state.Auth.getIn(['user', 'currentBrand'])).toObject()
  const brandId = currentBrand?.id || ''
  const k = Array.isArray(deps) ? [queryKey, { brandId }, ...deps] : [queryKey, { brandId }]
  const result = useQuery(
    k,
    async () => {
      const res = await req({ token })

      if (res.error) {
        setError(res)
        throw res
      }
      return res
    },
    { ...DEFAULT_QUERY_OPTIONS, ...options }
  )

  return result
}
