import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import { OrganizationInsightQuery, OrganizationInsightVariables } from 'src/modules/entity/organizationInsight'
import { CommonApi } from 'src/redux/api/commonApi'

export class OrganizationInsightApi extends CommonApi {
  static fetch = (params: OrganizationInsightVariables, headers: TApiHeadersCommon) =>
    OrganizationInsightApi.get<OrganizationInsightQuery>('/insight/organization', params, headers)
}

export const useOrganizationInsightQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<OrganizationInsightVariables>): ReactQueryResult<OrganizationInsightQuery> => {
  return useQueryWrapper<OrganizationInsightQuery>({
    queryKey: 'organizationInsight',
    deps: key,
    req: ({ token }) => OrganizationInsightApi.fetch(payload, { token }),
    options
  })
}
