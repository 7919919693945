import { AuthState } from './../reducers/AuthReducer'
import { AnyAction, Dispatch, Middleware } from 'redux'
import { setHsToken, delHsToken } from 'src/helpers/authUtils'

export const hsTokenChanger: Middleware = store => (next: Dispatch<AnyAction>) => action => {
  const returned = next(action)
  if (action.type === 'persist/REHYDRATE' || action.type === 'SIGN/SET_SIGNED') {
    const authState: AuthState = store.getState().Auth.toJS()
    if (authState.user?.user?.email && authState.user?.hsVisitorToken) {
      const email = authState.user?.user.email
      const token = authState.user?.hsVisitorToken
      setHsToken(email, token)
      if (action.type === 'SIGN/SET_SIGNED') {
        window.HubSpotConversations?.widget?.load()
      }
    } else {
      delHsToken()
    }
  }
  return returned
}
