import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  CommentsQuery,
  EnableCommentVariables,
  GetCommentsVariables,
  RegisterCommentTargetVariables,
  ReplyCommentVariables,
  UpdateCommentVariables
} from 'src/modules/entity/comments'
import { CommonApi } from 'src/redux/api/commonApi'

export class CommentsApi extends CommonApi {
  static registerTargetAdd = (params: RegisterCommentTargetVariables, headers: TApiHeadersCommon) =>
    CommentsApi.post('/comments/ig/target/add', params, headers)
  static registerTargetRemove = (params: RegisterCommentTargetVariables, headers: TApiHeadersCommon) =>
    CommentsApi.post('/comments/ig/target/remove', params, headers)
  static list = (params: GetCommentsVariables, headers: TApiHeadersCommon) =>
    CommentsApi.get('/comments/ig', params, headers)
  static hide = (params: UpdateCommentVariables, headers: TApiHeadersCommon) =>
    CommentsApi.post('/comments/ig/hide', params, headers)
  static show = (params: UpdateCommentVariables, headers: TApiHeadersCommon) =>
    CommentsApi.post('/comments/ig/show', params, headers)
  static reply = (params: ReplyCommentVariables, headers: TApiHeadersCommon) =>
    CommentsApi.post('/comments/ig/reply', params, headers)
  static fetchCsv = (params: GetCommentsVariables, headers: TApiHeadersCommon) =>
    CommentsApi.get('/comments/ig/csv', params, headers)
  static enableSetting = (params: EnableCommentVariables, headers: TApiHeadersCommon) =>
    CommentsApi.post('/sns_accounts/comment_enabled', params, headers)
}

export const useRegisterCommentTargetAddMutation = () => {
  return useMutationWrapper<RegisterCommentTargetVariables>({
    req: (payload, { token }) => CommentsApi.registerTargetAdd(payload, { token })
  })
}
export const useRegisterCommentTargetRemoveMutation = () => {
  return useMutationWrapper<RegisterCommentTargetVariables>({
    req: (payload, { token }) => CommentsApi.registerTargetRemove(payload, { token })
  })
}

export const useHideCommentMutation = () => {
  return useMutationWrapper<UpdateCommentVariables>({
    req: (payload, { token }) => CommentsApi.hide(payload, { token })
  })
}

export const useShowCommentMutation = () => {
  return useMutationWrapper<UpdateCommentVariables>({
    req: (payload, { token }) => CommentsApi.show(payload, { token })
  })
}

export const useReplyCommentMutation = () => {
  return useMutationWrapper<ReplyCommentVariables>({
    req: (payload, { token }) => CommentsApi.reply(payload, { token })
  })
}

export const useEnableCommentMutation = () => {
  return useMutationWrapper<EnableCommentVariables>({
    req: (payload, { token }) => CommentsApi.enableSetting(payload, { token })
  })
}

export const useCommentsQuery = ({ payload, key, options }: TUseQueryOptions<GetCommentsVariables>) => {
  return useQueryWrapper<CommentsQuery[]>({
    queryKey: 'comments',
    deps: key,
    req: ({ token }) => CommentsApi.list({ ...payload, paging: { ...payload.paging } }, { token }),
    options
  })
}

export const useCommentsCsvQuery = ({ payload, key, options }: TUseQueryOptions<GetCommentsVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'igCommentsCsv',
    deps: key,
    req: ({ token }) => CommentsApi.fetchCsv(payload, { token }),
    options
  })
}
