import { CommonApi } from 'src/redux/api/commonApi'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from '../entity/API'
import { AccountTaggedMediasQuery, AccountTaggedMediasVariables } from '../entity/accountTaggedMedias'

class AccountTaggedMediasApi extends CommonApi {
  static getAccountTaggedMedias = (params: AccountTaggedMediasVariables, headers: TApiHeadersCommon) =>
    AccountTaggedMediasApi.get<AccountTaggedMediasQuery>('/ig/user_tagged_media', params, headers)
  static fetchCsv = (params: AccountTaggedMediasVariables, headers: TApiHeadersCommon) =>
    AccountTaggedMediasApi.get('/ig/user_tagged_media/csv', params, headers)
}

export const useAccountTaggedMediasQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<AccountTaggedMediasVariables>): ReactQueryResult<AccountTaggedMediasQuery> => {
  return useQueryWrapper<AccountTaggedMediasQuery>({
    queryKey: 'accountTaggedMedias',
    deps: key,
    req: ({ token }) => AccountTaggedMediasApi.getAccountTaggedMedias(payload, { token }),
    options
  })
}

export const useAccountTaggedMediasCsvQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<AccountTaggedMediasVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'accountTaggedMediasCsv',
    deps: key,
    req: ({ token }) => AccountTaggedMediasApi.fetchCsv(payload, { token }),
    options
  })
}
