/* eslint-disable array-callback-return */
import head from 'lodash/head'
import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import { PostCommentActions } from '../actions/PostComment'
import { PostApi } from '../api/postApi'
import { PostComment } from '../models/PostCommentInfo'
import { State } from '../reducers'

/** generator **/
function* registerPostComment(action: ReturnType<typeof PostCommentActions.registerPostComment>) {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const response = yield PostApi.registerPostComment(action.payload, { token })
  if (response.isSuccess) {
    yield put(PostCommentActions.setRegisterPostComment(response.data))
    yield put(PostCommentActions.setPostCommentsRefresh(true))
  } else {
    yield put(PostCommentActions.setError({ status: response.status, message: response.error.message }))
  }
}
function* getIniPostComments(action: ReturnType<typeof PostCommentActions.getIniPostComments>) {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  action.payload = { ...action.payload, ...{ paging: { page: 1 } } }
  const response = yield PostApi.getPostComments(action.payload, { token })
  if (!response.isSuccess) {
    yield put(PostCommentActions.setError({ status: response.status, message: response.error.message }))
    return
  }
  yield put(PostCommentActions.setPostComments(PostComment.iniList(response.data)))
  const post: any = head(response.data)
  yield put(PostCommentActions.setMorePostComments(!!post)) // for infinity load
  if (post) {
    return
  } else {
    yield put(PostCommentActions.setPostCommentsNextPage(2)) // for infinity load.
  }
}
function* getAddPostComments(action: ReturnType<typeof PostCommentActions.getAddPostComments>) {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const page = yield select((state: State) => state.PostComment.get('postCommentsNextPage'))
  const exists = yield select((state: State) => state.PostComment.get('postComments'))
  action.payload = { ...action.payload, ...{ paging: { page: page } } }
  const response = yield PostApi.getPostComments(action.payload, { token })
  if (!response.isSuccess) {
    yield put(PostCommentActions.setError({ status: response.status, message: response.error.message }))
    return
  }
  const res = PostComment.addList(response.data, exists)
  yield put(PostCommentActions.setPostComments(res.list))
  yield put(PostCommentActions.setMorePostComments(res.hasMore)) // for infinity load.
  yield put(PostCommentActions.setPostCommentsNextPage(page + 1 || 1)) // for infinity load.
}

/** watcher **/
export function* watchRegisterPostComment() {
  yield takeLatest(PostCommentActions.registerPostComment, registerPostComment)
}
export function* watchGetIniPostComments() {
  yield takeLatest(PostCommentActions.getIniPostComments, getIniPostComments)
}
export function* watchGetAddPostComments() {
  yield takeLatest(PostCommentActions.getAddPostComments, getAddPostComments)
}

/** saga **/
function* postCommentSaga(): any {
  yield all([fork(watchRegisterPostComment), fork(watchGetIniPostComments), fork(watchGetAddPostComments)])
}

export default postCommentSaga
