import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { TFederateOrder } from 'src/redux/models/OauthInfo'

export class OauthApi extends CommonApi {
  static verifyUrls = (headers: TApiHeadersCommon) => OauthApi.get('/oauth/verifyUrls', null, headers)
  static federate = (params: TFederateOrder, headers: TApiHeadersCommon) =>
    OauthApi.post('/oauth/federate', params, headers)
  static federateFacebook = (params: TFederateOrder, headers: TApiHeadersCommon) =>
    OauthApi.post('/oauth/federate/facebook', params, headers)
  static federateSecond = (params: TFederateOrder, headers: TApiHeadersCommon) =>
    OauthApi.post('/oauth/federate/twitter-2', params, headers)
}
