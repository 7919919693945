export const byte2Mbyte = (byteSize: number): number => byteSize / Math.pow(1024, 2)
export const between = (x: number, min: number, max: number): boolean => x >= min && x <= max

/**
 * A:B = C:x ←の比例式においてxを求めて返却
 *  @params outerTerm
 *  @params innerTermX
 *  @params innerTermY
 *
 **/
export const calcProportion = ({
  outerTerm,
  innerTermX,
  innerTermY
}: {
  outerTerm: number
  innerTermX: number
  innerTermY: number
}) => (innerTermX * innerTermY) / outerTerm


/**
 * 2つの数値に基づいパーセンテージを計算します
 * null またはゼロの場合、および出力する小数点数を指定します。
 * @param {number} numerator 分子
 * @param {number} denominator 分母
 * @param {number} [precision=2] 出力する小数点数の桁数 (デフォルト: 1)
 * @returns {number | string} 率 (小数点、または '---' エラーメッセージ)
 * SAMPLE 110 / 200 = 55%
 */
export function calcRatio(numerator?: number, denominator?: number, precision = 1): number | null {
  if (numerator===0){
    return 0
  }
  if (!numerator || !denominator) {
    return null
  } else if ( denominator > 0 && numerator > 0 ) {
    const ratio: number = (numerator / denominator) * 100
    const result = ratio.toFixed(precision)
    return parseFloat(result) * 1
  }
  return null
}

/**
 * パーセンテージの実数を計算します
 * null またはゼロの場合、および出力する小数点数を指定します。
 * @param {number} value
 * @param {number} percentage パーセンテージ
 * @returns {number | string}
 * SAMPLE 200 * 55% = 110
 */
export function calcPercentage(value: number, percentage: number): number | null  {
  if(percentage === 0){
    return 0
  }
  if (!value || !percentage || percentage <= 0 || percentage > 100) {
    return null
  }
  return  Math.round((value / 100) * percentage)
}


/**
 * 10進数を丸めたりする
 * from: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/floor
 *
 * @param {String}  type  'ceil' | 'round' | 'floor'
 * @param {Number}  value ターゲットの数字
 * @param {Integer} exp   何桁でceil, round, floor するか何桁でceil, round, floor するか
 * @returns {Number} The adjusted value.
 */
const decimalAdjust = ({
  type,
  value,
  exp
}: {
  type: 'ceil' | 'round' | 'floor'
  value: number
  exp: number
}): number => {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  let v: number = +value,
    e: number = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(v) || !(typeof e === 'number' && e % 1 === 0)) {
    return NaN
  }
  // Shift
  const shift = v.toString().split('e')
  const shifted = Math[type](+(shift[0] + 'e' + (shift[1] ? +shift[1] - e : -e)))
  // Shift back
  const shiftBacked = shifted.toString().split('e')
  return +(shiftBacked[0] + 'e' + (shiftBacked[1] ? +shiftBacked[1] + e : e))
}
// Decimal round
export const round = (value: number, exp: number) => decimalAdjust({ type: 'round', value, exp })
// Decimal floor
export const floor = (value: number, exp: number) => decimalAdjust({ type: 'floor', value, exp })
// Decimal ceil
export const ceil = (value: number, exp: number) => decimalAdjust({ type: 'ceil', value, exp })

/**
 * 小数点切り捨てる 率(%)版,
 * @param num
 * @param n 切り捨て桁数
 */
export const truncatePoint = (num: number, n = 2) => {
  return Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
}

type FormatNumber = {
  num: number
  isRate?: boolean
}
export const formatNumber = ({ num, isRate }: FormatNumber) => {
  if (!isNumber(num)) return '---'
  const n = isRate ? truncatePoint(num * 100) : Math.round(num)
  return n.toLocaleString('ja-JP')
}

export const isNumber = (value: unknown): boolean => typeof value === 'number' && isFinite(value)
