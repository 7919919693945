import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { useQueryWrapper } from '../core/query'
import { ReactQueryResult, TUseQueryOptions } from '../entity/API'
import { DailyInsightQuery, DailyInsightVariables } from '../entity/dailyInsight'

export class DailyInsightApi extends CommonApi {
  static fetch = (params: DailyInsightVariables, headers: TApiHeadersCommon) =>
    DailyInsightApi.get<DailyInsightQuery>('/insight/brand', params, headers)
}

export const useDailyInsightQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<DailyInsightVariables>): ReactQueryResult<DailyInsightQuery> => {
  return useQueryWrapper<DailyInsightQuery>({
    queryKey: 'dailyInsight',
    deps: key,
    req: ({ token }) => DailyInsightApi.fetch(payload, { token }),
    options
  })
}
