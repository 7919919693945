import React from 'react'
const nlRegex = /(\r\n|\r|\n)/g

export const nl2br = (str: string) =>
  str.split(nlRegex).map((line, i) =>
    line.match(nlRegex) ? (
      <>
        <br />
      </>
    ) : (
      line
    )
  )

export const ucfirst = (str: string) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : str)

export const isString = (str: unknown) => typeof str === 'string' || str instanceof String
