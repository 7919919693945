import React from 'react'
import Error404 from 'src/components/Error/Error404'
import { userBrandRoleType } from 'src/constants/user'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'

const RoleHOC = (Component: React.LazyExoticComponent<React.FC>, requireRole: keyof typeof userBrandRoleType) => {
  const RoleRouter = () => {
    const roleType = useShallowEqualSelector(state => state.Auth)
      .toObject()
      .user.toObject().roleType
    if (roleType <= userBrandRoleType[requireRole]) return <Component />
    return <Error404 />
  }
  return RoleRouter
}
export default RoleHOC
