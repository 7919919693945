import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from '../entity/API'
import { CommonApi } from 'src/redux/api/commonApi'
import { useQueryWrapper } from '../core/query'
import { useMutationWrapper } from '../core/mutation'
import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from '../core/errors'
import {
  InstantWinMutationVariables,
  InstantWinQueryVariables,
  InstantWinQuery,
  InstantWinManualRelationVariables,
  InstantWinStatusQuery,
  InstantWinUpdateMutationVariables,
  InstantWinUpdateLotteryMutationVariables
} from '../entity/campaignsInstantWin'
import { CampaignUpdateVariables } from '../entity/campaigns'

class InstantWinApi extends CommonApi {
  static register = (params: InstantWinMutationVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.post('/campaigns/instantwin', params, headers)
  static update = (params: InstantWinUpdateMutationVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.post(`/campaigns/instantwin/${params.campaign.id}`, params, headers)
  static abolish = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.post('/campaigns/abolish', params, headers)
  static approve = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.post('/campaigns/approval', params, headers)
  static fetch = (params: InstantWinQueryVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.get('/campaigns', params, headers)
  static select = (params: InstantWinQueryVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.get(`/campaigns/instantwin/${params.campaign.id}`, {}, headers)
  static status = (params: InstantWinQueryVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.get(`/campaigns/instantwin/${params.campaign.id}/status`, {}, headers)
  static manualRelation = (params: InstantWinManualRelationVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.post('/campaigns/instantwin/media_id', params, headers)
  static updateLottery = (id: number, params: InstantWinUpdateLotteryMutationVariables, headers: TApiHeadersCommon) =>
    InstantWinApi.post(`/campaigns/instantwin/${id}/lottery`, params, headers)
}

// 多分使わない
export const useInstantWinsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<InstantWinQueryVariables>): ReactQueryResult<InstantWinQuery[]> => {
  return useQueryWrapper<InstantWinQuery[]>({
    queryKey: 'instantWins',
    deps: key,
    req: ({ token }) => InstantWinApi.fetch(payload, { token }),
    options
  })
}

export const useInstantWinSelectQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<InstantWinQueryVariables>): ReactQueryResult<InstantWinQuery> => {
  return useQueryWrapper<InstantWinQuery>({
    queryKey: 'instantWinSelect',
    deps: key,
    req: ({ token }) => InstantWinApi.select(payload, { token }),
    options
  })
}

export const useInstantWinStatusQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<InstantWinQueryVariables>): ReactQueryResult<InstantWinStatusQuery> => {
  return useQueryWrapper<InstantWinStatusQuery>({
    queryKey: 'instantWinStatus',
    deps: key,
    req: ({ token }) => InstantWinApi.status(payload, { token }),
    options
  })
}

export const useCreateInstantWinMutation = () => {
  return useMutationWrapper<InstantWinMutationVariables>({
    req: (payload, { token }) => InstantWinApi.register(payload, { token })
  })
}

export const useUpdateInstantWinMutation = () => {
  return useMutationWrapper<InstantWinUpdateMutationVariables>({
    req: (payload, { token }) => InstantWinApi.update(payload, { token })
  })
}

export const useAbolishInstantWinMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => InstantWinApi.abolish(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '廃止にできませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '廃止にしました' })
      }
    }
  })
}

export const useApproveInstantWinMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => InstantWinApi.approve(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '確認済みにできませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '確認済みにしました' })
      }
    }
  })
}

export const useInstantWinManualRelationMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<InstantWinManualRelationVariables & { id: number }>({
    req: (payload, { token }) => {
      const { id, ...rest } = payload
      return InstantWinApi.manualRelation(rest, { token })
    },
    toaster: res => {
      if (res.error || !res.data) {
        addToast({ result: 'error', message: genMessage(res.status, '登録できませんでした'), status: res.status })
      }
      if (res.isSuccess && res.data) {
        addToast({ result: 'succeeded', message: '手動登録できました' })
      }
    }
  })
}

export const useUpdateInstantWinLotteryMutation = (id: number) => {
  return useMutationWrapper<InstantWinUpdateLotteryMutationVariables>({
    req: (payload, { token }) => InstantWinApi.updateLottery(id, payload, { token })
  })
}
