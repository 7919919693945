import { useMutation } from 'react-query'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  DeletePostMediaTagRelationVariables,
  LibraryCompleteMediaUploadVariables,
  LibraryCompleteUploadResponse,
  LibraryOrder,
  LibrarySignedUrlResponse,
  LibrarySignedUrlVariables,
  LibraryTag,
  LibraryTagVariables,
  RegisterPostMediaQueryString,
  RegisterPostMediaTagRelationVariables,
  RegisterPostMediaTagVariables,
  RemoveTagVariables
} from 'src/modules/entity/library'
import { CommonApi } from 'src/redux/api/commonApi'
import { TLibrary, TLibraryOrder, TUpdateMediaStatusOrder } from 'src/redux/models/LibraryInfo'
import { getQueryString } from 'src/util/url'
export class LibraryApi extends CommonApi {
  static registerPostMedia = (form: FormData, getQueries: RegisterPostMediaQueryString, headers: TApiHeadersCommon) => {
    const getQuery = getQueryString(getQueries)
    return LibraryApi.postForm(`/resources/post_media${getQuery}`, form, headers)
  }
  static getIniLibrary = (params: LibraryOrder, headers: TApiHeadersCommon) =>
    LibraryApi.get('/resources/post_media', params, headers)
  static getAddLibrary = (params: LibraryOrder, headers: TApiHeadersCommon) =>
    LibraryApi.get('/resources/post_media', params, headers)
  static updateStatus = (params: TUpdateMediaStatusOrder, headers: TApiHeadersCommon) =>
    LibraryApi.post('/resources/post_media/status', params, headers)
  static getSignedUrl = (params: LibrarySignedUrlVariables, headers: TApiHeadersCommon) =>
    LibraryApi.post('/resources/post_media/signed', params, headers)
  static completeMediaUpload = (params: LibraryCompleteMediaUploadVariables, headers: TApiHeadersCommon) =>
    LibraryApi.post('/resources/post_media/complete', params, headers)
  static getTags = (params: LibraryTagVariables, headers: TApiHeadersCommon) =>
    LibraryApi.get('/post_media/tag', params, headers)
  static registerTag = (params: RegisterPostMediaTagVariables, headers: TApiHeadersCommon) =>
    LibraryApi.post('/post_media/tag', params, headers)
  static registerTagRelation = (params: RegisterPostMediaTagRelationVariables, headers: TApiHeadersCommon) =>
    LibraryApi.post('/post_media/tag/relation', params, headers)
  static deleteTagRelation = (params: DeletePostMediaTagRelationVariables, headers: TApiHeadersCommon) =>
    LibraryApi.delete(`/post_media/tag/relation/${params.relationId}`, params, headers)
  static removeTag = (params: RemoveTagVariables, headers: TApiHeadersCommon) =>
    LibraryApi.delete(`/post_media/tag/${params.id}`, params, headers)
}

export const useLibrarySignedUrlMutation = () => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useMutation<LibrarySignedUrlResponse, unknown, LibrarySignedUrlVariables, unknown>(payload =>
    LibraryApi.getSignedUrl(payload, { token })
  )
}

export const useCompleteMediaLibraryUploadMutation = () => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useMutation<LibraryCompleteUploadResponse, unknown, LibraryCompleteMediaUploadVariables, unknown>(payload =>
    LibraryApi.completeMediaUpload(payload, { token })
  )
}

export const useRegisterMediaMutation = () => {
  return useMutationWrapper<{ formData: FormData; rest: RegisterPostMediaQueryString }>({
    req: (payload, { token }) => {
      const { formData, rest } = payload
      return LibraryApi.registerPostMedia(formData, rest, { token })
    }
  })
}

export const useFetchMedia = ({ payload, key, options }: TUseQueryOptions<TLibraryOrder>) => {
  return useQueryWrapper<TLibrary[]>({
    queryKey: 'library',
    deps: key,
    req: ({ token }) => LibraryApi.getIniLibrary(payload, { token }),
    options
  })
}

export const useMediaUpdateStatus = () => {
  return useMutationWrapper<TUpdateMediaStatusOrder>({
    req: (payload, { token }) => LibraryApi.updateStatus(payload, { token })
  })
}

// brandIdに一致するタグそのものの取得
export const useFetchMediaTags = ({ payload, key, options }: TUseQueryOptions<LibraryTagVariables>) => {
  return useQueryWrapper<LibraryTag[]>({
    queryKey: 'postMediaTags',
    deps: key,
    req: ({ token }) => LibraryApi.getTags(payload, { token }),
    options
  })
}

// タグそのものの登録
export const useRegisterMediaTagMutation = () => {
  return useMutationWrapper<RegisterPostMediaTagVariables>({
    req: (payload, { token }) => LibraryApi.registerTag(payload, { token })
  })
}

// タグのRelationの登録
export const useRegisterMediaTagRelationMutation = () => {
  return useMutationWrapper<RegisterPostMediaTagRelationVariables>({
    req: (payload, { token }) => LibraryApi.registerTagRelation(payload, { token })
  })
}

// タグRelationの削除
export const useDeleteMediaTagRelationMutation = () => {
  return useMutationWrapper<DeletePostMediaTagRelationVariables>({
    req: (payload, { token }) => LibraryApi.deleteTagRelation(payload, { token })
  })
}

// タグそのものの論理削除
export const useRemoveTagMutation = () => {
  return useMutationWrapper<RemoveTagVariables>({
    req: (payload, { token }) => LibraryApi.removeTag(payload, { token })
  })
}
