import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logo from 'src/assets/images/logo-owgi.svg'
import { ROUTES } from 'src/constants/routes'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { State } from 'src/redux/reducers'
import { ColoredButton } from 'src/stories/atoms/ColoredButton'

const Error404: FC = () => {
  const history = useHistory()
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toObject()
  const { id: brandId } = user.toObject().currentBrand.toObject()

  return (
    <div className="account-pages" style={{ marginTop: '7rem' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <div className="auth-logo">
                    <Link to={ROUTES.brandTop(`${brandId}`)} className="logo logo-light text-center">
                      <span className="logo-lg">
                        <img src={logo} alt="" height="40" />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="text-center mt-2">
                  <h1 className="text-error">404</h1>
                  <h3 className="mt-3 mb-2">ページが見つかりません</h3>
                  <p className="text-muted mb-3">
                    <p className="text-muted">お探しのページはみつかりませんでした。</p>
                    <p className="text-muted mb-3">URLが間違っているか、ページが存在しません。</p>
                  </p>
                  <ColoredButton onClick={() => history.goBack()}>前のページへ戻る</ColoredButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404
