import { GetMentionsCsvVariables, MentionsQuery } from 'src/modules/entity/mentions'
import { useQueryWrapper } from 'src/modules/core/query'
import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from 'src/modules/core/errors'
import { TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  ListMentionsVariables,
  LikeMentionVariables,
  RegisterMentionsTargetVariables,
  UpdateMentionStatusVariables
} from 'src/modules/entity/mentions'
import { CommonApi } from 'src/redux/api/commonApi'
import { useMutationWrapper } from 'src/modules/core/mutation'

export class MentionsApi extends CommonApi {
  static list = (params: ListMentionsVariables, headers: TApiHeadersCommon) =>
    MentionsApi.get('/mentions', params, headers)
  static registerTargetAdd = (params: RegisterMentionsTargetVariables, headers: TApiHeadersCommon) =>
    MentionsApi.post('/mentions/target/add', params, headers)
  static registerTargetRemove = (params: RegisterMentionsTargetVariables, headers: TApiHeadersCommon) =>
    MentionsApi.post('/mentions/target/remove', params, headers)
  static updateStatus = (params: UpdateMentionStatusVariables, headers: TApiHeadersCommon) =>
    MentionsApi.post('/mentions/status', params, headers)
  static like = (params: LikeMentionVariables, headers: TApiHeadersCommon) =>
    MentionsApi.post('/favorite/tw/create', params, headers)
  static unlike = (params: LikeMentionVariables, headers: TApiHeadersCommon) =>
    MentionsApi.post('/favorite/tw/destroy', params, headers)
  static fetchCsv = (params: GetMentionsCsvVariables, headers: TApiHeadersCommon) =>
    MentionsApi.get('/mentions/csv', params, headers)
}

export const useRegisterMentionTargetAddMutation = () => {
  return useMutationWrapper<RegisterMentionsTargetVariables>({
    req: (payload, { token }) => MentionsApi.registerTargetAdd(payload, { token })
  })
}
export const useRegisterMentionTargetRemoveMutation = () => {
  return useMutationWrapper<RegisterMentionsTargetVariables>({
    req: (payload, { token }) => MentionsApi.registerTargetRemove(payload, { token })
  })
}

export const useLikeMentionMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<LikeMentionVariables>({
    req: (payload, { token }) => MentionsApi.like(payload, { token }),
    toaster: res => {
      if (res.status !== 200) {
        addToast({ result: 'error', message: genMessage(res.status), status: res.status })
      }
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: 'いいねしました' })
      }
    }
  })
}
export const useUnlikeMentionMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<LikeMentionVariables>({
    req: (payload, { token }) => MentionsApi.unlike(payload, { token }),
    toaster: res => {
      if (res.status !== 200) {
        addToast({ result: 'error', message: genMessage(res.status), status: res.status })
      }
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: 'いいねを取り消しました' })
      }
    }
  })
}

export const useUpdateStatusMentionMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<UpdateMentionStatusVariables>({
    req: (payload, { token }) => MentionsApi.updateStatus(payload, { token }),
    toaster: res => {
      if (res.status !== 200) {
        addToast({ result: 'error', message: genMessage(res.status), status: res.status })
      }
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: 'ステータスを更新しました。' })
      }
    }
  })
}

export const useMentionsQuery = ({ payload, key, options }: TUseQueryOptions<ListMentionsVariables>) => {
  return useQueryWrapper<MentionsQuery[]>({
    queryKey: 'mentions',
    deps: key,
    req: ({ token }) => MentionsApi.list({ ...payload, paging: { ...payload.paging } }, { token }),
    options
  })
}

export const useMentionsCsvQuery = ({ payload, key, options }: TUseQueryOptions<GetMentionsCsvVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'twMentionsCsv',
    deps: key,
    req: ({ token }) => MentionsApi.fetchCsv(payload, { token }),
    options
  })
}
