import { List } from 'immutable'
import actionCreatorFactory from 'typescript-fsa'
import {
  RegisterApprovalResult,
  TApproval,
  TApprovalOrder,
  TApprovalSetting,
  TApprovalSettingsGetOrder,
  TApprovalSettingsRegisterOrder,
  TApprovalSnsAccount,
  TApprovalRolesGetOrder,
  TApprovalSnsAccountsRegisterOrder,
  TApprovalStatus,
  TApprovalStatusGetOrder,
  TApprovalStatusRegisterOrder
} from '../models/ApprovalInfo'
import { TError } from 'src/modules/entity/API'

const actionCreator = actionCreatorFactory('APPROVAL')

/***
 * get for saga
 * set for reducer
 */
export const ApprovalActions = {
  initializeApproval: actionCreator('INITIALIZE_APPROVAL'),

  getIniApproval: actionCreator<TApprovalOrder>('GET_INI_APPROVAL'),
  getIniApprovalSnsAccounts: actionCreator<TApprovalRolesGetOrder>('GET_INI_APPROVAL_SNS_ACCOUNTS'),
  getAddApprovalSnsAccounts: actionCreator<TApprovalRolesGetOrder>('GET_ADD_APPROVAL_SNS_ACCOUNTS'),
  getApprovalStatus: actionCreator<TApprovalStatusGetOrder>('GET_APPROVAL_STATUS'),
  getApprovalSettings: actionCreator<TApprovalSettingsGetOrder>('GET_APPROVAL_SETTINGS'),

  registerApprovalSnsAccounts: actionCreator<TApprovalSnsAccountsRegisterOrder>('REGISTER_APPROVAL_SNS_ACCOUNTS'),
  registerApprovalStatus: actionCreator<TApprovalStatusRegisterOrder>('REGISTER_APPROVAL_STATUS'),
  registerApprovalSettings: actionCreator<TApprovalSettingsRegisterOrder>('REGISTER_APPROVAL_SETTINGS'),

  setIniApproval: actionCreator<TApproval>('SET_INI_APPROVAL'),
  setIniApprovalSnsAccounts: actionCreator<List<TApprovalSnsAccount>>('SET_INI_APPROVAL_SNS_ACCOUNTS'),
  setAddApprovalSnsAccounts: actionCreator<List<TApprovalSnsAccount>>('SET_ADD_APPROVAL_SNS_ACCOUNTS'),
  setApprovalStatus: actionCreator<List<TApprovalStatus>>('SET_APPROVAL_STATUS'),
  setApprovalSettings: actionCreator<List<TApprovalSetting>>('SET_APPROVAL_SETTINGS'),
  setApprovalSettingsResult: actionCreator<RegisterApprovalResult>('SET_APPROVAL_SETTINGS_RESULT'),
  setApprovalStatusResult: actionCreator<RegisterApprovalResult>('SET_APPROVAL_STATUS_RESULT'),
  setApprovalRefresh: actionCreator<boolean>('SET_APPROVAL_REFRESH'),

  setError: actionCreator<TError>('SET_ERROR'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED'),
  setLoading: actionCreator<boolean>('SET_LOADING')
}
