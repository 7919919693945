import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useInsightQuery, usePostCaptionRulesQuery } from 'src/hooks/domain'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import {
  AccountQuery,
  InstagramBusinessAccountEle,
  InstagramInsightMediaEle,
  InstagramInsightUserEle,
  TwitterInsightMediaEle,
  TwitterInsightUserEle
} from 'src/modules/entity/account'
import { PAGING_SIZE } from 'src/constants/query'

export const usePostAccount = (mId: string, refetchInterval?: number) => {
  const { daterange } = useShallowEqualSelector(state => state.Daterange).toJS()
  const [account, setAccount] = useState<AccountQuery>()
  const q = {
    snsAccount: {
      mId
    },
    insight: {
      collectAtFrom: dayjs(daterange.from).toISOString(),
      collectAtTo: dayjs(daterange.to)
        .endOf('date')
        .toISOString()
    }
  }
  const { data, refetch, isLoading, isError } = useInsightQuery({
    payload: q,
    key: [{ mId, daterange }],
    options: {
      enabled: !!mId,
      refetchInterval
    }
  })
  // temoporary variables
  const graphData = data?.data
  const snsAccount = graphData?.snsAccount

  useEffect(() => {
    if (graphData !== undefined) {
      // /graphをそのままComponent側で使えなかった
      // 理由：reduxで使っているaccountとgraphの型定義が違うので動かなくなってしまう
      setAccount({
        ...snsAccount,
        snsAccountId: snsAccount?.id,
        accountDetailPictureUrl: snsAccount?.pictureUrl,
        instagramBusinessAccount: graphData.snsIgBusinessAccount as InstagramBusinessAccountEle[],
        instagramInsightMedia: (graphData.snsIgMedias as unknown) as InstagramInsightMediaEle[],
        instagramInsightUser: (graphData.snsUsers as unknown) as InstagramInsightUserEle[],
        twitterInsightMedia: (graphData.snsTwMedias as unknown) as TwitterInsightMediaEle[],
        twitterInsightUser: (graphData.snsUsers as unknown) as TwitterInsightUserEle[]
        // instagramInsightStories: data?.data.snsIgStories as unknown /graphにはsnsIgStoriesは存在しているが、reduxは以下のaccountではinstagramInsightStoriesは使用されていないようなのでコメントアウトだけ残しておく
        // @see https://github.com/CyberBuzz/slider/issues/2234
      })
    }
  }, [data]) // eslint-disable-line
  return { account, isLoading, isError, refetch }
}

export const useSnsAccountPostCaptionRules = (snsAccountId: number) => {
  const { data, isLoading, refetch } = usePostCaptionRulesQuery({
    payload: { snsAccountId, paging: { size: PAGING_SIZE.all } },
    key: [{ snsAccountId }],
    options: { enabled: !!snsAccountId }
  })
  return { snsAccountPostCaptionRules: data?.data, isLoading, refetch }
}
