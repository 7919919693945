import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import { ROUTES } from 'src/constants/routes'
import { InsightActions } from 'src/redux/actions/Insight'
import { OauthActions } from 'src/redux/actions/Oauth'
import { OauthApi } from 'src/redux/api/oauthApi'
import { history } from 'src/redux/configureStore'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { Federate, FederateFacebook, VerifyUrls } from 'src/redux/models/OauthInfo'
import { State } from 'src/redux/reducers'

function* getVerifyUrls() {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const headOrder: TApiHeadersCommon = { token }
  const response = yield OauthApi.verifyUrls(headOrder)
  if (response.isSuccess) {
    yield put(OauthActions.setVerifyUrls(VerifyUrls.fromResponse(response.data)))
  } else {
    yield put(OauthActions.setError({ status: response.status, message: response.error.message }))
  }
}

function* getFederate(action: ReturnType<typeof OauthActions.getFederate>) {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const brandId: number = yield select((state: State) => state.Auth.getIn(['user', 'currentBrand', 'id']))
  const headOrder: TApiHeadersCommon = { token }
  const response = yield OauthApi.federate(action.payload, headOrder)
  if (response.isSuccess) {
    yield put(OauthActions.setFederate(Federate.fromResponse(response.data[0])))
    yield put(InsightActions.getIniAccounts({ paging: { page: 1 } }))
  } else {
    yield put(OauthActions.setError({ status: response.status, message: response.error.message }))
  }
  // アカウント一覧に遷移
  yield history.push(ROUTES.accountList(brandId.toString()))
}

function* getFederateSecond(action: ReturnType<typeof OauthActions.getFederateSecond>) {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const brandId: number = yield select((state: State) => state.Auth.getIn(['user', 'currentBrand', 'id']))
  const headOrder: TApiHeadersCommon = { token }
  const response = yield OauthApi.federateSecond(action.payload, headOrder)
  if (response.isSuccess) {
    yield put(OauthActions.setFederate(Federate.fromResponse(response.data[0])))
    //yield put(InsightActions.getIniAccounts({ paging: { page: 1 } }))
  } else {
    yield put(OauthActions.setError({ status: response.status, message: response.error.message }))
  }
  // アカウント一覧に遷移
  yield history.push(ROUTES.accountList(brandId.toString()))
}

function* getFederateFacebook(action: ReturnType<typeof OauthActions.getFederateFacebook>) {
  const token = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const brandId: number = yield select((state: State) => state.Auth.getIn(['user', 'currentBrand', 'id']))
  const headOrder: TApiHeadersCommon = { token }
  const response = yield OauthApi.federateFacebook(action.payload, headOrder)
  if (response.isSuccess) {
    yield put(OauthActions.setFederateFacebook(FederateFacebook.fromResponse(response.data)))
  } else {
    yield put(OauthActions.setError({ status: response.status, message: response.error.message }))
  }
  // OAuthトップに遷移、ただしクエリストリングを含む
  yield history.push(`${ROUTES.oauth(`${brandId}`)}?facebook_page_select=1`)
}

/*** watcher ***/
export function* watchGetVerifyUrl() {
  yield takeLatest(OauthActions.getVerifyUrls, getVerifyUrls)
}
export function* watchGetFederate() {
  yield takeLatest(OauthActions.getFederate, getFederate)
}
export function* watchGetFederateFacebook() {
  yield takeLatest(OauthActions.getFederateFacebook, getFederateFacebook)
}
export function* watchGetFederateSecond() {
  yield takeLatest(OauthActions.getFederateSecond, getFederateSecond)
}

/*** saga ***/
function* oauthSaga(): any {
  yield all([
    fork(watchGetVerifyUrl),
    fork(watchGetFederate),
    fork(watchGetFederateFacebook),
    fork(watchGetFederateSecond)
  ])
}

export default oauthSaga
