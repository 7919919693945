import { CalendarActions } from '../actions/Calendar'
import { TCalendar } from '../models/CalendarInfo'
import { TResponseCommon } from 'src/modules/entity/API'
import { List, Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

export type TCalendarState = TResponseCommon & {
  calendar: List<TCalendar>
  calendarRefresh: boolean
  initial: boolean
  targetDay: string | undefined
  updateByScroll: boolean
  displayTodo: boolean
}
export class CalendarState extends Record<TCalendarState>({
  calendar: List(),
  calendarRefresh: false,
  initial: true,
  targetDay: undefined,
  updateByScroll: false,
  displayTodo: false,
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const CalendarReducer = reducerWithInitialState(new CalendarState())
  .case(CalendarActions.initializeCalendar, (state, payload) => new CalendarState())
  .case(CalendarActions.setCalendar, (state, payload) =>
    state.withMutations(s =>
      s
        .set('calendar', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(CalendarActions.setDisplayTodo, (state, payload) => state.withMutations(s => s.set('displayTodo', payload)))
  .case(CalendarActions.setTargetDay, (state, payload) => state.withMutations(s => s.set('targetDay', payload)))
  .case(CalendarActions.setInitial, (state, payload) => state.withMutations(s => s.set('initial', payload)))
  .case(CalendarActions.updateByScroll, (state, payload) => state.withMutations(s => s.set('updateByScroll', payload)))
  .case(CalendarActions.setCalendarRefresh, (state, payload) =>
    state.withMutations(s => s.set('calendarRefresh', payload))
  )
  .case(CalendarActions.setError, (state, payload) =>
    state.withMutations(s =>
      s
        .set('error', payload)
        .set('loading', false)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(CalendarActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(CalendarActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
  .case(CalendarActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))
export default CalendarReducer
