import { TApiHeadersCommon } from '../entity/API'
import { CommonApi } from 'src/redux/api/commonApi'
import { useMutationWrapper } from '../core/mutation'
import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from '../core/errors'
import { AutoReplyMutationVariables } from '../entity/campaignsAutoReply'
import { CampaignUpdateVariables } from '../entity/campaigns'

class AutoReplyApi extends CommonApi {
  static register = (params: AutoReplyMutationVariables, headers: TApiHeadersCommon) =>
    AutoReplyApi.post('/campaigns/auto-reply', params, headers)
  static abolish = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    AutoReplyApi.post('/campaigns/abolish', params, headers)
  static approve = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    AutoReplyApi.post('/campaigns/approval', params, headers)
}

export const useCreateAutoReplyMutation = () => {
  return useMutationWrapper<AutoReplyMutationVariables>({
    req: (payload, { token }) => AutoReplyApi.register(payload, { token })
  })
}

export const useAbolishAutoReplyMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => AutoReplyApi.abolish(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '廃止にできませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '廃止にしました' })
      }
    }
  })
}

export const useApproveAutoReplyMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => AutoReplyApi.approve(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '確認済みにできませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '確認済みにしました' })
      }
    }
  })
}
