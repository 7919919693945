import React from 'react'
import { useAllowedFeatures } from 'src/hooks/domain'
import Error404 from 'src/components/Error/Error404'

type Scopes = keyof ReturnType<typeof useAllowedFeatures>

// requiredScopesで指定したいずれかのscopeを持っていれば表示可能
const PlanHOC = (Component: React.FC, requiredScopes: Scopes[]) => {
  const PlanRouter = () => {
    const allowedFeatures = useAllowedFeatures()
    if (
      Object.entries(allowedFeatures)
        .filter(x => x[1])
        .map((x: [Scopes, boolean]) => x[0])
        .some(x => requiredScopes.includes(x))
    ) {
      return <Component />
    } else {
      return <Error404 />
    }
  }
  return PlanRouter
}

export default PlanHOC
