import React, { Dispatch, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import logo from 'src/assets/images/logo-owgi.svg'
import { ErrorMessage } from 'src/components/Error/ErrorMessage'
import { ROUTES } from 'src/constants/routes'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { AuthActions } from 'src/redux/actions/Auth'
import { State } from 'src/redux/reducers'
import { Button } from 'src/stories/atoms/Button'
import { ColoredButton } from 'src/stories/atoms/ColoredButton'

type TReactError = RouteComponentProps & {
  message?: string
  setReload: Dispatch<boolean>
}
const ReactError: React.VFC<TReactError> = (props: TReactError) => {
  const { setReload } = props
  const dispatch = useDispatch()
  const handleSignOut = useCallback(() => {
    dispatch(AuthActions.getSignOut({ pushToTop: true }))
    setReload(true)
  }, [dispatch, setReload])
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toObject()
  const { id: brandId } = user.toObject().currentBrand.toObject()

  return (
    <div className="account-pages my-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <div className="auth-logo">
                    <Link to={ROUTES.brandTop(`${brandId}`)} className="logo logo-light text-center">
                      <span className="logo-lg">
                        <img src={logo} alt="" height="40" />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <h1 className="text-error">エラー</h1>
                  <ErrorMessage notification={{ result: 'error', message: 'エラーが発生しました。', status: 'F' }} />
                  <p className="text-muted mb-3">
                    再読み込むを行うか、
                    <br />
                    一度ログアウトしてから再度ログインを試してください。
                  </p>
                  <ColoredButton onClick={() => window.location.reload()} className="mr-2">
                    再読み込みする
                  </ColoredButton>
                  <Button onClick={handleSignOut}>ログアウトする</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ReactError)
