import dayjs from 'dayjs'
import { Record } from 'immutable'
import { DATE_FORMATTED } from 'src/constants/dates'

dayjs.locale('ja')

/*** order models ***/
export type TDateRange = {
  from: string
  to: string
}
export class DateRange extends Record<TDateRange>({
  from: DATE_FORMATTED.isoFrom,
  to: DATE_FORMATTED.isoTo
}) {
  static fromInput(params: TDateRange) {
    return new DateRange(params)
  }
}
