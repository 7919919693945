import {
  Brand,
  Invitation,
  Signed,
  TChangeBrandOrder,
  TInvitationOrder,
  TResetPasswordOrder,
  TSignInOrder,
  TSignOutOrder,
  TSignUpOrder
} from 'src/redux/models/AuthInfo'
import actionCreatorFactory from 'typescript-fsa'
import { TError } from 'src/modules/entity/API'

const actionCreator = actionCreatorFactory('SIGN')

/***
 * get for saga
 * set for reducer
 */
export const AuthActions = {
  initializeAuth: actionCreator('INITIALIZE_AUTH'),

  getSignUp: actionCreator<TSignUpOrder>('GET_SIGN_UP'), // TODO <- Use registers instead of get.
  getSigned: actionCreator<TSignInOrder>('GET_SIGNED'),
  getSignOut: actionCreator<TSignOutOrder>('GET_SIGN_OUT'),
  getChangeBrand: actionCreator<TChangeBrandOrder>('GET_CHANGE_BRAND'),
  getInvite: actionCreator<TInvitationOrder>('GET_INVITE'),
  getSentForgetPasswordMail: actionCreator<string>('GET_SENT_FORGET_PASSWORD_MAIL'),
  getSentResetPasswordOrder: actionCreator<TResetPasswordOrder>('GET_SENT_RESET_PASSWORD'),

  setSigned: actionCreator<Signed>('SET_SIGNED'),
  setSignOut: actionCreator<Signed>('SET_SIGN_OUT'),
  setBrand: actionCreator<Brand>('SET_BRAND'),
  setInvite: actionCreator<Invitation>('SET_INVITE'),
  setForgetPasswordMail: actionCreator<any>('SET_FORGET_PASSWORD_MAIL'),
  setResetPassword: actionCreator<any>('SET_RESET_PASSWORD'),
  setRedirect: actionCreator<string>('SET_REDIRECT'),
  setLoading: actionCreator<boolean>('SET_LOADING'),
  setError: actionCreator<TError>('SET_ERROR'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
