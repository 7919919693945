const baseUrlPerEnvironments = () => {
  // eslint-disable-next-line
  switch (process.env.REACT_APP_STAGE) {
    case 'stg':
      return 'https://api-stg.owgi.jp'
    case 'prd':
      return 'https://api.owgi.jp'
    default:
      return 'http://localhost:9000'
  }
}

export const apiOptions = {
  baseURL: baseUrlPerEnvironments(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': '',
    'X-Requested-With': 'XMLHttpRequest',
    'server-type': 'play'
  },
  validateStatus: (status: number) => {
    return status < 400
  },
  timeout: 60 * 10 * 1000 // 10分
}

// eslint-disable-next-line
export const ENV = process.env.REACT_APP_STAGE
