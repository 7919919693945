import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { UseMutateFunction } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { POST } from 'src/constants/posts'
import { userBrandRoleType } from 'src/constants/user'
import { usePostAccount, useSnsAccountPostCaptionRules } from 'src/containers/Post/hooks/account'
import { useApprovalSettings, useApprovalStatus, usePostApprovalTodoChecks } from 'src/containers/Post/hooks/approval'
import {
  useCommerceCatalogProducts,
  useDeletePostCommentReactionMutation,
  usePost,
  usePostCommentReactions,
  usePostComments,
  useRegisterPostCommentMutation,
  useRegisterPostCommentReactionMutation
} from 'src/containers/Post/hooks/post'
import {
  useAccountQuery,
  useCampaignsQuery,
  useFinishPostEditMutation,
  useImmediatelyPostMutation,
  useRoleAllowed,
  useUpdateApprovalMutation,
  useUpdatePostStatusMutation,
  useWrapperQueryClient
} from 'src/hooks/domain'
import { useQueryStringSearch } from 'src/hooks/useQueryString'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { AccountQuery } from 'src/modules/entity/account'
import {
  FinishEditPostVariables, InstagramProductTag,
  InstagramUserTag,
  PostData,
  PostFormValues,
  UpdatePostStatusVariables
} from 'src/modules/entity/post'
import { InsightActions } from 'src/redux/actions/Insight'
import { PostActions } from 'src/redux/actions/Post'
import { TApprovalSetting, TApprovalSnsAccount, TApprovalStatus } from 'src/redux/models/ApprovalInfo'
import { TSigned } from 'src/redux/models/AuthInfo'
import { Account } from 'src/redux/models/InsightInfo'
import { TPostComment } from 'src/redux/models/PostCommentInfo'
import { Post } from 'src/redux/models/PostInfo'
import { State } from 'src/redux/reducers'
import { DaterangeState } from 'src/redux/reducers/DatarangeReducer'
import { Maybe } from 'src/types'
import { first, isApplicable } from 'src/util/array'
import { minutesToDate } from 'src/util/date'
import { UseFormMethods, FieldValues } from 'react-hook-form'
import { getCurrentStepNumber } from '../helper/approval'
import {Product} from "../../../modules/entity/commerce";

type AccountPostSelector = {
  account: AccountQuery
  daterange: DaterangeState
  mId: string
  provider: string
  post: PostData
  iniPostFormValues: PostFormValues
  postLoading: boolean
  postComments: TPostComment[]
  postCommentsRefresh: boolean
  postCommentNotified: boolean
  postCommentLoading: boolean
  postRefresh: boolean
  postMediaRefresh: boolean
}

export const useAccountPostSelector = (): AccountPostSelector => {
  const { daterange } = useShallowEqualSelector((state: State) => state.Daterange).toJS()
  const { account } = useShallowEqualSelector((state: State) => state.Insight).toJS()
  const mId = account?.mId || ''
  const provider = account?.provider || ''
  const { post, iniPostFormValues, loading: postLoading, postRefresh, postMediaRefresh } = useShallowEqualSelector(
    (state: State) => state.Post
  ).toJS()
  const {
    postComments,
    postCommentsRefresh,
    notified: postCommentNotified,
    loading: postCommentLoading
  } = useShallowEqualSelector((state: State) => state.PostComment).toJS()
  return {
    mId,
    provider,
    daterange,
    account,
    post,
    postLoading,
    postComments,
    iniPostFormValues,
    postCommentsRefresh,
    postCommentNotified,
    postCommentLoading,
    postRefresh,
    postMediaRefresh
  }
}

type ApprovalAuthSelector = {
  snsAccounts: TApprovalSnsAccount[]
  settings: TApprovalSetting[]
  status: TApprovalStatus[]
  user: TSigned
}

export const useApprovalAuthSelector = (): ApprovalAuthSelector => {
  const { snsAccounts, settings, status } = useShallowEqualSelector((state: State) => state.Approval).toJS()
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toJS()
  return { snsAccounts, settings, status, user }
}

type BtnVisibility = {
  canBeEdit: boolean
  approvalApplication: boolean
  cancelApproval: boolean
  readyToPost: boolean
  approvePost: boolean
  completePost: boolean
  sendBack: boolean
  deletePost: boolean
  immediatelyPost: boolean
  makeInstagramPost: boolean
  postId: any
}

export const useBtnVisibility = (
  methods?: UseFormMethods<FieldValues>
): {
  btnVisibility: BtnVisibility
  isBtnDisabled: boolean
  isDisabled: boolean
  isLastApprover: boolean
  existInvalidInstagramMedia: boolean
} => {
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toJS()
  const { post, settings } = usePostDetail()
  const {
    roleType,
    user: { id: userId }
  } = user
  const {
    changePostStatus: { edit }
  } = useRoleAllowed()
  const isSystemUser = roleType === userBrandRoleType.system
  const isOwnerUser = roleType === userBrandRoleType.owner
  const isManagerUser = roleType === userBrandRoleType.manager
  const isMemberUser = roleType === userBrandRoleType.member
  const isCreateUser = userId === post?.createUser?.id
  const isInputDisabled = () => {
    if (isSystemUser) return false
    if (post?.post.status === POST.postStatus.approved) return true
    if (post?.post.status >= POST.postStatus.pending) {
      return !settings?.find(setting => setting.user.id === userId && setting.postApprovalSetting.stepNumber)
    }
  }
  const isBtnDisabled = isSystemUser || post?.post.status < POST.postStatus.requested
  const isDisabled = isInputDisabled()
  const isPublishDateTimePast = dayjs(post?.post.publishAt) < dayjs()
  const isLastApprover = post?.todo.canBeApprove && post?.todo.maxStepNumber === post?.todo.nextStepNumber
  const isInstagram = post?.post.provider === 'instagram'
  const existInvalidInstagramMedia =
    isInstagram && post?.postMediaRelations?.some(post => !post?.postMedia?.isInstagram)

  // create post
  if (!post) {
    return {
      btnVisibility: {
        canBeEdit: edit,
        approvalApplication: edit,
        cancelApproval: false,
        readyToPost: edit,
        approvePost: false,
        completePost: false,
        sendBack: false,
        deletePost: false,
        immediatelyPost: false,
        makeInstagramPost: false,
        postId: 0
      },
      isBtnDisabled,
      isDisabled,
      isLastApprover,
      existInvalidInstagramMedia
    }
  }

  const { draft, pending, approved, requested, fail } = POST.postStatus
  const approvalTodos: boolean[] = !!methods ? Object.values(methods.getValues()) : [] // eslint-disable-line
  const allCheckedTodos = approvalTodos.length === 0 || approvalTodos.every(Boolean)
  const hasSettings = settings?.length > 0
  const btnVisibility = {
    // 編集可能
    canBeEdit: post?.todo.canBeEdit || post?.post.status === fail,
    // 承認申請する
    approvalApplication:
      (post?.post.status === draft && post?.todo.canBeEdit && hasSettings) || (hasSettings && isSystemUser),
    // 承認申請を取り消す
    cancelApproval:
      (post?.post.status === pending && isCreateUser && post?.todo.prevStepNumber === 0) ||
      (hasSettings && isSystemUser),
    // 投稿待ちにする
    readyToPost: (post?.todo.canBeEdit && settings?.length === 0) || isSystemUser,
    // 承認する
    approvePost: allCheckedTodos && (post?.todo.canBeApprove || (hasSettings && isSystemUser)),
    // 投稿済みにする operator 全員可能
    completePost: (isPublishDateTimePast && post?.todo.canBeChangeToRequested) || isSystemUser,
    // 差し戻しする
    sendBack: post?.todo.canBeApprove || isSystemUser,
    // 破棄する
    deletePost:
      (!isMemberUser && post?.todo.canBeEdit) ||
      (!isMemberUser && post?.todo.canBeApprove) ||
      (!isMemberUser && post?.todo.canBeChangeToRequested) ||
      // オーナー権限では、投稿失敗時のみ削除可能
      (isOwnerUser && isApplicable([fail], post?.post.status)) ||
      isSystemUser,
    // 今すぐに投稿する
    immediatelyPost:
      isSystemUser || ((isOwnerUser || isManagerUser) && isApplicable([approved, fail], post?.post.status)),
    makeInstagramPost:
      isInstagram &&
      (isSystemUser ||
        ((isOwnerUser || isManagerUser) &&
          isApplicable([approved, requested], post?.post.status) &&
          post?.post.type === 'CAROUSEL_ALBUM')),
    postId: post?.post.id
  }
  return { btnVisibility, isBtnDisabled, isDisabled, isLastApprover, existInvalidInstagramMedia }
}

type ApprovalActions = {
  handleUpdateStatus: (ev: React.MouseEvent<HTMLButtonElement>) => void
  handleApprovalSubmit: (ev: React.MouseEvent<HTMLButtonElement>) => void
  handleImmediatelyPost: () => void
}

type Cons = {
  confirmText: string
  checkAutoPublish: boolean
  action: UseMutateFunction<unknown, unknown, FinishEditPostVariables | UpdatePostStatusVariables>
  payload: FinishEditPostVariables | UpdatePostStatusVariables
}

export const useApprovalActions = (): ApprovalActions => {
  const { mutate: updateApprovalMutate, isSuccess: isUpdateApprovalSuccess } = useUpdateApprovalMutation()
  const { mutate: finishPostEditMutate, isSuccess: isFinishPostEditSuccess } = useFinishPostEditMutation()
  const { mutate: updatePostStatusMutate, isSuccess: isUpdatePostStatusSuccess } = useUpdatePostStatusMutation()
  const { mutate: immediatelyPostMutate, isSuccess: isImmediatelyPostSuccess } = useImmediatelyPostMutation()
  const { account, post, postRefetch } = usePostDetail()
  const { invalidateQueries } = useWrapperQueryClient()
  const history = useHistory()

  useEffect(() => {
    if (isUpdateApprovalSuccess || isFinishPostEditSuccess || isUpdatePostStatusSuccess || isImmediatelyPostSuccess) {
      postRefetch()
    }
  }, [
    isFinishPostEditSuccess,
    isUpdateApprovalSuccess,
    isUpdatePostStatusSuccess,
    isImmediatelyPostSuccess,
    postRefetch
  ])
  const isInvalidInstagramPost = post?.post?.provider === 'instagram' && post?.postMediaRelations?.length < 1
  const promptMediaAttachmentConfirm = () => {
    if (window.confirm('メディアが登録されていません。登録を行ってください。')) {
      history.push(`${ROUTES.post(post?.post.brandId, `${post?.post.id}`)}/edit`)
    }
  }

  const nextStep = post?.todo.nextStepNumber
  const prevStep = post?.todo.prevStepNumber
  const readyToPostBaseText = '投稿待ちとしてよろしいですか？\n承認を経ずに投稿可能な状態になります。'
  const handleUpdateStatus = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const statusTarget = ev.currentTarget.id
    if (isInvalidInstagramPost && statusTarget !== 'deletePost') {
      promptMediaAttachmentConfirm()
      return
    }
    const prePayload = { id: post?.post.id, snsAccountId: account?.snsAccountId, mId: post?.post.mId }
    const statusUpdateConds: { [key: string]: Cons } = {
      approvalApplication: {
        confirmText: '承認申請してよろしいですか？',
        checkAutoPublish: true,
        action: finishPostEditMutate,
        payload: {
          post: {
            ...prePayload,
            status: POST.postStatus.pending
          }
        }
      },
      readyToPost: {
        confirmText: readyToPostBaseText,
        checkAutoPublish: true,
        action: finishPostEditMutate,
        payload: {
          post: {
            ...prePayload,
            status: POST.postStatus.approved
          }
        }
      },
      completePost: {
        confirmText: 'ステータスを【投稿済み】に変更してよろしいですか？\n※実際に投稿はされませんのでご注意ください。',
        checkAutoPublish: post?.todo.canBeChangeToRequested,
        action: updatePostStatusMutate,
        payload: {
          post: {
            ...prePayload,
            status: POST.postStatus.requested
          }
        }
      },
      deletePost: {
        confirmText: '破棄してよろしいですか？',
        checkAutoPublish: true,
        action: updatePostStatusMutate,
        payload: {
          post: {
            ...prePayload,
            status: POST.postStatus.archived
          }
        }
      }
    }
    if (
      window.confirm(statusUpdateConds[statusTarget].confirmText) &&
      statusUpdateConds[statusTarget].checkAutoPublish
    ) {
      statusUpdateConds[statusTarget].action(statusUpdateConds[statusTarget].payload)
      invalidateQueries('posts')
    }
  }

  const handleApprovalSubmit = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const updateTarget = ev.currentTarget.id
      const approvalConds: { [key: string]: { status: number; step: number; confirmText: string } } = {
        approvePost: {
          status: POST.approvalStatus.approved,
          step: nextStep,
          confirmText: '承認してよろしいですか？\n最後の承認者が承認すると【投稿待ち】ステータスになります。'
        },
        sendBack: {
          status: POST.approvalStatus.rollback,
          step: prevStep,
          confirmText: '差し戻ししてよろしいですか？'
        },
        cancelApproval: {
          status: POST.approvalStatus.rollback,
          step: prevStep,
          confirmText: '承認申請を取り消してよろしいですか？'
        }
      }
      const payload = {
        postApprovalStatus: {
          snsAccountId: account?.snsAccountId,
          postId: post?.post.id,
          stepNumber: approvalConds[updateTarget].step,
          status: approvalConds[updateTarget].status
        }
      }
      if (window.confirm(approvalConds[updateTarget].confirmText)) {
        updateApprovalMutate(payload)
      }
    },
    [post, account, nextStep, prevStep, updateApprovalMutate]
  )

  const handleImmediatelyPost = () => {
    if (isInvalidInstagramPost) {
      promptMediaAttachmentConfirm()
      return
    }
    const payload = {
      post: {
        id: post?.post.id,
        snsAccountId: account?.snsAccountId
      }
    }
    if (window.confirm('今すぐ投稿して本当によろしいですか？')) {
      immediatelyPostMutate(payload)
    }
  }

  return { handleUpdateStatus, handleApprovalSubmit, handleImmediatelyPost }
}

const defaultRefetchInterval = 10000

const setRefetchIntervalTime = (status: number): Maybe<number> => {
  if (status === POST.postStatus.inprogress) return 3000
  if (status === POST.postStatus.requested) return undefined
  return defaultRefetchInterval
}

export const usePostDetail = () => {
  const dispatch = useDispatch()
  const [refetchInterval, setRefetchInterval] = useState(defaultRefetchInterval)
  const { post, isLoading: postLoading, refetch: postRefetch } = usePost({ refetchInterval })
  const { account, refetch: accountRefetch, isLoading: accountLoading } = usePostAccount(post?.post.mId)
  const { postComments, isLoading: postCommentLoading, refetch: postCommentsRefetch } = usePostComments(
    account?.snsAccountId
  )
  const {
    snsAccountPostCaptionRules,
    isLoading: snsAccountPostCaptionRulesLoading,
    refetch: snsAccountPostCaptionRulesRefetch
  } = useSnsAccountPostCaptionRules(account?.snsAccountId)
  const { status, isLoading: statusLoading, refetch: statusRefetch } = useApprovalStatus(
    post?.post.id,
    account?.snsAccountId
  )
  const { settings, isLoading: settingLoading, refetch: settingsRefetch } = useApprovalSettings(account?.snsAccountId)
  const {
    postCommentReactions,
    isLoading: postCommentReactionLoading,
    refetch: postCommentReactionsRefetch
  } = usePostCommentReactions(post?.post.id)
  const {
    postApprovalTodoChecks,
    isLoading: postApprovalTodoLoading,
    refetch: postApprovalTodosRefetch
  } = usePostApprovalTodoChecks(account?.snsAccountId, post?.post.id, getCurrentStepNumber(post) - 1)
  const isLoading =
    postLoading ||
    postCommentLoading ||
    snsAccountPostCaptionRulesLoading ||
    postCommentReactionLoading ||
    statusLoading ||
    settingLoading ||
    accountLoading ||
    postApprovalTodoLoading
  useEffect(() => {
    if (!account && !!post?.post.mId) {
      accountRefetch()
    }
    if (account && account.snsAccountId) {
      postRefetch()
      settingsRefetch()
      postCommentsRefetch()
      snsAccountPostCaptionRulesRefetch()
      if (post && post.post.id) {
        statusRefetch()
        postCommentReactionsRefetch()
        postApprovalTodosRefetch()
      }
    }
    if (post && post.post) {
      const minToDate = post.poll ? minutesToDate(post.poll.durationMinutes) : 0
      const instagramUserTags: InstagramUserTag[] = post.postMediaRelations
        .map(pmr => pmr.postMediaInstagramUserTags.map((t, i) => ({ ...t, postMediaId: pmr.postMedia.id, index: i })))
        .flat()
      const instagramProductTags: InstagramProductTag[] = post.postMediaRelations
          .map(pmr => pmr.postMediaInstagramProductTags.map((t, i) => ({ ...t, postMediaId: pmr.postMedia.id, index: i })))
          .flat()
      // 投稿処理中のステータスならリフェッチステータスを早くして、リアルタイム性を高めたい
      setRefetchInterval(setRefetchIntervalTime(post?.post.status))
      dispatch(
        PostActions.setPostFormValues({
          ...post.post,
          // postMediaRelations: post.postMediaRelations,

          /**
           * @deprecated
           * スプレッド構文で２つのエンティティをまぜるな危険
           * idがpostMediaのIdなのか、postMediaRelationのIdなのかLostする為、 ここがバグの温床になっている
           */
          postMediaRelations: post.postMediaRelations.map(pMedia => ({
            ...pMedia.postMedia,
            ...pMedia.postMediaRelation,
          })),

          /**
           * #3329
           * @deprecated instagramUserTags. instead use postMediaRelations.map(x => x.postMediaInstagramUserTags)
           * @deprecated instagramProductTags. instead use postMediaRelations.map(x => x.postMediaInstagramProductTags)
           * Eliminates the need to maintain postMediaId and index
           */
          instagramUserTags,
          instagramProductTags,
          options: post.poll?.options.map(e => ({ value: e })),
          ...minToDate
        })
      )
      dispatch(PostActions.setPost(Post.fromResponse(post)))
    }
  }, [
    post,
    account,
    settingsRefetch,
    statusRefetch,
    postCommentsRefetch,
    snsAccountPostCaptionRulesRefetch,
    postCommentReactionsRefetch,
    accountRefetch,
    postRefetch,
    postApprovalTodosRefetch,
    dispatch
  ])
  return {
    post,
    account,
    status,
    settings,
    postComments,
    postCommentReactions,
    postApprovalTodoChecks,
    snsAccountPostCaptionRules,
    isLoading,
    postRefetch,
    accountRefetch,
    postCommentsRefetch,
    postCommentReactionsRefetch,
    postApprovalTodosRefetch,
    snsAccountPostCaptionRulesRefetch
  }
}

export { usePostAccount, usePost, useApprovalSettings, useApprovalStatus }

export const usePostCommentFormAction = () => {
  const methods = useForm({ mode: 'onBlur' })
  const { account, post, postCommentsRefetch } = usePostDetail()
  const { mutate, isSuccess } = useRegisterPostCommentMutation()
  const handleSubmit = useCallback(
    ({ text, id, cancelFlg }: { text: string; id?: number; cancelFlg?: boolean }) => {
      mutate({
        postComment: {
          snsAccountId: account?.snsAccountId,
          postId: post?.post.id,
          text,
          id,
          cancelFlg: cancelFlg || false
        }
      })
      methods.reset()
    },
    [account, methods, mutate, post]
  )
  useEffect(() => {
    isSuccess && postCommentsRefetch()
  }, [isSuccess, postCommentsRefetch])
  return { methods, handleSubmit, postStatus: post?.post.status }
}

export const usePostCommentReactionFormAction = () => {
  const { post, postCommentReactionsRefetch } = usePostDetail()
  const { mutate: registerMutate, isSuccess: isRegisterSuccess } = useRegisterPostCommentReactionMutation()
  const { mutate: deleteMutate, isSuccess: isDeleteSuccess } = useDeletePostCommentReactionMutation()
  const registerReaction = (postCommentId: number, emojiText: string) => {
    registerMutate({
      postId: post?.post.id,
      postCommentId,
      emojiText
    })
  }

  const deleteReaction = (postCommentReactionId: number) => {
    deleteMutate({
      id: postCommentReactionId
    })
  }

  useEffect(() => {
    ;(isRegisterSuccess || isDeleteSuccess) && postCommentReactionsRefetch()
  }, [isRegisterSuccess, isDeleteSuccess, postCommentReactionsRefetch])
  return { registerReaction, deleteReaction }
}

export const useCampaignPost = () => {
  const dispatch = useDispatch()
  const { params } = useQueryStringSearch()
  const { campaignId } = params
  const { data: campaigns, isFetched: campaignIsFetched } = useCampaignsQuery({
    key: [{ id: +campaignId }],
    payload: { campaign: { id: +campaignId } },
    options: { enabled: !!campaignId }
  })
  const campaign = first(campaigns?.data)
  const mId = campaign?.snsAccount.mId
  const { data: account, isFetched: accountIsFetched } = useAccountQuery({
    payload: { account: { mId } },
    key: [{ mId }],
    options: { enabled: !!mId }
  })

  useEffect(() => {
    if (campaignIsFetched && accountIsFetched) {
      dispatch(InsightActions.setAccount(Account.fromResponse(account?.data)))
    }
  }, [account, dispatch, campaignIsFetched, accountIsFetched])

  return { campaign }
}


export const useCommerce = (mId: string) => {
  const { catalogProducts, isLoading } = useCommerceCatalogProducts(mId)
  const products: Product[] = catalogProducts.flatMap(catalog => catalog.products || []);
  return { catalogProducts, products, isLoading }
}