import { List, Record } from 'immutable'
import { InsightActions } from 'src/redux/actions/Insight'
import { TResponseCommon } from 'src/modules/entity/API'
import { Abolish, Account, TAccount } from 'src/redux/models/InsightInfo'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

export type TInsightState = TResponseCommon & {
  abolish: Abolish
  account: TAccount
  accounts: List<Account>
  accountsNextPage: number
  accountsRefresh: boolean
  moreAccounts: boolean
  accountRefresh: boolean
}
export class InsightState extends Record<TInsightState>({
  abolish: new Abolish(),
  account: new Account(),
  accounts: List(),
  accountsNextPage: 1,
  accountsRefresh: true,
  moreAccounts: false,
  accountRefresh: false,
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const InsightReducer = reducerWithInitialState(new InsightState())
  .case(InsightActions.initializeInsight, (state, payload) => new InsightState())

  .case(InsightActions.getIniAccounts, (state, payload) =>
    state.withMutations(s => s.set('accountsNextPage', 2).set('error', null))
  )
  .case(InsightActions.getIniAccount, (state, payload) => state.withMutations(s => s.set('error', null)))
  .case(InsightActions.getAddAccounts, (state, payload) => state.withMutations(s => s.set('error', null)))
  .case(InsightActions.getAllAccounts, (state, payload) => state.withMutations(s => s.set('error', null)))

  .case(InsightActions.setAbolish, (state, payload) =>
    state.withMutations(s => s.set('abolish', payload).set('error', null))
  )
  .case(InsightActions.setAccounts, (state, payload) =>
    state.withMutations(s => s.set('accounts', payload).set('error', null))
  )
  .case(InsightActions.setAccountsNextPage, (state, payload) =>
    state.withMutations(s => s.set('accountsNextPage', payload))
  )
  .case(InsightActions.setMoreAccounts, (state, payload) =>
    state.withMutations(s => s.set('moreAccounts', payload).set('error', null))
  )
  .case(InsightActions.setAccountsRefresh, (state, payload) =>
    state.withMutations(s => s.set('accountsRefresh', payload))
  )
  .case(InsightActions.setAccountRefresh, (state, payload) =>
    state.withMutations(s => s.set('accountRefresh', payload))
  )
  .case(InsightActions.setAccount, (state, payload) =>
    state.withMutations(s => s.set('account', payload).set('error', null))
  )
  .case(InsightActions.setMediaInsights, (state, payload) =>
    state.withMutations(s => s.set('account', payload).set('error', null))
  )
  .case(InsightActions.setLostDate, (state, payload) =>
    state.withMutations(s => s.setIn(['account', 'lostDate'], payload).set('error', null))
  )
  .case(InsightActions.setError, (state, payload) =>
    state.withMutations(s => s.set('error', payload).set('notified', false))
  )
  .case(InsightActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('notified', false)
    )
  )
  .case(InsightActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
  .case(InsightActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))
export default InsightReducer
