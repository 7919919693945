import * as H from 'history'
import * as queryString from 'querystring'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isEmptyObject } from 'src/util/object'

type QueryStringSearch = {
  params: Record<string, any>
  location: H.Location
  history: H.History
  setUrl: (query: Record<string, any>) => string
  isPushed: boolean
  setPush: (p: boolean) => void
}

export function useQueryStringSearch(): QueryStringSearch {
  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const [isPushed, setPush] = useState(true)
  const params = queryString.parse(search.substr(1))
  const setUrl = useCallback(
    query => {
      return `${location.pathname}?${queryString.stringify(query)}`
    },
    [location.pathname]
  )
  return { params, location, history, setUrl, isPushed, setPush }
}

type Pagination = {
  page: string | string[]
  setLinkUrl: (page: number) => string
}

export const usePagination = (): Pagination => {
  const location = useLocation()
  const query = queryString.parse(location.search.substr(1))
  const setLinkUrl = useCallback(
    page => {
      const obj = {
        ...query,
        page
      }
      return `${location.pathname}?${queryString.stringify(obj)}`
    },
    [location.pathname, query]
  )

  return { page: query.page, setLinkUrl }
}

export type ReplaceToDefaultPage = {
  [key: string]: string | number | boolean
}
export const useReplaceToDefaultPage = (defaultQueries: ReplaceToDefaultPage) => {
  const history = useHistory()
  const location = useLocation()
  const [isReplaced, setReplace] = useState(false)
  const params = queryString.parse(location.search.substr(1))
  const setUrl = useCallback(
    query => {
      return `${location.pathname}?${queryString.stringify(query)}`
    },
    [location.pathname]
  )
  useEffect(() => {
    if (!isReplaced && defaultQueries && isEmptyObject(params)) {
      history.replace(setUrl({ ...defaultQueries }))
      setReplace(true)
    }
  }, [defaultQueries, history, isReplaced, params, setUrl])

  return { params, isReplaced }
}
