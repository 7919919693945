import { TApiHeadersCommon } from 'src/modules/entity/API'
import { CommonApi } from 'src/redux/api/commonApi'
import { QueryObserverResult, useQuery } from 'react-query'
import { State } from 'src/redux/reducers'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import isEmpty from 'lodash/isEmpty'
import { ActivityQuery, ActivityQueryVariables } from '../entity/activity'

export class ActivityApi extends CommonApi {
  static fetch = (params: ActivityQueryVariables, headers: TApiHeadersCommon) =>
    ActivityApi.get('/activity', params, headers)
}

export const useActivitiesQuery = (payload: ActivityQueryVariables): QueryObserverResult<ActivityQuery[], any> => {
  const token: string = useShallowEqualSelector((state: State) => state.Auth.getIn(['user', 'token']))
  return useQuery('activities', async () => {
    const res = await ActivityApi.fetch(payload, { token })
    return !isEmpty(res.data) ? res.data : []
  })
}
