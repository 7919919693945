import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from 'src/modules/core/errors'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  ApprovalSettingsQuery,
  ApprovalStatusQuery,
  GetSettingsVariables,
  GetStatusVariables,
  RegisterSettingsVariables,
  RegisterStatusVariables,
  GetTodosVariables,
  RegisterTodoVariables,
  DeleteTodoVariables,
  ApprovalTodosQuery,
  GetTodoChecksVariables,
  RegisterTodoCheckVariables,
  ApprovalTodoCheckQuery
} from 'src/modules/entity/approval'
import { CommonApi } from 'src/redux/api/commonApi'

class ApprovalApi extends CommonApi {
  static getSettings = (params: GetSettingsVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.get<ApprovalSettingsQuery>('/approval/settings', params, headers)
  static registerSettings = (params: RegisterSettingsVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.post('/approval/settings', params, headers)
  static getStatus = (params: GetStatusVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.get<ApprovalStatusQuery>('/approval/status', params, headers)
  static registerStatus = (params: RegisterStatusVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.post('/approval/status', params, headers)
  static getTodos = (params: GetTodosVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.get<ApprovalTodosQuery>('/approval/todos', params, headers)
  static registerTodo = (params: RegisterTodoVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.post('/approval/todo', params, headers)
  static deleteTodo = (params: DeleteTodoVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.delete(`/approval/todo/${params.id}`, {}, headers)
  static getTodoChecks = (params: GetTodoChecksVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.get<ApprovalTodoCheckQuery>('/approval/todos/check', params, headers)
  static registerTodoCheck = (params: RegisterTodoCheckVariables, headers: TApiHeadersCommon) =>
    ApprovalApi.post('/approval/todos/check', params, headers)
}

export const useApprovalSettingsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetSettingsVariables>): ReactQueryResult<ApprovalSettingsQuery> => {
  return useQueryWrapper<ApprovalSettingsQuery>({
    queryKey: 'approvalSettings',
    deps: key,
    req: ({ token }) => ApprovalApi.getSettings(payload, { token }),
    options
  })
}

export const useApprovalStatusQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetStatusVariables>): ReactQueryResult<ApprovalStatusQuery> => {
  return useQueryWrapper<ApprovalStatusQuery>({
    queryKey: 'approvalStatus',
    deps: key,
    req: ({ token }) => ApprovalApi.getStatus(payload, { token }),
    options
  })
}

export const useUpdateApprovalMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RegisterStatusVariables>({
    req: (payload, { token }) => ApprovalApi.registerStatus(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({
          result: 'error',
          message: genMessage(res.status, 'エラーにより操作完了できませんでした'),
          status: res.status
        })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '更新しました' })
      }
    }
  })
}

export const useRegisterApprovalSettingsMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RegisterSettingsVariables>({
    req: (payload, { token }) => ApprovalApi.registerSettings(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '設定を保存できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '設定を保存しました' })
      }
    }
  })
}

export const useApprovalTodosQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetTodosVariables>): ReactQueryResult<ApprovalTodosQuery> => {
  return useQueryWrapper<ApprovalTodosQuery>({
    queryKey: 'approvalTodos',
    deps: key,
    req: ({ token }) => ApprovalApi.getTodos(payload, { token }),
    options
  })
}

export const useRegisterApprovalTodoMutation = () => {
  return useMutationWrapper<RegisterTodoVariables>({
    req: (payload, { token }) => ApprovalApi.registerTodo(payload, { token })
  })
}

export const useDeleteApprovalTodoMutation = () => {
  return useMutationWrapper<DeleteTodoVariables>({
    req: (payload, { token }) => ApprovalApi.deleteTodo(payload, { token })
  })
}

export const useApprovalTodoChecksQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetTodoChecksVariables>): ReactQueryResult<ApprovalTodoCheckQuery> => {
  return useQueryWrapper<ApprovalTodoCheckQuery>({
    queryKey: 'approvalTodoChecks',
    deps: key,
    req: ({ token }) => ApprovalApi.getTodoChecks(payload, { token }),
    options
  })
}

export const useRegisterApprovalTodoCheckMutation = () => {
  return useMutationWrapper<RegisterTodoCheckVariables>({
    req: (payload, { token }) => ApprovalApi.registerTodoCheck(payload, { token })
  })
}
