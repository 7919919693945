import { List } from 'immutable'
import actionCreatorFactory from 'typescript-fsa'
import { Abolish, Account, TAbolishOrder, TAccountOrder, TAccountsOrder } from 'src/redux/models/InsightInfo'
import { TError } from 'src/modules/entity/API'
const actionCreator = actionCreatorFactory('INSIGHT')

/***
 * get for saga
 * set for reducer
 */
export const InsightActions = {
  initializeInsight: actionCreator('INITIALIZE_INSIGHT'),

  getIniAccounts: actionCreator<TAccountsOrder>('GET_INI_ACCOUNTS'),
  getAddAccounts: actionCreator<TAccountsOrder>('GET_ADD_ACCOUNTS'),
  getAllAccounts: actionCreator<TAccountsOrder>('GET_ALL_ACCOUNTS'),
  getIniAccount: actionCreator<TAccountOrder>('GET_INI_ACCOUNT'),
  getAccount: actionCreator<TAccountOrder>('GET_ACCOUNT'),
  getAbolish: actionCreator<TAbolishOrder>('GET_ABOLISH'),

  setAccounts: actionCreator<List<Account>>('SET_ACCOUNTS'),
  setMoreAccounts: actionCreator<boolean>('SET_MORE_ACCOUNTS'),
  setAccountsNextPage: actionCreator<number>('SET_ACCOUNTS_PAGE_NUM'),
  setAccountsRefresh: actionCreator<boolean>('SET_ACCOUNTS_REFRESH'),

  setAccount: actionCreator<Account>('SET_ACCOUNT'),
  setAccountRefresh: actionCreator<boolean>('SET_ACCOUNT_REFRESH'),
  setMediaInsights: actionCreator<Account>('SET_MEDIA_INSIGHTS'),
  setLostDate: actionCreator<List<string>>('SET_LOST_DATE'),
  setAbolish: actionCreator<Abolish>('SET_ABOLISH'),

  setError: actionCreator<TError>('SET_ERROR'),
  setLoading: actionCreator<boolean>('SET_LOADING'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
