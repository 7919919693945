import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from '../entity/API'
import { CommonApi } from 'src/redux/api/commonApi'
import {
  CampaignMutationVariables,
  CampaignQuery,
  CampaignsQueryVariables,
  CampaignUpdateVariables
} from '../entity/campaigns'
import { useQueryWrapper } from '../core/query'
import { useMutationWrapper } from '../core/mutation'
import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from '../core/errors'

class CampaignApi extends CommonApi {
  static register = (params: CampaignMutationVariables, headers: TApiHeadersCommon) =>
    CampaignApi.post('/campaigns', params, headers)
  static abolish = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    CampaignApi.post('/campaigns/abolish', params, headers)
  static approve = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    CampaignApi.post('/campaigns/approval', params, headers)
  static fetch = (params: CampaignsQueryVariables, headers: TApiHeadersCommon) =>
    CampaignApi.get('/campaigns', params, headers)
  static select = (params: CampaignsQueryVariables, headers: TApiHeadersCommon) =>
    CampaignApi.get(`/campaign/${params.campaign.id}`, {}, headers)
  static remove = (params: CampaignUpdateVariables, headers: TApiHeadersCommon) =>
    CampaignApi.delete(`/campaigns/${params.campaign.id}`, {}, headers)
}

export const useCampaignsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<CampaignsQueryVariables>): ReactQueryResult<CampaignQuery[]> => {
  return useQueryWrapper<CampaignQuery[]>({
    queryKey: 'campaigns',
    deps: key,
    req: ({ token }) => CampaignApi.fetch(payload, { token }),
    options
  })
}

export const useCampaignSelectQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<CampaignsQueryVariables>): ReactQueryResult<CampaignQuery> => {
  return useQueryWrapper<CampaignQuery>({
    queryKey: 'campaignsSelect',
    deps: key,
    req: ({ token }) => CampaignApi.select(payload, { token }),
    options
  })
}

export const useCreateCampaignMutation = () => {
  return useMutationWrapper<CampaignMutationVariables>({
    req: (payload, { token }) => CampaignApi.register(payload, { token })
  })
}

export const useAbolishCampaignMutation = () => {
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => CampaignApi.abolish(payload, { token })
  })
}

export const useApproveCampaignMutation = () => {
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => CampaignApi.approve(payload, { token })
  })
}

export const useRemoveCampaignMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<CampaignUpdateVariables>({
    req: (payload, { token }) => CampaignApi.remove(payload, { token }),
    toaster: res => {
      if (!res.data || res.error) {
        addToast({
          result: 'error',
          message: genMessage(res.status, 'このキャンペーンは削除できません。')
        })
      }
      if (res.data && res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}
