import { useMutationWrapper } from 'src/modules/core/mutation'
import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { EventDeleteMutationVariables, EventMutationVariables } from 'src/modules/entity/event'

export class EventApi extends CommonApi {
  static save = (params: EventMutationVariables, headers: TApiHeadersCommon) =>
    EventApi.post('/events', params, headers)
  static bulk = (params: EventMutationVariables, headers: TApiHeadersCommon) =>
    EventApi.post('/events/bulk', params, headers)
  static remove = (params: EventDeleteMutationVariables, headers: TApiHeadersCommon) =>
    EventApi.delete(`/events/${params.id}`, params, headers)
}

export const useEventSaveMutation = () => {
  return useMutationWrapper<EventMutationVariables>({
    req: (payload, { token }) => EventApi.save(payload, { token })
  })
}

export const useEventBulkMutation = () => {
  return useMutationWrapper<EventMutationVariables>({
    req: (payload, { token }) => EventApi.bulk(payload, { token })
  })
}

export const useEventDeleteMutation = () => {
  return useMutationWrapper<EventDeleteMutationVariables>({
    req: (payload, { token }) => EventApi.remove(payload, { token })
  })
}
