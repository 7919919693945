import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import { CommonApi } from 'src/redux/api/commonApi'
import {
  RegisterFollowerWatchStartVariables,
  FollowerCsvListVariables,
  FollowerCsvDownloadVariables,
  FollowerCsvList,
  CountGettingFollowerVariables,
  GettingFollowerCount
} from 'src/modules/entity/followerCollect'

export class FollowerCollectApi extends CommonApi {
  static registerFollowerWatchStart = (params: RegisterFollowerWatchStartVariables, headers: TApiHeadersCommon) =>
    FollowerCollectApi.post('/sns_accounts/follower/watch/start', params, headers)
  static countGettingFollower = (params: CountGettingFollowerVariables, headers: TApiHeadersCommon) =>
    FollowerCollectApi.get('/sns_accounts/follower/count', params, headers)
  static getFollowerCsvList = (params: FollowerCsvListVariables, headers: TApiHeadersCommon) =>
    FollowerCollectApi.get('/sns_accounts/follower/csv/list', params, headers)
  static downloadFollowerCsv = (params: FollowerCsvDownloadVariables, headers: TApiHeadersCommon) =>
    FollowerCollectApi.get('/sns_accounts/follower/csv/download', params, headers)
}

export const useFollowerWatchStartMutation = () => {
  return useMutationWrapper<RegisterFollowerWatchStartVariables>({
    req: (payload, { token }) => FollowerCollectApi.registerFollowerWatchStart(payload, { token })
  })
}

export const useCountGettingFollower = ({ payload, key, options }: TUseQueryOptions<CountGettingFollowerVariables>) => {
  return useQueryWrapper<GettingFollowerCount>({
    queryKey: 'followerCollectCountFollower',
    deps: key,
    req: ({ token }) => FollowerCollectApi.countGettingFollower(payload, { token }),
    options
  })
}
export const useFetchFollowerCsvList = ({ payload, key, options }: TUseQueryOptions<FollowerCsvListVariables>) => {
  return useQueryWrapper<FollowerCsvList[]>({
    queryKey: 'followerCollectCsvList',
    deps: key,
    req: ({ token }) => FollowerCollectApi.getFollowerCsvList(payload, { token }),
    options
  })
}

export const useDownloadFollowerCsvMutation = () => {
  return useMutationWrapper<FollowerCsvDownloadVariables>({
    req: (payload, { token }) => FollowerCollectApi.downloadFollowerCsv(payload, { token })
  })
}
