import {
  Post,
  RegisterPostResult,
  TFinishEditPostOrder,
  TPostFormValuesPostMediaRelations,
  TRegisterPostOrder,
  TUpdatePostStatusOrder
} from 'src/redux/models/PostInfo'
import actionCreatorFactory from 'typescript-fsa'
import { TError } from 'src/modules/entity/API'
import { InstagramProductTag, InstagramUserTag } from 'src/modules/entity/post'

const actionCreator = actionCreatorFactory('POST')

/***
 * get for saga
 * set for reducer
 *
 * web/src/redux/reducers/PostReducer.ts
 * https://github.com/CyberBuzz/slider-web/blob/965e7dd261cf64bf549f94ea3277ffcc897aad37/web/src/redux/reducers/PostReducer.ts
 */
export const PostActions = {
  initializePost: actionCreator('INITIALIZE_POST'),

  registerPost: actionCreator<TRegisterPostOrder>('REGISTER_POST'),

  setPost: actionCreator<Post>('SET_POST'),
  setPostFormValues: actionCreator<any>('SET_POST_FORM_VALUES'),
  setPostMediaRelations: actionCreator<TPostFormValuesPostMediaRelations[]>('SET_POST_MEDIA_RELATIONS'),
  setPostRefresh: actionCreator<boolean>('SET_POST_REFRESH'),
  setPostMediaRefresh: actionCreator<boolean>('SET_POST_MEDIA_REFRESH'),
  setCarouselNum: actionCreator<number>('SET_CAROUSEL_NUM'),
  setRegisterPost: actionCreator<RegisterPostResult>('SET_REGISTER_POST'),
  setRegisterInstagramUserTags: actionCreator<InstagramUserTag[]>('SET_POST_INSTAGRAM_USER_TAGS'),
  setRegisterInstagramProductTags: actionCreator<InstagramProductTag[]>('SET_POST_INSTAGRAM_PRODUCT_TAGS'),
  setLoading: actionCreator<boolean>('SET_POST_LOADING'),
  setError: actionCreator<TError>('SET_ERROR'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED'),

  /*** update status ***/
  updatePostStatus: actionCreator<TUpdatePostStatusOrder>('UPDATE_POST_STATUS'),
  finishEditPost: actionCreator<TFinishEditPostOrder>('FINISH_EDIT')
}
