import { CommonApi } from 'src/redux/api/commonApi'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import { CommerceCatalogProducts, GetCatalogProductVariables } from "../entity/commerce";
import { useQueryWrapper } from "../core/query";

export class CommerceApi extends CommonApi {
    static fetchCatalogProducts = (params: GetCatalogProductVariables, headers: TApiHeadersCommon) => {
        return CommerceApi.get<CommerceCatalogProducts>(`/ig/commerce/catalogs`, params, headers)
    }
}

export const useCatalogProductsQuery = ({
    payload,
    key,
    options
}: TUseQueryOptions<GetCatalogProductVariables>): ReactQueryResult<CommerceCatalogProducts|null> => {
    return useQueryWrapper<CommerceCatalogProducts>({
        queryKey: 'commerceCatalogProducts',
        deps: key,
        req: ({ token }) => CommerceApi.fetchCatalogProducts(payload, { token }),
        options
    })
}