import { CommonApi } from 'src/redux/api/commonApi'
import { useToast } from 'src/components/Toast/ToastProvider'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { FederateFacebookPageVariables } from 'src/modules/entity/facebook'
import { useMutationWrapper } from '../core/mutation'

export class FacebookApi extends CommonApi {
  static federateFacebookPage = (params: FederateFacebookPageVariables, headers: TApiHeadersCommon) =>
    FacebookApi.post('/oauth/federate/facebook/pages', params, headers)
}

export const useFederateFacebookPageMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<FederateFacebookPageVariables>({
    req: (payload, { token }) => FacebookApi.federateFacebookPage(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: 'Facebookページの連携に失敗しました', status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: 'Facebookページを連携しました' })
      }
    }
  })
}
