import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import find from 'lodash/find'
import { CONTENT_TYPES } from 'src/constants/attachment'
import { SNS, BYTES } from 'src/constants/posts'
import { POTS_MEDIA_STATUS, LIBRARY_MEDIA_KIND } from 'src/constants/attachment'
import { TProviderValidities, RegisterPostMediaVariables } from 'src/redux/models/LibraryInfo'
import {
  convertFileToBase64,
  dataURLtoFile,
  getImageWH,
  getThumbnail,
  getVideoDuration,
  isImage,
  isVideo,
  getVideoMeta,
  getMimeTypeFromFileName
} from './post/media'
import { between, calcProportion } from 'src/util/number'
import get from 'lodash/get'

dayjs.locale('ja')

const libraryKindsPayload = [
  { name: '', param: { postMedia: { status: POTS_MEDIA_STATUS.active } } },
  { name: 'upload', param: { kind: LIBRARY_MEDIA_KIND.upload, postMedia: { status: POTS_MEDIA_STATUS.active } } },
  { name: 'archive', param: { postMedia: { status: POTS_MEDIA_STATUS.archive } } }
]

export const threeMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0)
export const lastTimeOfToday = new Date(new Date().setHours(23, 59, 59, 0)).getTime()

export const formatConditions = (conditions: any) => {
  const kind = find(libraryKindsPayload, k => k.name === (conditions?.kind || ''))?.param
  const postMedia = {
    postMedia: {
      status: kind.postMedia.status,
      storageAtFrom: conditions?.from && dayjs(conditions?.from).toISOString(),
      storageAtTo:
        conditions?.to &&
        dayjs(conditions.to)
          .endOf('date')
          .toISOString(),
      statusUpdateAtFrom: conditions.from && dayjs(conditions?.from).toISOString(),
      statusUpdateAtTo:
        conditions?.to &&
        dayjs(conditions.to)
          .endOf('date')
          .toISOString()
    }
  }

  const ugcSetting = {
    ugcSetting: { id: +conditions.ugcSettingId === 0 || conditions.kind === 'upload' ? null : conditions.ugcSettingId }
  }

  const postMediaTagRelation = {
    postMediaTagRelation: {
      postMediaTagIds:
        typeof conditions?.tags === 'string' ? [+conditions?.tags] : conditions?.tags?.map((e: string) => +e) || []
    }
  }
  const sort = { 'sort[]': 'id.desc' }
  const payload = { ...postMedia, ...ugcSetting, ...sort, ...postMediaTagRelation }

  return payload
}

const getFileInfo = async (file: File | Blob) => {
  const { type, size } = file
  const filename = get(file, 'name')
  const isVideoFile = type ? isVideo({ mimeType: type }) : isVideo({ filename })
  const fileType = type || getMimeTypeFromFileName(filename)
  return { type: fileType, size, isVideoFile }
}

// TODO バックエンドにメディアバリデーションを移行したら削除する
export const twitterUploadMediaValidation = async (
  file: File | Blob
): Promise<Pick<TProviderValidities, 'isTwitter'>> => {
  const { type, size, isVideoFile } = await getFileInfo(file)
  // 動画ファイルでtypeが空文字だったら、ファイルサイズでvalidation
  if (size > SNS.twitter.media.video.max.bytes) return { isTwitter: false }
  const fileBase64 = await convertFileToBase64(file)
  const { width, height } = isImage({ mimeType: type }) && (await getImageWH(fileBase64))
  const duration = isVideoFile && (await getVideoDuration(file))
  // pngファイルはwidth:900px/height:900px以下 まで。
  const isSafePng = type === CONTENT_TYPES.png && (width <= 900 || height <= 900)
  // jpgファイルは長辺4096px以下。画像容量は5MB未満まで
  const isSafeJpeg =
    type === CONTENT_TYPES.jpeg && (width <= 4096 || height <= 4096 || size <= SNS.twitter.media.image.max.bytes)
  // 動画ファイルの長さ(秒数)は140秒まで
  const isSafeVideo = isVideoFile && duration <= SNS.twitter.media.video.max.duration
  // TODO twitter で使用できる画像ファイルタイプのチェック
  const isValid = isSafePng || isSafeJpeg || isSafeVideo
  return { isTwitter: isValid }
}

export const instagramUploadMediaValidation = async (
  file: File | Blob
): Promise<Pick<TProviderValidities, 'isInstagram'>> => {
  const { type, size, isVideoFile } = await getFileInfo(file)
  // 動画ファイルでfile.typeが空文字だったら、ファイルサイズでvalidation
  if (size > SNS.instagram.media.video.max.bytes) return { isInstagram: false }
  const { width, height } = isVideoFile
    ? await getImageWH((await getThumbnail(file as File, 1, type)).thumbnail)
    : await getImageWH(await convertFileToBase64(file))
  // 画像と動画のアスペクト比制限は等しいためisVideoFileの制御なし
  const maxHeight = calcProportion({
    outerTerm: SNS.instagram.media.video.max.widthRatio,
    innerTermX: SNS.instagram.media.video.max.heightRatio,
    innerTermY: width
  })
  const minHeight = isVideoFile
    ? calcProportion({
        outerTerm: SNS.instagram.media.video.min.widthRatio,
        innerTermX: SNS.instagram.media.video.min.heightRatio,
        innerTermY: width
      })
    : calcProportion({
        outerTerm: SNS.instagram.media.image.min.widthRatio,
        innerTermX: SNS.instagram.media.image.min.heightRatio,
        innerTermY: width
      })
  const isAllowedAspect = between(height, minHeight, maxHeight)
  const isAllowedSize = isVideoFile
    ? size <= SNS.instagram.media.video.max.bytes
    : size <= SNS.instagram.media.image.max.bytes

  if (isVideo({ mimeType: file.type })) {
    const duration = await getVideoDuration(file)
    const isAllowedWidth = width <= 1920
    const isAllowedDuration = between(
      duration as number,
      SNS.instagram.media.video.min.duration,
      SNS.instagram.media.video.max.duration
    )
    const isValid = isAllowedSize && isAllowedDuration && isAllowedAspect && isAllowedWidth
    return { isInstagram: isValid }
  } else {
    const isAllowedType = type === CONTENT_TYPES.jpeg
    const isAllowedWidth = width >= SNS.instagram.media.image.min.width && width <= SNS.instagram.media.image.max.width
    const isValid = isAllowedType && isAllowedSize && isAllowedAspect && isAllowedWidth
    return { isInstagram: isValid }
  }
}
export const instagramReelUploadMediaValidation = async (
    file: File | Blob
): Promise<Pick<TProviderValidities, 'isInstagramReel'>> => {
  const { type, size, isVideoFile } = await getFileInfo(file)
  // 動画ファイルでfile.typeが空文字だったら、ファイルサイズでvalidation
  if (size > SNS.instagramReel.media.video.max.bytes) return { isInstagramReel: false }
  const { width, height } = isVideoFile
      ? await getImageWH((await getThumbnail(file as File, 1, type)).thumbnail)
      : await getImageWH(await convertFileToBase64(file))
  const maxHeightDecimal = isVideoFile
      ? calcProportion({
        outerTerm: SNS.instagramReel.media.video.max.widthRatio,
        innerTermX: SNS.instagramReel.media.video.max.heightRatio,
        innerTermY: width
      })
      : calcProportion({
        outerTerm: SNS.instagramReel.media.image.max.widthRatio,
        innerTermX: SNS.instagramReel.media.image.max.heightRatio,
        innerTermY: width
      })
  const maxHeight = Math.ceil(maxHeightDecimal)
  const minHeightDecimal = isVideoFile
      ? calcProportion({
        outerTerm: SNS.instagramReel.media.video.min.widthRatio,
        innerTermX: SNS.instagramReel.media.video.min.heightRatio,
        innerTermY: width
      })
      : calcProportion({
        outerTerm: SNS.instagramReel.media.image.min.widthRatio,
        innerTermX: SNS.instagramReel.media.image.min.heightRatio,
        innerTermY: width
      })
  const minHeight = Math.ceil(minHeightDecimal)
  const isAllowedAspect = between(height, minHeight, maxHeight)
  const isAllowedSize = isVideoFile
      ? size <= SNS.instagramReel.media.video.max.bytes
      : size <= SNS.instagramReel.media.image.max.bytes
  if (isVideo({ mimeType: file.type })) {
    const duration = await getVideoDuration(file)
    const isAllowedWidth = width <= 1920
    const isAllowedDuration = between(
        duration as number,
        SNS.instagramReel.media.video.min.duration,
        SNS.instagramReel.media.video.max.duration
    )
    const isValid = isAllowedSize && isAllowedDuration && isAllowedAspect && isAllowedWidth
    return { isInstagramReel: isValid }
  } else {
    const isAllowedType = type === CONTENT_TYPES.jpeg
    const isAllowedWidth = true
    const isValid = isAllowedType && isAllowedSize && isAllowedAspect && isAllowedWidth
    return { isInstagramReel: isValid }
  }
}

/**
 * facebook のメディア投稿validator
 * 以下仕様
 * 画像: https://developers.facebook.com/docs/graph-api/reference/page/photos/
 * 動画: https://developers.facebook.com/docs/graph-api/reference/video/
 *
 * @param {(File | Blob)} file
 * @returns {Promise<Pick<TProviderValidities, 'isFacebook'>>}
 */
export const facebookUploadMediaValidation = async (
  file: File | Blob
): Promise<Pick<TProviderValidities, 'isFacebook'>> => {
  const { type, size, isVideoFile } = await getFileInfo(file)
  const { width } = isVideoFile
    ? await getImageWH((await getThumbnail(file as File, 1, type)).thumbnail)
    : await getImageWH(await convertFileToBase64(file))
  const isAllowedWidth = width >= SNS.facebook.media.image.min.width && width <= SNS.facebook.media.image.max.width
  // 動画ファイルでfile.typeが空文字だったら、ファイルサイズでvalidation
  if (isVideoFile || size > SNS.facebook.media.image.max.bytes) {
    const video = isVideoFile && await getVideoMeta({ file })
    const maxWidth = calcProportion({
      outerTerm: SNS.facebook.media.video.max.widthRatio,
      innerTermX: SNS.facebook.media.video.max.heightRatio,
      innerTermY: video.videoHeight
    })
    const mixWidth = calcProportion({
      outerTerm: SNS.facebook.media.video.min.widthRatio,
      innerTermX: SNS.facebook.media.video.min.heightRatio,
      innerTermY: video.videoHeight
    })
    const isAllowedSize = size <= SNS.facebook.media.video.max.bytes
    const isAllowedAspect = isVideoFile ? between(video.videoWidth, mixWidth, maxWidth) : true
    const isAllowedDuration = isVideoFile ? between(
      video.duration as number,
      SNS.facebook.media.video.min.duration,
      SNS.facebook.media.video.max.duration
    ): true
    const isValid = isAllowedSize && isAllowedDuration && isAllowedAspect && isAllowedWidth
    return { isFacebook: isValid }
  }
  // png 画像のみ 1MB制限, 他画像は4MB
  const isAllowedSize = type === CONTENT_TYPES.png ? size <= BYTES.oneMB : size <= SNS.facebook.media.image.max.bytes
  const isValid = isAllowedSize && isAllowedWidth
  return { isFacebook: isValid }
}

// TODO UGC のリファクタ終わったら削除, Canva にも使用されてる
export const getMediaValidityOfProviders = async (
  files: File[]
): Promise<{
  error: string
  uploadFiles: RegisterPostMediaVariables[]
}> => {
  const uploadFiles = await Promise.all(
    files.map(async (f: File) => {
      const twitterValidCheck = await twitterUploadMediaValidation(f)
      const instagramValidCheck = await instagramUploadMediaValidation(f)
      const instagramReelValidCheck = await instagramReelUploadMediaValidation(f)
      const facebookValidCheck = await facebookUploadMediaValidation(f)
      return { upload: f, ...twitterValidCheck, ...instagramValidCheck, ...instagramReelValidCheck, ...facebookValidCheck }
    })
  )
  return { error: '', uploadFiles: uploadFiles }
}

export const getVideoUploadPayload = async (uploads: RegisterPostMediaVariables[]) =>
  await Promise.all(
    uploads.map(async f => {
      return isVideo({ mimeType: f.upload.type })
        ? getThumbnail(f.upload, 2, f.upload.type)
            .then(async thumbnail => ({
              ...f,
              thumbnail: await dataURLtoFile(
                thumbnail?.thumbnail,
                thumbnail?.filename?.replace(/(\.mp4|\.mov)$/, '.png')
              )
            }))
            .catch(e => {
              console.error('Error on execute upload : ', e)
              throw Error(e)
            })
        : { ...f }
    })
  )
