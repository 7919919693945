import { TPaging } from 'src/modules/entity/API'
import { JSObject } from '../../../types/common'
import { List, Record } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import dayjs, { Dayjs } from 'dayjs'
dayjs.locale('ja')

/*** register models ***/
export type TRegisterPostCommentOrder = {
  postComment: {
    snsAccountId: number
    postId: number
    text: string
  }
}

/*** order models ***/
export type TPostCommentOrder = {
  postComment: {
    snsAccountId: number
    postId: number
  }
  paging?: TPaging
}

/*** store models ***/
export type TPostComment = {
  id: string
  brandId: string
  snsAccountId: string
  postId: string
  userId: string
  text: string
  createId: string
  updateId: string
  createdAt: Dayjs
  updatedAt: Dayjs
  deleteFlg: boolean
}
export class PostComment extends Record<TPostComment>({
  id: '',
  brandId: '',
  snsAccountId: '',
  postId: '',
  userId: '',
  text: '',
  createId: '',
  updateId: '',
  createdAt: dayjs(),
  updatedAt: dayjs(),
  deleteFlg: false
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.createdAt = dayjs(params.createdAt)
    params.updatedAt = dayjs(params.updatedAt)
    return new PostComment(params)
  }
  static iniList(params: JSObject[]): List<PostComment> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => PostComment.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<PostComment>): { list: List<PostComment>; hasMore: boolean } {
    if (isEmpty(params)) {
      return { list: exist, hasMore: false }
    }
    return { list: exist.concat(PostComment.iniList(params)), hasMore: true }
  }
}
