import { Maybe } from '../types'
import { JSObject } from 'types/common'

type Many<T> = T | ReadonlyArray<T>
export const deepFlatten = <T, _>(list: ArrayLike<Many<T>> | null | undefined): T[] =>
  Array.isArray(list) ? list.reduce((a: Array<T>, b: T) => a.concat(Array.isArray(b) ? deepFlatten(b) : b), []) : []

export const find = <T>(arr: T[], fn: (e: T, i?: number, all?: T[]) => boolean): Maybe<T> => {
  if (!Array.isArray(arr)) return undefined
  if (arr.length === 0) return undefined
  return first(arr.filter(fn))
}

export const first = <T>(arr: T[]): Maybe<T> => {
  if (!Array.isArray(arr)) return undefined
  if (arr.length === 0) return undefined
  const copy = arr.slice()
  return copy.shift()
}
export const last = <T>(arr: T[]): Maybe<T> => {
  if (!Array.isArray(arr)) return undefined
  if (arr.length === 0) return undefined
  const copy = arr.slice()
  return copy.pop()
}

export const isArrayEqual = (
  arr1: (string | number | boolean)[],
  arr2: (string | number | boolean)[]
): Maybe<boolean> => {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const isApplicable = <T, _>(arr: T[], target: T): boolean => arr.indexOf(target) !== -1

export const uniqBy = <T = JSObject>(array: readonly T[], key: keyof T): T[] => {
  const seen = new Set()
  return array.filter(it => {
    let val = it[key]
    if (seen.has(val)) {
      return false
    } else {
      seen.add(val)
      return true
    }
  })
}
