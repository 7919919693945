import { useMutation, UseMutationResult } from 'react-query'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { State } from 'src/redux/reducers'
import { useErrors } from 'src/modules/core/errors'

type Props<T> = {
  req: (payload: T, { token }: { token: string }) => Promise<any>
  toaster?: (res: any) => void
}

export const useMutationWrapper = <T>({ req, toaster }: Props<T>): UseMutationResult<unknown, any> => {
  const { setError } = useErrors()
  const token: string = useShallowEqualSelector((state: State) => state.Auth.getIn(['user', 'token']))
  const result = useMutation(async (payload: T) => {
    const res = await req(payload, { token })

    if (toaster) {
      toaster(res)
    }
    // data が null で返却されるケースもあるため、data プロパティがあるかで判定する
    if (res.error || !('data' in res)) {
      if (!toaster) {
        setError(res)
      }
      throw res
    }
    if (res.isSuccess && !toaster && 'data' in res) {
      return res
    }
  })

  return result
}
