import { useMemo } from 'react'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useToast } from 'src/components/Toast/ToastProvider'
import { PAGING_SIZE } from 'src/constants/query'
import { genMessage } from 'src/modules/core/errors'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions, TUseInfiniteQueryOptions } from 'src/modules/entity/API'
import { first } from 'src/util/array'
import { AxiosResponse } from 'types/axios'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { CommonApi } from 'src/redux/api/commonApi'
import {
  AbolishAccountVariables,
  AccountsQuery,
  FacebookPagePostsQuery,
  GetAccountsVariables,
  GetAccountFacebookPagePostsVariables
} from 'src/modules/entity/account'

class InsightApi extends CommonApi {
  static getAccounts = (params: GetAccountsVariables, headers: TApiHeadersCommon) =>
    InsightApi.get<AccountsQuery>('/api/accounts', params, headers)
  static abolish = (params: AbolishAccountVariables, headers: TApiHeadersCommon) =>
    InsightApi.post('/sns_accounts/abolish', params, headers)
  static getFacebookPagePosts = (params: GetAccountFacebookPagePostsVariables, headers: TApiHeadersCommon) =>
    InsightApi.get<FacebookPagePostsQuery>('/api/account/facebook/pages/posts', params, headers)
  static fetchCsv = (params: {}, headers: TApiHeadersCommon) => InsightApi.get('/insight/accounts/csv', params, headers)
}

// useAccountInsightはアカウントのInsight情報を取得するためbuzz-cloudにも問い合わせを行う
// mIdやsnsAccountIdだけが必要な場合はこちらを利用されたし
export const useAccountQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<Omit<GetAccountsVariables, 'paging'>>): ReactQueryResult<AccountsQuery[0]> => {
  return useQueryWrapper<AccountsQuery[0]>({
    queryKey: 'account',
    deps: key,
    req: async ({ token }) => {
      const res = await InsightApi.getAccounts(payload, { token })
      if (res.isSuccess) {
        return { ...res, ...{ data: first(res.data) } }
      }
      return { ...res, ...{ data: first(res.data) } }
    },
    options
  })
}

export const useAccountsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetAccountsVariables>): ReactQueryResult<AccountsQuery> => {
  return useQueryWrapper<AccountsQuery>({
    queryKey: 'accounts',
    deps: key,
    req: ({ token }) => InsightApi.getAccounts(payload, { token }),
    options
  })
}

export const useAllAccountsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetAccountsVariables>): ReactQueryResult<AccountsQuery> => {
  return useQueryWrapper({
    queryKey: 'allAccounts',
    deps: key,
    req: async ({ token }) => {
      const status: { page: number; accounts: AccountsQuery; hasMore: boolean; res: AxiosResponse<AccountsQuery> } = {
        page: 1,
        accounts: [],
        hasMore: true,
        res: { data: [], isSuccess: false, requestQuery: { path: '', method: 'GET' } }
      }
      while (status.hasMore) {
        const paging = { page: status.page, size: PAGING_SIZE.xl }
        const accounts = await InsightApi.getAccounts({ ...payload, paging }, { token })
        status.res = accounts
        status.accounts = [...status.accounts, ...accounts.data]
        status.hasMore = accounts?.data?.length > 0
        status.page++
      }

      return {
        ...status.res,
        data: status.accounts.sort((a, b) => a?.snsAccountId - b?.snsAccountId)
      }
    },
    options
  })
}

export const useAbolishAccountMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<AbolishAccountVariables>({
    req: (payload, { token }) => InsightApi.abolish(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '廃止にできませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '廃止にしました' })
      }
    }
  })
}

export type InfiniteFacebookPagePostsQueryData = { data?: FacebookPagePostsQuery[]; pageParam?: string }
export type InfiniteFacebookPagePostsQueryPages = InfiniteData<InfiniteFacebookPagePostsQueryData>
export type InfiniteFacebookPagePostsUpdate = (e: FacebookPagePostsQuery) => void
export type InfiniteFacebookPagePostsQueryResult = UseInfiniteQueryResult<InfiniteFacebookPagePostsQueryData, any>
export const useFacebookPagePostsQuery = ({
  payload,
  key,
  options
}: TUseInfiniteQueryOptions<GetAccountFacebookPagePostsVariables>): InfiniteFacebookPagePostsQueryResult => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  const queryKey = useMemo(() => key || ['facebookPagePosts', payload.snsAccount, payload.cursor], [key, payload])
  // query infinite data
  return useInfiniteQuery(
    queryKey,
    res => InsightApi.getFacebookPagePosts({ ...payload, cursor: res.pageParam }, { token }),
    {
      ...options,
      getNextPageParam: (lastPage: AxiosResponse<FacebookPagePostsQuery>) => lastPage?.headers['x-next-cursor']
    }
  )
}

export const useAccountsCsvQuery = ({ payload, key, options }: TUseQueryOptions<{}>) => {
  return useQueryWrapper<string>({
    queryKey: 'accountsCsv',
    deps: key,
    req: ({ token }) => InsightApi.fetchCsv(payload, { token }),
    options
  })
}
