import dayjs from 'dayjs'
import { List, Record, Set } from 'immutable'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'

import { JSObject } from '../../../types/common'
import { TPost } from './PostInfo'
import { SnsAccount } from './SnsAccountInfo'
dayjs.locale('ja')

/*** order models ***/
export type TCalendarOrder = {
  from: string
  to: string
}

/*** store models ***/
export type TDisplayTodo = {
  displayTodo: boolean //'calender' | 'todo'
}
export class DisplayTodo extends Record<TDisplayTodo>({
  displayTodo: false //'calender'
}) {
  static fromResponse(param: JSObject) {
    const params = { ...param }
    return new DisplayTodo(params)
  }
}
export type TSnsRole = {
  id: string
  brandId: string
  snsAccountId: string
  userId: string
  role: 1 | 2 | 3 // 1: 権限なし 2: 編集者 3: 管理者
}
export class SnsRole extends Record<TSnsRole>({
  id: '',
  brandId: '',
  snsAccountId: '',
  userId: '',
  role: 1
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new SnsRole(params)
  }
}
export type TNextSetting = {
  id: string
  brandId: string
  snsAccountId: string
  userId: string
  stepNumber: number
  stepMaxNumber: number
}
export class NextSetting extends Record<TNextSetting>({
  id: '',
  brandId: '',
  snsAccountId: '',
  userId: '',
  stepNumber: 0,
  stepMaxNumber: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new NextSetting(params)
  }
}
export type TTodo = {
  nextStepNumber: number
  maxStepNumber: number
  canBeEdit: boolean
  canBeChangeToRequested: boolean
  canBeApprove: boolean
}
export class Todo extends Record<TTodo>({
  nextStepNumber: 0,
  maxStepNumber: 0,
  canBeEdit: false,
  canBeChangeToRequested: false,
  canBeApprove: false
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Todo(params)
  }
}
export type TCalendarData = {
  snsRole: TSnsRole
  nextSetting: TNextSetting
  todo: TTodo
} & TPost
export class CalendarData extends Record<TCalendarData>({
  post: undefined,
  snsRole: new SnsRole(),
  nextSetting: new NextSetting(),
  todo: new Todo(),
  postMediaRelations: [],
  snsAccount: new SnsAccount()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new CalendarData(params)
  }
  static iniList(params: JSObject[]): List<CalendarData> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => CalendarData.fromResponse(x)))
  }
}

export type TCalendarEvent = {
  event: {
    id: number
    title: string
    body: string
    brandId: number
    eventFrom: Date
    eventTo: Date
    allDayFlg: boolean
    color: string
  }
  user: {
    id: number
    email: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    status: number
  }
}

export type TCalendar = {
  date: string
  ymd: string
  weekName: string
  weekNum: number
  data: List<TCalendarData>
}
export class Calendar extends Record<TCalendar>({
  date: '',
  ymd: '',
  weekName: '',
  weekNum: 0,
  data: List()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.date = dayjs(params.date).format()
    params.data = List(CalendarData.iniList(params.data))
    return new Calendar(params)
  }
  static iniList(params: JSObject[]): List<TCalendar> {
    if (isEmpty(params)) {
      return List()
    }
    let result = List(params.map(x => Calendar.fromResponse(x)))
    result = result.sortBy(c => dayjs(c.date))
    return List(result)
  }
  static addList(params: JSObject[], exist: List<Calendar>): List<TCalendar> {
    if (isEmpty(params)) {
      return List()
    }
    const paramsList = List(params.map(x => Calendar.fromResponse(x)))
    let result = Set.union([exist, paramsList]).toList() as List<TCalendar> // 効いてないぽい。toSet() しても
    result = List(uniqBy(orderBy(result.toJS(), ['date', 'asc']), 'date'))
    return result
  }
}

export type TCalendarRoot = {
  data: List<TCalendar>
  events: TCalendarEvent[]
}
