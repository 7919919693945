import { IgDisplayMediaType, InstagramMediaType, TwDisplayMediaType, TwitterMediaType } from 'src/types'
import { MediaKind, MediaStatus } from 'src/redux/models/LibraryInfo'
import { MediaStatusFilter } from 'src/modules/entity/library'

export const CONTENT_TYPES = {
  text: 'text/plain',
  csv: 'text/csv',
  json: 'text/json',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  pdf: 'application/pdf',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  any: 'application/octet-stream'
}

type TMEDIAS_MEDIA = InstagramMediaType | TwitterMediaType | '' | 'none'

type TMEDIAS = { media: TMEDIAS_MEDIA; color: string; text: TwDisplayMediaType | IgDisplayMediaType }

export const MEDIAS: TMEDIAS[] = [
  /*** テキスト ***/
  { media: '', color: 'primary', text: 'テキスト' },
  { media: 'テキスト', color: 'primary', text: 'テキスト' },
  { media: 'text', color: 'primary', text: 'テキスト' },
  /*** 画像 ***/
  { media: '画像', color: 'success', text: '画像' },
  { media: 'IMAGE', color: 'success', text: '画像' },
  { media: 'image', color: 'success', text: '画像' },
  { media: 'photo', color: 'success', text: '画像' },
  /*** 動画 ***/
  { media: '動画', color: 'danger', text: '動画' },
  { media: 'VIDEO', color: 'danger', text: '動画' },
  { media: 'video', color: 'danger', text: '動画' },
  /*** GiF ***/
  { media: 'GIF', color: 'warning', text: 'GIF' },
  { media: 'animated_gif', color: 'warning', text: 'GIF' },
  /*** カルーセルアルバム ***/
  { media: 'CAROUSEL_ALBUM', color: 'purple', text: 'カルーセル' },
  { media: 'carousel', color: 'purple', text: 'カルーセル' },
  /*** ストーリーズ ***/
  { media: 'stories', color: 'purple', text: 'ストーリーズ' },
  /*** リール ***/
  { media: 'reels', color: 'info', text: 'リール' },
  /*** 不明 ***/

  { media: 'none', color: 'pink', text: '不明' },
  { media: 'unknown', color: 'dark', text: '不明' },
  { media: '不明', color: 'dark', text: '不明' },
  { media: '画像or動画', color: 'dark', text: '画像or動画' },
  /*** ウェブサイトカード ***/
  { media: 'ウェブサイトカード', color: 'orange', text: 'ウェブサイトカード' },
  { media: 'website_card', color: 'orange', text: 'ウェブサイトカード' }
]

type TLIBRARY_MEDIA_SORT_OPTIONS = { value: string; label: string }
export const LIBRARY_MEDIA_SORT_OPTIONS: TLIBRARY_MEDIA_SORT_OPTIONS[] = [
  { value: 'statusUpdateAt.desc', label: '更新日の新しい順' },
  { value: 'statusUpdateAt.asc', label: '更新日の古い順' },
  { value: 'storageAt.desc', label: '作成日の新しい順' },
  { value: 'storageAt.asc', label: '作成日の古い順' }
]
export const NUM_OF_SORT = {
  descByUpdateAt: 0,
  ascByUpdateAt: 1,
  descByCreateAt: 2,
  ascByCreateAt: 3
}

/*** ライブラリメディアセレクトオプション ***/
type TLIBRARY_MEDIA_OPTIONS = { value: MediaStatusFilter; label: string }
export const LIBRARY_MEDIA_OPTIONS: TLIBRARY_MEDIA_OPTIONS[] = [
  { value: 'upload', label: 'アップロード' },
  { value: 'archive', label: 'ゴミ箱' }
]

/*** ライブラリメディアステータス ***/
type TPOTS_MEDIA_STATUS = {
  active: MediaStatus
  archive: MediaStatus
  drop: MediaStatus
}
export const POTS_MEDIA_STATUS: TPOTS_MEDIA_STATUS = {
  active: 10, // アクティブ
  archive: 20, // アーカイブ(ゴミ箱)
  drop: 99 // 削除
}

/*** ライブラリメディア種別 ***/
type TLibraryMediaKind = {
  none: MediaKind
  upload: MediaKind
  ugc: MediaKind
}
export const LIBRARY_MEDIA_KIND: TLibraryMediaKind = {
  none: 0,
  upload: 10,
  ugc: 20
}

export const JA_STRINGS = {
  addMore: 'さらに追加',
  addMoreFiles: 'ファイルを追加',
  addingMoreFiles: 'ファイルを追加しています',
  allowAccessDescription: 'カメラやビデオの機能を使用するには、カメラへのアクセスを許可してください。',
  allowAccessTitle: 'カメラへのアクセスを許可してください',
  authenticateWith: '%{pluginName}に接続します',
  authenticateWithTitle: 'ファイルを選択するには%{pluginName}で認証してください',
  back: '戻る',
  browse: 'ファイルを参照',
  browseFiles: '参照',
  cancel: 'キャンセル',
  cancelUpload: 'アップロードをキャンセル',
  chooseFiles: 'ファイルを選択',
  closeModal: 'モーダルを閉じる',
  companionError: 'Companionとの接続に失敗しました',
  complete: '完了しました',
  connectedToInternet: 'インターネットに接続しました',
  copyLink: 'リンクをコピー',
  copyLinkToClipboardFallback: '以下のURLをコピー',
  copyLinkToClipboardSuccess: 'リンクをクリップボードにコピーしました',
  creatingAssembly: 'アップロードの準備をしています...',
  creatingAssemblyFailed: 'Transloadit: アセンブリを作成できませんでした',
  dashboardTitle: 'ファイルアップローダー',
  dashboardWindowTitle: 'ファイルアップローダーウィンドウ（閉じるにはEscapeキーを押してください）',
  dataUploadedOfTotal: '%{total} %{complete}',
  done: '完了しました',
  dropHereOr: 'ここにファイルをドロップするか%{browse}してください',
  dropHint: 'ここにファイルをドロップしてください',
  dropPasteBoth: 'ここにファイルをドロップするか、貼り付けるか、%{browse}してください',
  dropPasteFiles: 'ここにファイルをドロップ または%{browse}してください',
  dropPasteFolders: 'ここにファイルをドロップするか、貼り付けるか、%{browse}してください',
  dropPasteImportBoth: 'ここにファイルをドロップするか、貼り付けるか、%{browse}するか、以下からインポートしてください',
  dropPasteImportFiles: 'ここにファイルをドロップするか、貼り付けるか、%{browse}するか、以下からインポートしてください',
  dropPasteImportFolders:
    'ここにファイルをドロップするか、貼り付けるか、%{browse}するか、以下からインポートしてください',
  editFile: 'ファイルを編集',
  editing: '%{file}を編集しています',
  emptyFolderAdded: 'フォルダが空なためファイルが追加されませんでした',
  encoding: 'エンコードしています...',
  enterCorrectUrl: '不正なURL: ファイルへの直接リンクが入力されていることを確認してください',
  enterUrlToImport: 'ファイルをインポートするためのURLを入力してください',
  exceedsSize: 'ファイルサイズが大きすぎます',
  failedToFetch: 'CompanionがURLを取得できませんでした。URLが正しいか確認してください',
  failedToUpload: '%{file}のアップロードに失敗しました',
  fileSource: '元ファイル：%{name}',
  filesUploadedOfTotal: {
    '0': '%{smart_count} 個のファイルのアップロードが%{complete}',
    '1': '%{smart_count} 個のファイルのアップロードが%{complete}',
    '2': '%{cmart_count} 個のファイルのアップロードが%{complete}'
  },
  filter: 'フィルタ',
  finishEditingFile: 'ファイルの編集を終了',
  folderAdded: {
    '0': '%{folder} から% {smart_count} 個のファイルを追加しました',
    '1': '%{folder} から% {smart_count} 個のファイルを追加しました',
    '2': '%{folder} から% {smart_count} 個のファイルを追加しました'
  },
  import: 'インポート',
  importFrom: '%{name}からインポート',
  loading: 'ロード中...',
  logOut: 'ログアウト',
  myDevice: 'マイデバイス',
  noFilesFound: 'ファイルやフォルダがありません',
  noInternetConnection: 'インターネット接続がありません',
  pause: '一時停止',
  pauseUpload: 'アップロードを一時停止',
  paused: '停止中',
  poweredBy: 'Powered by',
  processingXFiles: {
    '0': '%{smart_count} ファイル処理中',
    '1': '%{smart_count} ファイル処理中',
    '2': '%{smart_count} ファイル処理中'
  },
  removeFile: 'ファイルを消す',
  resetFilter: 'フィルタをリセット',
  resume: '再開',
  resumeUpload: 'アップロードを再開',
  retry: 'リトライ',
  retryUpload: 'アップロードをリトライ',
  saveChanges: '変更を保存',
  selectX: {
    '0': '%{smart_count} を選択',
    '1': '%{smart_count} を選択',
    '2': '%{smart_count} を選択'
  },
  smile: 'スマイル〜！',
  startRecording: '録画開始',
  stopRecording: '録画停止',
  takePicture: '写真を撮る',
  timedOut: 'アップロードが %{seconds} 秒間止まった為中断しました',
  upload: 'アップロード',
  uploadComplete: 'アップロード完了',
  uploadFailed: 'アップロード失敗',
  uploadPaused: 'アップロード一時停止',
  uploadXFiles: {
    '0': '%{smart_count} ファイルをアップロード',
    '1': '%{smart_count} ファイルをアップロード',
    '2': '%{smart_count} ファイルをアップロード'
  },
  uploadXNewFiles: {
    '0': '+%{smart_count} ファイルをアップロード',
    '1': '+%{smart_count} ファイルをアップロード',
    '2': '+%{smart_count} ファイルをアップロード'
  },
  uploading: 'アップロード中',
  uploadingXFiles: {
    '0': '%{smart_count} ファイルアップロード中',
    '1': '%{smart_count} ファイルアップロード中',
    '2': '%{smart_count} ファイルアップロード中'
  },
  xFilesSelected: {
    '0': '%{smart_count} ファイルを選択',
    '1': '%{smart_count} ファイルを選択',
    '2': '%{smart_count} ファイルを選択'
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} ファイルを追加',
    '1': '%{smart_count} ファイルを追加',
    '2': '%{smart_count} ファイルを追加'
  },
  xTimeLeft: '残り %{time}',
  youCanOnlyUploadFileTypes: '許可されているファイル形式は次の物です: %{types}',
  youCanOnlyUploadX: {
    '0': '%{smart_count} ファイル数のみアップロード可能です',
    '1': '%{smart_count} ファイル数のみアップロード可能です',
    '2': '%{smart_count} ファイル数のみアップロード可能です'
  },
  youHaveToAtLeastSelectX: {
    '0': '最低でも %{smart_count} ファイル選択してください',
    '1': '最低でも %{smart_count} ファイル選択してください',
    '2': '最低でも %{smart_count} ファイル選択してください'
  },
  selectAllFilesFromFolderNamed: 'フォルダからすべてのファイルを選択 %{name}',
  unselectAllFilesFromFolderNamed: 'フォルダからすべてのファイルを選択解除 %{name}',
  selectFileNamed: 'ファイルを選ぶ %{name}',
  unselectFileNamed: 'ファイルの選択を解除 %{name}',
  openFolderNamed: '開いたフォルダ %{name}'
}
