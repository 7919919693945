import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { PLAN_ALLOWED_FEATURES } from 'src/constants/plan'
import { State } from 'src/redux/reducers'

type AllowedFeatures = {
  canva: boolean
  report: boolean
  benchmark: boolean
  ugc: boolean
  campaign: boolean
  mention: boolean
  comments: boolean
  instantwin: boolean
  autoreply: boolean
}

export const useAllowedFeatures = (): AllowedFeatures => {
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toObject()
  return Object.entries(PLAN_ALLOWED_FEATURES).reduce(
    (a, c) =>
      Object.assign(a, {
        [c[0].toLowerCase()]: user.toObject()?.scopes.indexOf(c[1]) !== -1
      }),
    {} as AllowedFeatures
  )
}

const InfiniteNumber = 99999999
export const usePlanRegisterLimit = (): { user: number | 'infinite'; snsAccount: number | 'infinite' } => {
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toObject()
  const scopes = user?.toObject().scopes
  const limit = scopes.reduce(
    (a: { user: number; snsAccount: number }, c: string) => {
      if (/\/user\/num\/\d*/.test(c)) {
        const userNum = +c.replace(/\/user\/num\//, '')
        if (userNum > a.user) return { ...a, user: userNum }
      }
      if (/\/sns_account\/num\/\d*/.test(c)) {
        const snsAccountNum = +c.replace(/\/sns_account\/num\//, '')
        if (snsAccountNum > a.snsAccount) return { ...a, snsAccount: snsAccountNum }
      }
      return a
    },
    { user: 0, snsAccount: 0 }
  )
  return {
    user: limit.user === InfiniteNumber ? 'infinite' : limit.user,
    snsAccount: limit.snsAccount === InfiniteNumber ? 'infinite' : limit.snsAccount
  }
}
