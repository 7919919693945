import dayjs from 'dayjs'
import { TSnses } from 'src/modules/entity/API'
import { ReplySettingKey, ReplySettingValue } from 'src/modules/entity/post'

export const POST = {
  approvalStatus: {
    waiting: 10, // 未処理 (未使用想定: 承認者指名機能を作る場合を想定)
    approved: 20, //承認 または 承認無しパターン
    rollback: 30 //差戻 または 承認申請取消パターン
  },
  postStatus: {
    none: 0, //未投稿（未使用想定）
    draft: 10, //下書き
    pending: 20, //承認待ち
    approved: 30, //投稿待ち(承認完了) < 投稿依頼対象
    requested: 40, //投稿済み(IG: manual, TW: auto)
    inprogress: 50, // 投稿処理中 (4/1現在: Instagram自動投稿失敗するとこのステータス)
    fail: 90, //投稿失敗
    archived: 99 //破棄
  }
} as const

const { draft, pending, approved, requested, inprogress, fail } = POST.postStatus
export const MNGED_POST_DEFAULT_VALUE = {
  paging: {
    page: 1,
    size: 10
  },
  statuses: [draft, pending, approved, requested, inprogress, fail],
  publishAtFrom: '',
  publishAtTo: '',
  isAutoReply: '',
  postCategoryTemplateId: ''
}

export const DEFAULT_POSTS_QUERY_STRING = {
  from: dayjs()
    .startOf('month')
    .startOf('day')
    .format('YYYY-MM-DD'),
  to: dayjs()
    .endOf('month')
    .endOf('day')
    .format('YYYY-MM-DD')
}

export const replySettingsOptions: {
  key: ReplySettingKey
  value: ReplySettingValue
  label: string
}[] = [
  { key: 'all', value: 10, label: '全員' },
  { key: 'following', value: 20, label: 'フォローしているアカウント' },
  { key: 'mentionedUsers', value: 30, label: '@ツイートしたアカウントのみ' }
]
export const feedOption = { value: 'feed', label: 'フィード' }
export const reelsOption = { value: 'reels', label: 'リール' }
// export const storiesOption = { value: 'stories', label: 'ストーリーズ' }
export const mediaProductTypeOptions = [
  feedOption,
  reelsOption
  // storiesOption
]

export const LIMIT_OF_TW_POST_MEDIA_LENGTH = 4
export const LIMIT_OF_IG_POST_MEDIA_LENGTH = 10

export const IG_USER_TAGS_MAX_LENGTH = 20

export const TW_POST_POLL_OPTIONS_MIN_LENGTH = 2
export const TW_POST_POLL_OPTIONS_MAX_LENGTH = 4

export const TW_POST_POLL_DURATION_DAYS = 7
export const TW_POST_POLL_DURATION_HOURS = 24
export const TW_POST_POLL_DURATION_MINUTES = 60
export const TW_POST_POLL_MIN_DURATION_MINUTES = 5

type LimitObj<T extends string> = {
  [key in T]: number
}
type ImageLimit = {
  max: LimitObj<'bytes' | 'heightRatio' | 'widthRatio' | 'width'>
  min: LimitObj<'bytes' | 'heightRatio' | 'widthRatio' | 'width'>
}
type VideoLimit = {
  max: LimitObj<'bytes' | 'duration' | 'heightRatio' | 'widthRatio'>
  min: LimitObj<'bytes' | 'duration' | 'heightRatio' | 'widthRatio'>
}

type TSNS_LIMITS = {
  caption: { max: number }
  hashtag: { max: number; minLength: number; maxLength: number }
  uniqueId: { minLength: number; maxLength: number }
  url: { maxLength: number }
  media: {
    max: number
    acceptTypes: string
    width: number
    image: ImageLimit
    video: VideoLimit
  }
}

const fakeInfinity = 999999999999999

interface ISNS {
  [key: string]: TSNS_LIMITS
}
export const BYTES = {
  oneMB: 1048576,
  fourMB: 4194304,
  fiveMB: 5242880,
  tenMB: 10485760,
  eightMiB: 8799649,
  oneGiB: 1073741824,
  fifteenMB: 15728640,
  hundredMB: 104857600,
  fourGiB: 4294967296,
  tenGB: 10737418240
}

export const PROVIDER = {
  instagram: 'instagram',
  twitter: 'twitter',
  facebook: 'facebook',
  tiktok: 'tiktok'
}

// Instagram自動投稿の仕様document
// https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
export const SNS: ISNS = {
  instagram: {
    caption: { max: 2199 },
    hashtag: { max: 30, minLength: 1, maxLength: 99 },
    uniqueId: { minLength: 1, maxLength: 30 },
    url: { maxLength: 2199 },
    media: {
      max: 10,
      acceptTypes: '.jpg,.jpeg,.png,.mp4,.mov',
      width: 375, // プレビューの幅
      image: {
        max: {
          bytes: BYTES.eightMiB,
          widthRatio: 4,
          heightRatio: 5,
          width: 1440
        },
        min: {
          bytes: 0,
          widthRatio: 1.91,
          heightRatio: 1,
          width: 320
        }
      },
      video: {
        max: {
          bytes: BYTES.hundredMB, // 100MB
          duration: 90, // seconds
          widthRatio: 9,
          heightRatio: 16
        },
        min: {
          bytes: 0,
          duration: 3,
          widthRatio: 16,
          heightRatio: 9
        }
      }
    }
  },
  instagramReel: {
    caption: { max: 2199 },
    hashtag: { max: 30, minLength: 1, maxLength: 99 },
    uniqueId: { minLength: 1, maxLength: 30 },
    url: { maxLength: 2199 },
    media: {
      max: 10,
      acceptTypes: '.jpg,.jpeg,.png,.mp4,.mov',
      width: 375, // プレビューの幅
      image: {
        max: {
          bytes: BYTES.eightMiB,
          widthRatio: 9,
          heightRatio: 16,
          width: 1920
        },
        min: {
          bytes: 0,
          widthRatio: 1.91,
          heightRatio: 1,
          width: 320
        }
      },
      video: {
        max: {
          bytes: BYTES.oneGiB, // 1GB
          duration: 900, // 15 min
          widthRatio: 9,
          heightRatio: 16
        },
        min: {
          bytes: 0,
          duration: 3,
          widthRatio: 16,
          heightRatio: 9
        }
      }
    }
  },
  twitter: {
    caption: { max: 280 },
    hashtag: { max: 50, minLength: 1, maxLength: 100 },
    uniqueId: { minLength: 1, maxLength: 20 },
    url: { maxLength: 15 }, // ドメイン後のスラッシュから数えて 15
    media: {
      max: 4,
      acceptTypes: '.jpg,.jpeg,.gif,.png,.mp4,.mov',
      width: 279, // 279px はiPhoneXにおけるメディアのwidth
      image: {
        max: {
          bytes: BYTES.fiveMB,
          widthRatio: 1,
          heightRatio: 3,
          width: fakeInfinity
        },
        min: {
          bytes: 0,
          widthRatio: 3,
          heightRatio: 1,
          width: 1
        }
      },
      video: {
        max: {
          bytes: BYTES.fifteenMB,
          duration: 140,
          widthRatio: 1,
          heightRatio: 1
        },
        min: {
          bytes: 0,
          duration: 1,
          widthRatio: 16,
          heightRatio: 9
        }
      }
    }
  },

  /**
   * NOTE 公式ドキュメントにない部分は実際の動作を確認した。またヘルプセンターの記述も参考にした。
   * 特に caption.max は動作確認の前にサイトが固まってしまったためブログの記述を参考にしている。
   * Facebook ヘルプセンター https://www.facebook.com/business/help/103816146375741
   * 調査Issue https://github.com/CyberBuzz/slider-web/issues/1363
   * video https://developers.facebook.com/docs/video-api/guides/publishing
   * image https://developers.facebook.com/docs/graph-api/reference/photo
   * image2 https://developers.facebook.com/docs/graph-api/reference/page/photos/ 投稿に添付する画像はこっち
   */
  facebook: {
    caption: { max: 63206 },
    hashtag: { max: 30, minLength: 1, maxLength: 81 }, // 英数字、日本語混合であれば maxLength:100だけど一旦 81文字で統一
    uniqueId: { minLength: 1, maxLength: 50 }, // minLength 実際には5文字 正規表現がうまくいかぬ
    url: { maxLength: 4000 }, // UTF-8換算して4000byte
    media: {
      max: 5, // api から投稿する場合5枚までだそう
      acceptTypes: '.bmp,.jpg,.jpeg,.png,.gif,.tiff,.mp4,.mov',
      width: 0, // 使わない
      image: {
        max: {
          bytes: BYTES.fourMB, // png のみ 1MBなのでこの数値とは別で validation
          widthRatio: fakeInfinity,
          heightRatio: 1,
          width: 2048 // Owgiとしての推奨サイズ, Facebook側からの正確な規定は無し
        },
        min: {
          bytes: 0,
          widthRatio: 1,
          heightRatio: fakeInfinity,
          width: 320 // Owgiとしての推奨サイズ, Facebook側からの正確な規定は無し
        }
      },
      video: {
        max: {
          bytes: BYTES.tenGB,
          duration: 14400, // seconds, 4時間
          widthRatio: 1,
          heightRatio: 4
        },
        min: {
          bytes: 0,
          duration: 1,
          widthRatio: fakeInfinity,
          heightRatio: 1
        }
      }
    }
  }
}

export const PREVIEW = {
  WIDTH: 375,
  HEIGHT: 760
}

type TSNS_GLOSSARY = {
  [key in TSnses]: { postJa?: string} 
}

export const SNS_GLOSSARY: TSNS_GLOSSARY = {
  instagram: {
    postJa: 'フィード'
  },
  twitter: {
    postJa: 'ポスト'
  },
  facebook: {
    postJa: '投稿'
  },
  tiktok: {
    postJa: '動画'
  },
  line: {},
  '': {}
}
