import { useQueryWrapper } from 'src/modules/core/query'
import { TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import { GetStoriesCsvVariables } from 'src/modules/entity/stories'
import { CommonApi } from 'src/redux/api/commonApi'

class StoriesApi extends CommonApi {
  static fetchCsv = (params: GetStoriesCsvVariables, headers: TApiHeadersCommon) =>
    StoriesApi.get('/insight/stories/csv', params, headers)
}

export const useStoriesCsvQuery = ({ payload, key, options }: TUseQueryOptions<GetStoriesCsvVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'storiesCsv',
    deps: key,
    req: ({ token }) => StoriesApi.fetchCsv(payload, { token }),
    options
  })
}
