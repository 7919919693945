import { PostsQuery } from 'src/modules/entity/post'
import { UnArray } from 'src/types'
import { CheckType, ApprovalTodoHistory, ApprovalTodoCheckQuery } from 'src/modules/entity/approval'
import { CHECKBOX, INPUT, UNNECESSARY, CHECKED, UNCHECKED } from 'src/constants/approval'

export const getCurrentStepNumber = (post: UnArray<PostsQuery>) => {
  const maxStep = 3 + post?.todo?.maxStepNumber
  if (post?.post.status === 10) {
    return 1
  }
  if (post?.post.status === 20) {
    return 1 + post?.todo.nextStepNumber
  }
  if (post?.post.status === 30) {
    return maxStep - 1
  }
  if (post?.post.status === 40) {
    return maxStep
  }
  return 0
}

const getTodoCheckUpdate = (
  checkType: CheckType,
  isChecked: string,
  checkInput: string,
  prevCheck: ApprovalTodoHistory | null
) => {
  if (checkType === CHECKBOX) {
    const isCheckedPrevValue = prevCheck?.unnecessary ? UNNECESSARY : prevCheck?.isChecked ? CHECKED : UNCHECKED
    return isCheckedPrevValue !== isChecked
  }
  return prevCheck?.checkInput !== checkInput && (prevCheck?.checkInput || checkInput)
}

export const formatTodoHistories = (
  approvalTodos: ApprovalTodoCheckQuery,
  values: { id: string[]; isChecked?: string[]; checkInput?: string[] },
  stepNumber: number
) => {
  return approvalTodos
    .filter(approvalTodo => {
      const todoId = approvalTodo.todo.id
      // const unnecessary = values.isChecked && values.isChecked[todoId] === UNNECESSARY
      // const isChecked = values.isChecked && values.isChecked[todoId] === CHECKED
      const checkInput = values.checkInput && values.checkInput[todoId]
      return getTodoCheckUpdate(approvalTodo.todo.checkType, values.isChecked[todoId], checkInput, approvalTodo.history)
    })
    .map(approvalTodo => {
      const todoId = approvalTodo.todo.id
      const unnecessary = values.isChecked && values.isChecked[todoId] === UNNECESSARY
      const isChecked = values.isChecked && values.isChecked[todoId] === CHECKED
      const checkInput = values.checkInput && values.checkInput[todoId]
      return {
        todoId,
        isChecked,
        checkInput: checkInput || '',
        unnecessary,
        stepNumber
      }
    })
}

export const getCheckedTodoCount = (approvalTodos?: ApprovalTodoCheckQuery) => {
  if (!approvalTodos) return 0
  return approvalTodos.filter(t => {
    if (t.todo.checkType === CHECKBOX) return t.history?.isChecked || t.history?.unnecessary
    if (t.todo.checkType === INPUT) return t.history?.checkInput?.length > 0 || t.history?.unnecessary
    return false
  }).length
}
