/* eslint-disable array-callback-return */
import { List } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import { InsightActions } from '../actions/Insight'
import { InsightApi } from '../api/insightApi'
import { history } from '../configureStore'
import { Abolish, Account } from '../models/InsightInfo'
import { State } from '../reducers'

/** generator **/
function* getIniAccounts(action: ReturnType<typeof InsightActions.getIniAccounts>) {
  // if exists state.Auth.user.redirect param, forward to redirect page
  const redirect: string = yield select((state: State) => state.Auth.get('redirect'))
  if (redirect) {
    yield history.push(redirect)
  } // history.push 移行も処理継続される

  yield put(InsightActions.setLoading(true))
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  action.payload = { ...action.payload, ...{ paging: { page: 1, size: 50 } } }
  try {
    const response = yield InsightApi.accounts(action.payload, { token })
    if (response.isSuccess) {
      yield put(InsightActions.setAccounts(Account.iniList(response.data)))
      const insight: any = head(response.data)
      if (!isEmpty(insight)) {
        yield put(InsightActions.setMoreAccounts(true)) // for infinity load.
        return
      } else {
        yield put(InsightActions.setMoreAccounts(false)) // for infinity load.
      }
      yield put(InsightActions.setAccountsNextPage(2)) // for infinity load.

      // FIXME: Don't needed here, but Using the changeBrand in Insight Page.
      // const mId: string | undefined = _.get(insight, 'mId', undefined) // set first item of accounts to default mId
      // yield put(InsightActions.getIniAccount(_.assign(action.payload, { mId: mId })))
    } else {
      yield put(InsightActions.setError({ status: response.status, message: response.error.message }))
    }
  } catch (e) {
    yield put(InsightActions.setError({ ...e }))
  } finally {
    yield put(InsightActions.setLoading(false))
  }
}
function* getAddAccounts(action: ReturnType<typeof InsightActions.getAddAccounts>) {
  yield put(InsightActions.setLoading(true))
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const nextPage = yield select((state: State) => state.Insight.get('accountsNextPage'))
  action.payload = { ...action.payload, ...{ paging: { page: nextPage, size: 50 } } }
  try {
    const response = yield InsightApi.accounts(action.payload, { token })
    if (response.isSuccess) {
      const exists = yield select((state: State) => state.Insight.get('accounts'))
      const res = Account.addList(response.data, exists)
      yield put(InsightActions.setAccounts(res.list))
      yield put(InsightActions.setMoreAccounts(res.hasMore)) // for infinity load.
      yield put(InsightActions.setAccountsNextPage(nextPage + 1 || 1)) // for infinity load.
    } else {
      yield put(InsightActions.setError({ status: response.status, message: response.error.message }))
    }
  } catch (e) {
    yield put(InsightActions.setError({ ...e }))
  } finally {
    yield put(InsightActions.setLoading(false))
  }
}
function* getAllAccounts(action: ReturnType<typeof InsightActions.getAddAccounts>) {
  try {
    yield put(InsightActions.setLoading(true))
    const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
    let moreAccounts: boolean = yield select((state: State) => state.Insight.get('moreAccounts'))
    // 何故かダブるので最初から全部取得する
    let nextPage: number = 1
    let res: { list: List<Account>; hasMore: boolean } = { list: List(), hasMore: false }
    if (!moreAccounts) return
    while (moreAccounts) {
      action.payload = { ...action.payload, ...{ paging: { page: nextPage, size: 50 } } }
      const response = yield InsightApi.accounts(action.payload, { token })
      if (response.isSuccess) {
        res = Account.addList(response.data, res.list)
        if (!res.hasMore) moreAccounts = false
        action.payload = { ...action.payload, ...{ paging: { page: nextPage, size: 50 } } }
      } else {
        yield put(
          InsightActions.setError({ status: response.status, message: 'アカウント一覧全取得でエラーが発生しました。' })
        )
        break
      }
      nextPage++
    }
    yield put(InsightActions.setAccounts(res.list))
    yield put(InsightActions.setMoreAccounts(res.hasMore))
    yield put(InsightActions.setAccountsNextPage(nextPage))
  } catch (e) {
    yield put(InsightActions.setError({ ...e }))
  } finally {
    yield put(InsightActions.setLoading(false))
  }
}
function* getAbolish(action: ReturnType<typeof InsightActions.getAbolish>) {
  yield put(InsightActions.setLoading(true))
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  try {
    const response = yield InsightApi.abolish(action.payload, { token })
    if (response.isSuccess) {
      yield put(InsightActions.setAbolish(Abolish.fromResponse(response.data)))
      yield put(InsightActions.setSucceeded(true))
      yield put(InsightActions.getIniAccounts({ paging: { page: 1, size: 50 } }))
    } else {
      yield put(InsightActions.setError({ status: response.status, message: response.error.message }))
    }
  } catch (e) {
    yield put(InsightActions.setError({ ...e }))
  } finally {
    yield put(InsightActions.setLoading(false))
  }
}

/** watcher **/
export function* watchGetIniAccounts() {
  yield takeLatest(InsightActions.getIniAccounts, getIniAccounts)
}
export function* watchGetAddAccounts() {
  yield takeLatest(InsightActions.getAddAccounts, getAddAccounts)
}
export function* watchGetAllAccounts() {
  yield takeLatest(InsightActions.getAllAccounts, getAllAccounts)
}
export function* watchGetAbolish() {
  yield takeLatest(InsightActions.getAbolish, getAbolish)
}

/** saga **/
function* insightSaga(): any {
  yield all([fork(watchGetIniAccounts), fork(watchGetAddAccounts), fork(watchGetAllAccounts), fork(watchGetAbolish)])
}

export default insightSaga
