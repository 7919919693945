import isEmpty from 'lodash/isEmpty'

export const arrayInObj2str = (obj: any, delimiter: string) => {
  const formatedData: { [key: string]: string | number } = {}
  Object.keys(obj).forEach(v => {
    if (Array.isArray(obj[v])) {
      formatedData[v] = !isEmpty(obj[v]) ? obj[v].join(delimiter) : ''
    } else {
      formatedData[v] = obj[v]
    }
  })
  return formatedData
}

// NOTE: ネストされたオブジェクトのキーチェックには対応していません
export const hasPropertiesCheck = <K extends string>(obj: unknown, ...keys: K[]): obj is { [M in K]: unknown } =>
  obj instanceof Object && keys.every(key => key in obj)

export const isPropertiesNumber = <T extends object>(obj: T): obj is { [M in keyof T]: number } & T =>
  ![null, undefined].some(falsy => falsy === obj) && Object.values(obj).every(value => typeof value === 'number')

export const isEmptyObject = (obj: Record<string, unknown> | unknown[]): boolean => {
  if (!obj) return true
  return Object.entries(obj).length === 0
}
// ほとんど多くの機会で !isEmptyObject と否定形で用いているため、根元で逆転させておく。
export const existObject = (obj: unknown[] | Record<string, unknown>) => !isEmptyObject(obj)

// TODO 型
export const stringToNumber = <T>(data: T) => {
  if (!data) return
  const obj: Record<string, number> = {}
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      obj[key] = +value
    } else if (typeof value === 'number') {
      obj[key] = value
    }
  })
  return obj
}
