import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { State } from 'src/redux/reducers'
import { userBrandRoleType } from 'src/constants/user'

type RoleAllowed = {
  pageCanView: { accountComplement: boolean }
  canAdd: { owner: boolean; manager: boolean; member: boolean; snsAccount: boolean }
  canRemove: { snsAccount: boolean }
  changeToRole: { owner: boolean; manager: boolean; member: boolean }
  changeUserInfo: { other: boolean; self: boolean }
  changePostStatus: { edit: boolean; approvalRequest: boolean; approval: boolean; remand: boolean; delate: boolean }
  changeAutoPublish: { canChange: boolean }
  downloadReport: { canDownload: boolean }
  changePostCategory: { canEdit: boolean }
  canAccountPageAction: {
    // accountRegist: boolean
    // accountRemove: boolean
    // downloadReport: boolean
    managedPostCreate: boolean
    twitterAnalyticsImport: boolean
    instagramCommentReply: boolean
    rivalRegist: boolean
    rivalsCsvDownload: boolean
    // settingAutopublish: boolean
    settingManagedPostFlow: boolean
  }
  canCampaignPageAction: {
    create: boolean
    edit: boolean
    changeStatus: boolean
    applicantChangeStatus: boolean
    applicantCsvDownload: boolean
    sendWinMessage: boolean
  }
  canUgcPageAction: {
    changeStatus: boolean
  }
  canCalendarPageAction: {
    createEvent: boolean
    editEvent: boolean
    deleteEvent: boolean
  }
}

export const useRoleAllowed = (): RoleAllowed => {
  const { user } = useShallowEqualSelector((state: State) => state.Auth).toObject()
  const roleType = user?.toObject().roleType
  return {
    pageCanView: selectPageCanView(roleType),
    canAdd: selectCanAdd(roleType),
    canRemove: selectCanRemove(roleType),
    changeToRole: selectChangeToRole(roleType),
    changeUserInfo: selectChangeUserInfo(roleType),
    changePostStatus: selectChangePostStatus(roleType),
    changeAutoPublish: selectChangeAutoPublish(roleType),
    downloadReport: selectDownloadReport(roleType),
    changePostCategory: selectChangePostCategory(roleType),
    canAccountPageAction: selectCanAccountPageAction(roleType),
    canCampaignPageAction: selectCanCampaignPageAction(roleType),
    canUgcPageAction: selectUgcPageAction(roleType),
    canCalendarPageAction: selectCalendarPageAction(roleType)
  }
}
const selectPageCanView = (roleType: number): RoleAllowed['pageCanView'] => {
  if (roleType === userBrandRoleType.system) return { accountComplement: true }
  return { accountComplement: false }
}

const selectCanAdd = (roleType: number): RoleAllowed['canAdd'] => {
  const { system, manager } = userBrandRoleType
  if (roleType === system) return { owner: true, manager: true, member: true, snsAccount: true }
  if (roleType <= manager) return { owner: false, manager: true, member: true, snsAccount: true }
  return { owner: false, manager: false, member: false, snsAccount: false }
}

const selectCanRemove = (roleType: number): RoleAllowed['canRemove'] => {
  return { snsAccount: roleType <= userBrandRoleType.manager }
}

const selectChangeToRole = (roleType: number): RoleAllowed['changeToRole'] => {
  const { system, manager } = userBrandRoleType
  if (roleType === system) return { owner: true, manager: true, member: true }
  if (roleType <= manager) return { owner: false, manager: true, member: true }
  return { owner: false, manager: false, member: false }
}

const selectChangeUserInfo = (roleType: number): RoleAllowed['changeUserInfo'] => {
  const { manager, member } = userBrandRoleType
  if (roleType <= manager) return { other: true, self: true }
  if (roleType === member) return { other: false, self: true }
  return { other: false, self: false }
}

const selectChangePostStatus = (roleType: number): RoleAllowed['changePostStatus'] => {
  const { manager, member } = userBrandRoleType
  if (roleType <= manager) return { edit: true, approvalRequest: true, approval: true, remand: true, delate: true }
  if (roleType === member) return { edit: false, approvalRequest: false, approval: true, remand: true, delate: false }
  return { edit: false, approvalRequest: false, approval: false, remand: false, delate: false }
}

const selectChangeAutoPublish = (roleType: number): RoleAllowed['changeAutoPublish'] => {
  return { canChange: roleType <= userBrandRoleType.manager }
}

// 2021/1/31 https://github.com/CyberBuzz/slider-web/issues/996
const selectDownloadReport = (roleType: number): RoleAllowed['downloadReport'] => {
  return { canDownload: roleType <= userBrandRoleType.manager }
}

const selectChangePostCategory = (roleType: number): RoleAllowed['changePostCategory'] => {
  return { canEdit: roleType <= userBrandRoleType.manager }
}

const selectCanAccountPageAction = (roleType: number): RoleAllowed['canAccountPageAction'] => {
  const isOverMangerRole = roleType <= userBrandRoleType.manager
  // TODO コメントアウトしているところはこっちに寄せたい
  return {
    // accountRegist: isOverMangerRole,
    // accountRemove: isOverMangerRole,
    // downloadReport: isOverMangerRole,
    managedPostCreate: isOverMangerRole,
    twitterAnalyticsImport: isOverMangerRole,
    instagramCommentReply: isOverMangerRole,
    rivalRegist: isOverMangerRole,
    rivalsCsvDownload: isOverMangerRole,
    // settingAutopublish: isOverMangerRole,
    settingManagedPostFlow: isOverMangerRole
  }
}

const selectCanCampaignPageAction = (roleType: number): RoleAllowed['canCampaignPageAction'] => {
  const isOverMangerRole = roleType <= userBrandRoleType.manager
  return {
    create: isOverMangerRole,
    edit: isOverMangerRole,
    changeStatus: isOverMangerRole,
    applicantChangeStatus: isOverMangerRole,
    applicantCsvDownload: isOverMangerRole,
    sendWinMessage: isOverMangerRole
  }
}

const selectUgcPageAction = (roleType: number): RoleAllowed['canUgcPageAction'] => {
  const isOverMangerRole = roleType <= userBrandRoleType.manager
  return {
    changeStatus: isOverMangerRole
  }
}

const selectCalendarPageAction = (roleType: number): RoleAllowed['canCalendarPageAction'] => {
  const isOverMangerRole = roleType <= userBrandRoleType.manager
  return { createEvent: isOverMangerRole, editEvent: isOverMangerRole, deleteEvent: isOverMangerRole }
}
