import { userBrandRoleType } from 'src/constants/user'
import { BRAND_STATUS_TYPE } from 'src/constants/brand'
import { AxiosError } from 'axios'
import get from 'lodash/get'
import { all, call, delay, fork, put, putResolve, select, takeLatest } from 'redux-saga/effects'
import { ROUTES } from 'src/constants/routes'
import { delHsToken, getSession, setSession } from 'src/helpers/authUtils'
import { AuthActions } from 'src/redux/actions/Auth'
import { InsightActions } from 'src/redux/actions/Insight'
import { SignApi } from 'src/redux/api/signApi'
import { history } from 'src/redux/configureStore'
import { setLoggerUser } from 'src/redux/middleware/dataDogLogger'
import {
  ForgetPassword,
  Invitation,
  ResetPassword,
  Signed,
  TForgetPassword,
  TResetPassword,
  TSigned
} from 'src/redux/models/AuthInfo'
import { State } from 'src/redux/reducers'
import { AxiosResponse } from 'types/axios'

export const stateUser = (state: State) => state.Auth.toJS().user
export const stateDateRange = (state: State) => state.Daterange.toJS().daterange

const setLoggerGlobalContext = (data: Signed) => {
  const user = data.user.toObject()
  const currentBrand = data.currentBrand.toObject()
  setLoggerUser({
    id: user.id.toString(),
    name: `${user.lastName}${user.firstName}`,
    email: user.email,
    brandId: currentBrand.id,
    brandName: currentBrand.displayName
  })
}

function* getSignUp(action: ReturnType<typeof AuthActions.getSignUp>) {
  try {
    const response: AxiosResponse<TSigned> = yield SignApi.signUp(action.payload)
    if (response.isSuccess) {
      const data = Signed.fromResponse(response.data)
      yield putResolve(AuthActions.setSigned(data))
      yield setSession('user', { token: data.token })
      yield put(InsightActions.getIniAccounts({ paging: { page: 1 } }))
      // yield history.push(ROUTES.brandTop())
      window.location.href = '/'
    } else {
      yield put(AuthActions.setError({ status: response.status, message: response.error.message }))
    }
  } catch (e) {
    yield put(AuthActions.setError({ ...e, message: 'ユーザ数が上限に達しています。営業担当者にご確認ください。' }))
  } finally {
    yield put(AuthActions.setLoading(false))
  }
}

function* getSigned(action: ReturnType<typeof AuthActions.getSigned>) {
  const { brandId, redirectTo, ...req } = action.payload
  try {
    const response: AxiosResponse<TSigned> = yield SignApi.signIn(req)
    if (response.isSuccess) {
      const data = Signed.fromResponse(response.data)
      setLoggerGlobalContext(data)
      yield put(AuthActions.setSigned(data))
      yield setSession('user', { token: data.token })
      const loading: boolean = yield select((state: State) => state.Auth.get('loading'))
      if (!loading) {
        /** FIXME InsightActions.getIniAccounts は本来要らないものだけど、以下のケース例でアカウントページで不具合があるため残します。
         * 1. ブランド1のページからログアウト
         * 2. ブランド1 のページへログインページからリダイレクトする
         * 不具合: アカウントドロップダウンのデータが読み込まれない(useAllAccounts の移行と同時に deps に location なり token を入れればfixできると思われます)
         * TODO 修正したらこのコメントは削除
         **/
        yield put(InsightActions.getIniAccounts({ paging: { page: 1 } }))
        if (redirectTo) {
          yield put(AuthActions.getChangeBrand({ userBrandRole: { brandId: +brandId } }))
          const paths = redirectTo.split('?')
          history.push({ pathname: paths[0], search: paths[1] || '' })
        } else {
          const activeBrand =
            data.toObject().currentBrand ||
            data.toObject().selectableBrands.find(brand => brand.toObject().status === BRAND_STATUS_TYPE.active)
          if (
            data.toObject().currentBrand.toObject().status === BRAND_STATUS_TYPE.suspensionOfUse &&
            data.toObject().roleType !== userBrandRoleType.system
          ) {
            yield put(AuthActions.getChangeBrand({ userBrandRole: { brandId: +activeBrand.id } }))
          }

          history.push(ROUTES.brandTop(`${activeBrand.id}`))
        }
      }
    } else {
      if (response.error.response.status === 400)
        yield put(
          AuthActions.setError({
            ...response.error.response,
            message:
              'ログインできませんでした。ログインに連続で失敗すると、アカウントが一時的にロックされますのでご注意ください。'
          })
        )
      if (response.error.response.status === 401)
        yield put(
          AuthActions.setError({
            ...response.error.response,
            message:
              'ログインできませんでした。ログインに連続で失敗すると、アカウントが一時的にロックされますのでご注意ください。'
          })
        )
      if (response.error.response.status === 429)
        yield put(
          AuthActions.setError({
            ...response.error.response,
            message: '連続してログインに失敗したため、一時的にログインが制限されています。'
          })
        )
    }
  } catch (e) {
    yield put(
      AuthActions.setError({
        ...e,
        message:
          'ログインできませんでした。ログインに連続で失敗すると、アカウントが一時的にロックされますのでご注意ください。'
      })
    )
  }
}

function* getSignOut(action: ReturnType<typeof AuthActions.getSignOut>) {
  yield setSession('user', null)
  yield setSession('oauth', null)
  yield delHsToken()
  yield putResolve({ type: 'SIGN/INITIALIZE_AUTH' })
  yield putResolve({ type: 'DATERANGE/INITIALIZE_DATE_RANGE' })
  yield putResolve({ type: 'INJECTION/INITIALIZE_INJECTION' })
  yield putResolve({ type: 'INSIGHT/INITIALIZE_INSIGHT' })
  yield putResolve({ type: 'OAUTH/INITIALIZE_OAUTH' })
  yield putResolve({ type: 'POSTS/INITIALIZE_POSTS' })
  yield putResolve({ type: 'POST/INITIALIZE_POST' })
  yield putResolve({ type: 'POST_COMMENT/INITIALIZE_POST_COMMENT' })
  yield putResolve({ type: 'CALENDER/INITIALIZE_CALENDER' })
  yield putResolve({ type: 'LIBRARY/INITIALIZE_LIBRARY' })
  yield putResolve({ type: 'CAMPAIGN/INITIALIZE_CAMPAIGN' })
  yield putResolve({ type: 'UGC/INITIALIZE_UGC' })
  yield putResolve({ type: 'APPLICANTS/INITIALIZE' })
  if (action.payload?.pushToTop) {
    yield call(history.push, { pathname: ROUTES.signIn() })
  }
}

function* getChangeBrand(action: ReturnType<typeof AuthActions.getChangeBrand>) {
  let token = get(getSession('user'), 'token')
  try {
    const response: AxiosResponse<TSigned> = yield SignApi.changeBrand(action.payload, { token })
    const hsVisitorToken: string = yield select((state: State) => state.Auth.getIn(['user', 'hsVisitorToken']))
    if (response.isSuccess) {
      const data = Signed.fromResponse({ ...response.data, ...{ hsVisitorToken } })
      setLoggerGlobalContext(data)
      yield put(AuthActions.setSigned(data))
    } else {
      yield put(AuthActions.setError({ status: response.status, message: response.error.message }))
    }
  } catch (e) {
    yield put(AuthActions.setError({ ...e }))
    yield put(InsightActions.setError({ ...e }))
  } finally {
    /**
     * MEMO
     * Auth.loading で react-query の enabled を制御するため 500 ms の delay を入れました。
     * あくまでも暫定処置。不細工なので Auth周りも react-query 化したいところ！
     */
    yield delay(500)
    yield put(InsightActions.setLoading(false))
    yield put(AuthActions.setLoading(false))
  }
}

function* getInvite(action: ReturnType<typeof AuthActions.getInvite>) {
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const response: AxiosResponse<any> = yield SignApi.invite(action.payload, { token: token })
  if (response.isSuccess) {
    yield put(AuthActions.setInvite(Invitation.fromResponse(response.data)))
    yield put(AuthActions.setSucceeded(true))
  } else {
    const e: AxiosError = response.error
    if (e.response.status === 401) {
      yield put(AuthActions.getSignOut({ pushToTop: true }))
    } else {
      yield put(AuthActions.setError({ status: response.status, message: response.error.message }))
    }
  }
  return
}

function* sentForgetPasswordMail(action: ReturnType<typeof AuthActions.getSentForgetPasswordMail>) {
  const param = { user: { email: action.payload } }
  const response: AxiosResponse<TForgetPassword> = yield SignApi.sentForgetPasswordMail(param)
  if (response.isSuccess) {
    yield put(AuthActions.setForgetPasswordMail(ForgetPassword.fromResponse(response.data)))
    yield put(AuthActions.setSucceeded(true))
  } else {
    yield put(AuthActions.setError({ status: response.status, message: response.error.message }))
  }
}

function* sentResetPassword(action: ReturnType<typeof AuthActions.getSentResetPasswordOrder>) {
  const response: AxiosResponse<TResetPassword> = yield SignApi.sentResetPasswordOrder(action.payload)
  if (response.isSuccess) {
    yield put(AuthActions.setResetPassword(ResetPassword.fromResponse(response.data)))
    yield put(AuthActions.setSucceeded(true))
  } else {
    yield put(AuthActions.setError({ status: response.status, message: response.error.message }))
  }
}

/*** watcher ***/
export function* watchGetSignUp() {
  yield takeLatest(AuthActions.getSignUp, getSignUp)
}
export function* watchGetSigned() {
  yield takeLatest(AuthActions.getSigned, getSigned)
}
export function* watchGetSignOut() {
  yield takeLatest(AuthActions.getSignOut, getSignOut)
}
export function* watchGetChangeBrand() {
  yield takeLatest(AuthActions.getChangeBrand, getChangeBrand)
}
export function* watchGetInvite() {
  yield takeLatest(AuthActions.getInvite, getInvite)
}
export function* watchSentForgetPasswordMail() {
  yield takeLatest(AuthActions.getSentForgetPasswordMail, sentForgetPasswordMail)
}
export function* watchSentResetPassword() {
  yield takeLatest(AuthActions.getSentResetPasswordOrder, sentResetPassword)
}

/*** saga ***/
function* authSaga(): any {
  yield all([
    fork(watchGetSignUp),
    fork(watchGetSigned),
    fork(watchGetSignOut),
    fork(watchGetChangeBrand),
    fork(watchGetInvite),
    fork(watchSentForgetPasswordMail),
    fork(watchSentResetPassword)
  ])
}

export default authSaga
