import { Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { SideMenusActions } from 'src/redux/actions/SideMenus'

type TSideMenusState = {
  isCondensed: boolean
}

export class SideMenusState extends Record<TSideMenusState>({ isCondensed: false }) {}

const SideMenusReducer = reducerWithInitialState(new SideMenusState()).case(SideMenusActions.switch, (state, payload) =>
  state.withMutations(s => s.set('isCondensed', payload))
)

export default SideMenusReducer
