import dayjs from 'dayjs'

const weekday = ['日', '月', '火', '水', '木', '金', '土'].map(x => `${x}曜日`)
export const formatDate = (s: number | string) => {
  const date = new Date(s)
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}(${weekday[date.getDay()]})`
}

export const formatDateForFileName = (s: number | string) => {
  const date = new Date(s)
  return `${date.getFullYear()}_${padMonth(date.getMonth() + 1)}_${date.getDate()}`
}

export const padMonth = (month: number) => month.toString().padStart(2, '0')

const MINUTES = 60
const HOURS = 24
const DAY_TO_MINUTES = MINUTES * HOURS

export const minutesToDate = (min: number) => {
  const dd = Math.floor(min / DAY_TO_MINUTES)
  const hh = Math.floor((min % DAY_TO_MINUTES) / MINUTES)
  const mm = Math.floor(min % MINUTES)

  return { days: `${dd}`, hours: `${hh}`, minutes: `${mm}` }
}

type DateToMin = { days: number; hours: number; minutes: number }

export const dateToMinutes = ({ days, hours, minutes }: DateToMin): number => {
  const d = +days * DAY_TO_MINUTES
  const h = +hours * MINUTES
  const m = +minutes
  return d + h + m
}

export const formatDateHyphen = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD')
}
