import { datadogLogs, StatusType } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { apiOptions, ENV } from 'src/constants/api'

datadogLogs.init({
  clientToken: process.env.NODE_ENV !== 'development' && 'pub6830314e4fa6e5fa37a0854d8df8426d', // localは記録しない
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: `owgi-${ENV}`,
  env: ENV
})

datadogRum.init({
  applicationId: '58fdf341-b4f0-4695-821e-483ea725eeea',
  clientToken: process.env.NODE_ENV !== 'development' && 'pub89b6f9dd3da35ea1023b8b52053663e5',
  site: 'datadoghq.com',
  service: `owgi-${ENV}`,
  env: ENV,
  sampleRate: 100,
  trackInteractions: true
})
type Log = {
  _custom: {
    type: 'network' | 'event' | 'front'
    user?: {
      userId: number // FACT @_cutom.user.userId
      name: string
      brandId: number // FACT @_custom.user.brandId
      brandName: string
      email: string
    }
    url?: string // FACT @_custom.url
    httpMethod?: string // FACT @_custom.httpMethod
    httpStatus?: number // FACT @_custom.httpStatus
    requestParams?: any
    responseBody?: any
    eventName?: string // FACT @_custom.eventName
    eventArgs?: any
    componentStack?: string
  }
}

type NetworkLogParams = {
  httpStatus: number
  path: string
  method: 'GET' | 'POST' | 'DELETE'
  requestParams: any
  responseBody: any
  status: StatusType
}
type FrontEndLogParams = {
  path: string
  componentStack: string
  status: StatusType
}

type EventLogParams = {
  eventName: string
  eventArgs: any
  status: StatusType
}

type EventName = 'ExportPostPreview' | 'GoBackPage'

/**
 * DatadogのRUMの設定及びグローバルコンテキストの設定
 * 送信するログに_cutom.userのフィールドを入れ込む
 *
 * @param user
 *
 */
export const setLoggerUser = (user: {
  id: string
  name: string
  email: string
  brandId: number
  brandName: string
}) => {
  datadogRum.setUser(user)
  const { id, ...rest } = user
  datadogLogs.setLoggerGlobalContext({ _custom: { user: { ...rest, userId: id } } })
}

export const loggedNetworkSuccess = (params: Omit<NetworkLogParams, 'status'>) =>
  loggedNetwork4DataDog({ ...params, status: StatusType.info })
export const loggedNetworkError = (params: Omit<NetworkLogParams, 'status'>) =>
  loggedNetwork4DataDog({ ...params, status: StatusType.error })
export const loggedFrontEndError = (params: Omit<FrontEndLogParams, 'status'>) =>
  loggedFrontEnd4DataDog({ ...params, status: StatusType.error })
export const withLoggerEventInfo = (_: void, p: { eventName: EventName; eventArgs?: unknown }) => {
  loggedEventInfo({ eventName: p.eventName, eventArgs: p.eventArgs })
}

const loggedEventInfo = (params: Omit<EventLogParams, 'status'>) =>
  loggedEvent4DataDog({ ...params, status: StatusType.info })
const loggedNetwork4DataDog = ({ httpStatus, path, method, status, requestParams, responseBody }: NetworkLogParams) => {
  const context: Log = {
    _custom: {
      url: `${apiOptions.baseURL}${path}`,
      httpStatus,
      httpMethod: method,
      requestParams,
      responseBody,
      type: 'network'
    }
  }
  datadogLogs.logger.log(path.replace(/\//, '').toUpperCase(), context, status)
}
const loggedEvent4DataDog = ({ eventName, status, eventArgs }: EventLogParams) => {
  const context: Log = { _custom: { type: 'event', eventName, eventArgs } }
  datadogLogs.logger.log(eventName.toUpperCase(), context, status)
}
const loggedFrontEnd4DataDog = ({ path, status, componentStack }: FrontEndLogParams) => {
  const context: Log = { _custom: { type: 'front', componentStack } }
  datadogLogs.logger.log(`${apiOptions.baseURL}${path}`, context, status)
}
