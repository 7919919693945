export const PLAN_ALLOWED_FEATURES = {
  CANVA: '/canva',
  REPORT: '/report',
  BENCHMARK: '/benchmark',
  UGC: '/ugc',
  CAMPAIGN: '/campaign',
  MENTION: '/mention',
  COMMENTS: '/comments',
  INSTANTWIN: '/instantwin',
  AUTOREPLY: '/auto-reply'
}

export const PLAN_NUMBER: { [x: string]: number } = {
  CANVA: 1,
  REPORT: 2,
  BENCHMARK: 3,
  UGC: 4,
  CAMPAIGN: 5,
  MENTION: 6,
  SNS_ACCOUNT_10: 7,
  SNS_ACCOUNT_UNLIMITED: 8,
  USER_6: 9,
  USER_10: 10,
  USER_15: 11,
  USER_20: 12,
  USER_50: 13,
  USER_100: 14,
  USER_UNLIMITED: 15,
  COMMENTS: 16,
  INSTANTWIN: 17,
  SNS_ACCOUNT_1: 18,
  SNS_ACCOUNT_2: 19,
  SNS_ACCOUNT_3: 20,
  SNS_ACCOUNT_4: 21,
  SNS_ACCOUNT_5: 22,
  SNS_ACCOUNT_6: 23,
  SNS_ACCOUNT_7: 24,
  SNS_ACCOUNT_8: 25,
  SNS_ACCOUNT_9: 26,
  SNS_ACCOUNT_11: 27,
  SNS_ACCOUNT_12: 28,
  SNS_ACCOUNT_13: 29,
  SNS_ACCOUNT_14: 30,
  SNS_ACCOUNT_15: 31,
  SNS_ACCOUNT_16: 32,
  SNS_ACCOUNT_17: 33,
  SNS_ACCOUNT_18: 34,
  SNS_ACCOUNT_19: 35,
  SNS_ACCOUNT_20: 36,
  SNS_ACCOUNT_21: 37,
  SNS_ACCOUNT_22: 38,
  SNS_ACCOUNT_23: 39,
  SNS_ACCOUNT_24: 40,
  SNS_ACCOUNT_25: 41,
  SNS_ACCOUNT_26: 42,
  SNS_ACCOUNT_27: 43,
  SNS_ACCOUNT_28: 44,
  SNS_ACCOUNT_29: 45,
  SNS_ACCOUNT_30: 46,
  SNS_ACCOUNT_31: 47,
  SNS_ACCOUNT_32: 48,
  SNS_ACCOUNT_33: 49,
  SNS_ACCOUNT_34: 50,
  SNS_ACCOUNT_35: 51,
  SNS_ACCOUNT_36: 52,
  SNS_ACCOUNT_37: 53,
  SNS_ACCOUNT_38: 54,
  SNS_ACCOUNT_39: 55,
  SNS_ACCOUNT_40: 56,
  SNS_ACCOUNT_41: 57,
  SNS_ACCOUNT_42: 58,
  SNS_ACCOUNT_43: 59,
  SNS_ACCOUNT_44: 60,
  SNS_ACCOUNT_45: 61,
  SNS_ACCOUNT_46: 62,
  SNS_ACCOUNT_47: 63,
  SNS_ACCOUNT_48: 64,
  SNS_ACCOUNT_49: 65,
  SNS_ACCOUNT_50: 66,
  SNS_ACCOUNT_51: 67,
  SNS_ACCOUNT_52: 68,
  SNS_ACCOUNT_53: 69,
  SNS_ACCOUNT_54: 70,
  SNS_ACCOUNT_55: 71,
  SNS_ACCOUNT_56: 72,
  SNS_ACCOUNT_57: 73,
  SNS_ACCOUNT_58: 74,
  SNS_ACCOUNT_59: 75,
  SNS_ACCOUNT_60: 76,
  SNS_ACCOUNT_61: 77,
  SNS_ACCOUNT_62: 78,
  SNS_ACCOUNT_63: 79,
  SNS_ACCOUNT_64: 80,
  SNS_ACCOUNT_65: 81,
  SNS_ACCOUNT_66: 82,
  SNS_ACCOUNT_67: 83,
  SNS_ACCOUNT_68: 84,
  SNS_ACCOUNT_69: 85,
  SNS_ACCOUNT_70: 86,
  SNS_ACCOUNT_71: 87,
  SNS_ACCOUNT_72: 88,
  SNS_ACCOUNT_73: 89,
  SNS_ACCOUNT_74: 90,
  SNS_ACCOUNT_75: 91,
  SNS_ACCOUNT_76: 92,
  SNS_ACCOUNT_77: 93,
  SNS_ACCOUNT_78: 94,
  SNS_ACCOUNT_79: 95,
  SNS_ACCOUNT_80: 96,
  SNS_ACCOUNT_81: 97,
  SNS_ACCOUNT_82: 98,
  SNS_ACCOUNT_83: 99,
  SNS_ACCOUNT_84: 100,
  SNS_ACCOUNT_85: 101,
  SNS_ACCOUNT_86: 102,
  SNS_ACCOUNT_87: 103,
  SNS_ACCOUNT_88: 104,
  SNS_ACCOUNT_89: 105,
  SNS_ACCOUNT_90: 106,
  SNS_ACCOUNT_91: 107,
  SNS_ACCOUNT_92: 108,
  SNS_ACCOUNT_93: 109,
  SNS_ACCOUNT_94: 110,
  SNS_ACCOUNT_95: 111,
  SNS_ACCOUNT_96: 112,
  SNS_ACCOUNT_97: 113,
  SNS_ACCOUNT_98: 114,
  SNS_ACCOUNT_99: 115,
  SNS_ACCOUNT_100: 116,
  AUTOREPLY: 117,
  INSTANTWIN_AUTO_REPLY: 118
}
