import { all, fork, takeEvery } from 'redux-saga/effects'

export function* watchAndLog() {
  yield takeEvery('*', function* logger(action) {
    // const state = yield select()
  })
}

/** saga **/
function* commonSaga(): any {
  yield all([fork(watchAndLog)])
}

export default commonSaga
