import dayjs, { Dayjs } from 'dayjs'
import { List, Record } from 'immutable'
import { TPaging, TSnses } from 'src/modules/entity/API'
import { MediaStatus } from 'src/modules/entity/library'
import {
  TApprovalRolesGetOrder,
  TApprovalSettingsGetOrder,
  TApprovalStatusGetOrder
} from 'src/redux/models/ApprovalInfo'
import { Todo } from 'src/redux/models/CalendarInfo'
import { TPostCommentOrder } from 'src/redux/models/PostCommentInfo'
import { SnsAccount } from 'src/redux/models/SnsAccountInfo'
import { JSObject } from 'types/common'
import { isEmptyObject } from 'src/util/object'
import {InstagramProductTag, InstagramUserTag} from 'src/modules/entity/post'

dayjs.locale('ja')

/**
 * post と共通する箇所が多いため posts のモデルも含みます。
 */
/*** register models ***/
export type TRegisterPostOrder = {
  post: {
    id?: string
    mId: string
    provider: TSnses
    inReplyToStatusId: string
    type: 'text' | 'carousel' | 'image' | 'photo' | 'video' | 'stories'
    mngTitle: string
    mngNote: string
    text: string
    publishAt: string
  }
  postMediaRelations: { postMediaId: number; orderNumber: number }[]
}
export type TPostsOrder = {
  post: {
    mId: string
    id?: string
    statuses?: number[]
    provider?: string
    publishAtFrom?: string
    publishAtTo?: string
  }
  paging?: TPaging
  sort?: string
}

/*** order models ***/
// getIniPost Approval 関係も
export type TPostOrder = {
  post: TPostsOrder // 固有のpostを引っ張るのに最低限必要な引数 mId
  // media: TLibraryOrder // snsAccountId 画像倉庫実装時に有効化
  comment: TPostCommentOrder // snsAccountId & postId
  snsAccounts: TApprovalRolesGetOrder
  settings: TApprovalSettingsGetOrder
  status: TApprovalStatusGetOrder
}
export type TFinishEditPostOrder = {
  post: {
    id: string // postId
    snsAccountId: string
    status: 20 | 30 // 20:承認待ち 30:投稿待ち
    mId: string
  }
}
export type TUpdatePostStatusOrder = {
  post: {
    id: string // postId
    snsAccountId: string
    status: 40 | 99 // 40:投稿済み 99:破棄 40リクエストはIGのみ
  }
}

/*** store models ***/
export type TPostMediaRelation = {
  id: number
  postId: number
  postMediaId: number
  orderNumber: number
}
export class PostMediaRelation extends Record<TPostMediaRelation>({
  id: 0,
  postId: 0,
  postMediaId: 0,
  orderNumber: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new PostMediaRelation(params)
  }
}

export type TPostMediaRelationsPostMedia = {
  id: number
  brandId: number
  url: string
  filename: string
  thumbnailUrl: string | null
  thumbnailFilename: string | null
  isInstagram: boolean
  isTwitter: boolean
}
export class PostMediaRelationsPostMedia extends Record<TPostMediaRelationsPostMedia>({
  id: 0,
  brandId: 0,
  url: '',
  filename: '',
  thumbnailUrl: '',
  thumbnailFilename: '',
  isInstagram: false,
  isTwitter: false
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new PostMediaRelationsPostMedia(params)
  }
}

export type TPostMediaInstagramUserTags = {
  id: number
  uniqueId: string
  coordinateX?: number
  coordinateY?: number
}

export class PostMediaInstagramUserTags extends Record<TPostMediaInstagramUserTags>({
  id: 0,
  uniqueId: '',
  coordinateX: 0,
  coordinateY: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new PostMediaInstagramUserTags(params)
  }
  static iniList(params: JSObject[]): List<PostMediaInstagramUserTags> {
    if (isEmptyObject(params)) return List()
    return List(params.map(x => PostMediaInstagramUserTags.fromResponse(x)))
  }
}

export type TPostMediaRelations = {
  postMedia: TPostMediaRelationsPostMedia
  postMediaRelation: PostMediaRelation
  postMediaInstagramUserTags: PostMediaInstagramUserTags[]
}
export class PostMediaRelations extends Record<TPostMediaRelations>({
  postMediaRelation: new PostMediaRelation(),
  postMedia: new PostMediaRelationsPostMedia(),
  postMediaInstagramUserTags: []
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new PostMediaRelations(params)
  }
  static iniList(params: JSObject[]): List<PostMediaRelations> {
    if (isEmptyObject(params)) return List()
    return List(params.map(x => PostMediaRelations.fromResponse(x)))
  }
}
// 0:未投稿,10:下書き, 20:承認待ち, 30:投稿待ち, 40:投稿済み, 99:破棄
export type TPostStatus = 0 | 10 | 20 | 30 | 40 | 99
export type TPostMediaType =
  | 'text'
  | 'carousel'
  | 'carousel_album'
  | 'image'
  | 'photo'
  | 'video'
  | 'animated_gif'
  | 'stories'
  | 'none' // 便宜上のnone

export type TPostContent = {
  id: number
  brandId: number
  snsAccountId: number
  mId: string
  mediaId?: string | null
  provider: TSnses
  type: TPostMediaType
  mngTitle: string
  mngNote: string
  status: TPostStatus
  text: string
  isReel?: boolean
  thumbOffset?: number
  publishAt: Dayjs
  inReplyToStatusId: string | null
}

export type TPost = {
  post: TPostContent
  postMediaRelations?: PostMediaRelations[]
  snsAccount: SnsAccount
  todo: Todo
}
export class Post extends Record<TPost>({
  post: {
    id: 0,
    brandId: 0,
    snsAccountId: 0,
    mId: '',
    mediaId: '',
    provider: '',
    type: 'none',
    mngTitle: '',
    mngNote: '',
    status: 0,
    text: '',
    isReel: false,
    thumbOffset: null,
    publishAt: dayjs().add(7, 'day'),
    inReplyToStatusId: ''
  },
  postMediaRelations: [],
  snsAccount: new SnsAccount(),
  todo: new Todo()
}) {
  [x: string]: any
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.publishAt = dayjs(params.publishAt)
    return new Post(params)
  }
  static iniList(params: JSObject[]): List<Post> {
    if (isEmptyObject(params)) return List()
    return List(params.map(x => Post.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<Post>): { list: List<Post>; hasMore: boolean } {
    if (isEmptyObject(params)) return { list: exist, hasMore: false }
    return { list: exist.concat(Post.iniList(params)), hasMore: true }
  }
}

type TPostStatusResult = {
  id: string
  text: string
  status: 0 | 10 | 20 | 30 | 40 | 99 // 0:未投稿,10:下書き, 20:承認待ち, 30:投稿待ち, 40:投稿済み, 99:破棄
  publishAt: Dayjs
  deleteFlg: boolean
}
export class PostStatusResult extends Record<TPostStatusResult>({
  id: '',
  text: '',
  status: 0,
  publishAt: undefined,
  deleteFlg: false
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.publishAt = dayjs(params.publishAt)
    return new PostStatusResult(params)
  }
}

export type TPostFormValuesPostMediaRelations = {
  id: number
  postId?: number
  postMediaId?: number
  orderNumber?: number
  brandId?: number
  url: string
  filename: string
  contentType: string
  thumbnailUrl: string | null
  thumbnailFilename: string | null
  status: MediaStatus
  storageAt: string
  isInstagram: boolean
  isTwitter: boolean
  isFacebook: boolean
  instagramUserTags?: InstagramUserTag[]
  instagramProductTags?: InstagramProductTag[]
}

export type TPostFormValues = {
  id: string
  inReplyToStatusId: string
  mId: string
  mngNote: string
  mngTitle: string
  status: MediaStatus
  provider: TSnses
  publishAt: Dayjs
  text: string
  type: string
  carouselNum: number
  postMediaRelations: TPostFormValuesPostMediaRelations[]
  instagramUserTags?: InstagramUserTag[]
  instagramProductTags?: InstagramProductTag[]
  account: string
  thumbOffset?: number
}
export class PostFormValues extends Record<TPostFormValues>({
  id: '',
  inReplyToStatusId: '',
  mId: '',
  mngNote: '',
  mngTitle: '',
  status: 10,
  postMediaRelations: [],  // TODO 型を定義する　#3339で対応予定 https://github.com/CyberBuzz/slider-web/blob/d3727b8d4f32590d8733ed859491797638774ffe/web/src/containers/Post/hooks/index.ts#L475
  instagramUserTags: [], // TODO Deprecated!
  instagramProductTags: [], // TODO Deprecated!
  provider: '',
  publishAt: dayjs().add(7, 'day'),
  text: '',
  type: '',
  carouselNum: 0,
  account: '',
  thumbOffset: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new PostFormValues(params)
  }
}

type TPostMediaResult = {
  postMediaId: string
  orderNumber: number
}
export class PostMediaResult extends Record<TPostMediaResult>({
  postMediaId: '',
  orderNumber: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new PostMediaResult(params)
  }
  static iniList(params: JSObject[]): List<PostMediaResult> {
    if (isEmptyObject(params)) return List()
    return List(params.map(x => PostMediaResult.fromResponse(x)))
  }
}

/*** register response ***/
export type TRegisterPostResult = {
  note: string
}
export class RegisterPostResult extends Record<TRegisterPostResult>({
  note: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new RegisterPostResult(params)
  }
}
