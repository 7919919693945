import { routerMiddleware } from 'connected-react-router/immutable'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { userActionLogger } from './middleware/userActionLogger'
import { hsTokenChanger } from './middleware/hsTokenChanger'
import { rootReducer } from './reducers'
import rootSaga from './sagas'

const persistConfig = {
  transforms: [
    immutableTransform({
      blacklist: ['router']
    })
  ],
  key: 'root',
  storage,
  blacklist: ['router'],
  whitelist: ['Auth']
}

// Create and export the history object
export const history = createBrowserHistory()

// Middlewares setup
const reactRouterMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
// const loggerMiddleware = createLogger()
// const middleWares = [reactRouterMiddleware, sagaMiddleware, loggerMiddleware]
const middleWares = [reactRouterMiddleware, sagaMiddleware, userActionLogger, hsTokenChanger]

// for dev tools
let composeEnhancers: any
interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
}
declare let window: ExtendedWindow
composeEnhancers = (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
// eslint-disable-next-line no-undef
if (process.env.REACT_APP_STAGE === 'prd') composeEnhancers = compose

/**
 * 1. connectRouter の引数に history が必要
 * 2. 各種middlewareを挟む場合はココ
 * @param initialState
 */
export function configureStore(initialState?: any) {
  const store = createStore(
    persistReducer(persistConfig, rootReducer(history)),
    initialState,
    composeEnhancers(applyMiddleware(...middleWares))
  )
  sagaMiddleware.run(rootSaga)
  return store
}

const store = configureStore()
export const persistor = persistStore(store)
export default store
