import { darken } from 'polished'
import React from 'react'
import theme from 'src/styles/Theme'
import styled, { css } from 'styled-components'

export type StyledButtonProps = {
  /**
   * ボタンの文言
   */
  children?: React.ReactNode
  /**
   * 小さくするか如何
   */
  isSmall?: boolean
  /**
   * disabled フラグ. form 中で使用するとvalueは無視されます。
   */
  disabled?: boolean
  /**
   * not allowed フラグ. 値は変更できないけど、valueは無視されない
   */
  notAllowed?: boolean
}
export type ButtonProps = StyledButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>

export const _Button: React.VFC<ButtonProps> = props => {
  const { isSmall, ...rest } = props
  return <button {...rest} />
}

export const disabledCss = css`
  opacity: 0.5;
  cursor: default;
`
export const notAllowedCss = css`
  opacity: 0.5;
  cursor: not-allowed;
`

export const ButtonStyle = css<StyledButtonProps>`
  display: inline-block;
  font-weight: 400;
  color: black;
  text-align: center;
  vertical-align: middle;
  background-color: ${theme.colors.default};
  border: 1px solid ${darken(0.1, theme.colors.default)};
  font-size: ${props => (props.isSmall ? '12px' : '14px')};
  word-break: keep-all;
  padding: 0.4em 0.6em;
  line-height: 1.5;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  :hover {
    color: black;
    background-color: ${props => !props.disabled && darken(0.1, theme.colors.default)};
    border-color: ${props => !props.disabled && darken(0.1, theme.colors.default)};
    text-decoration: none;
  }
  :not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  ${props => props.disabled && disabledCss}
  ${props => props.notAllowed && notAllowedCss}
`

const StyledButton = styled(_Button)`
  ${ButtonStyle};
`

/**
 * 通常のボタン<br />
 * `onClick` がない場合 `type="submit"` になります。それ以外は `type="button"`<br />
 * フォーム内で使用する場合、 disabled だと value が認識されないので、 value が必要な場合は notAllowed にしてね<br />
 */
export const Button: React.VFC<ButtonProps> = ({ isSmall = false, onClick, ...props }) => {
  const buttonType = onClick ? 'button' : 'submit'
  return <StyledButton {...{ ...props, isSmall, type: buttonType, onClick }} />
}
