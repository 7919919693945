const content = {
  // iPhone 8 plus が歴代 iPhoneの中で最も幅広画面なのでこれに合わせる
  maxWidth: '414px',
  breakpoint: {
    default: 48, // about 769px
    tablet: 30 // about 481px
  }
}
const media = {
  pc: `${content.breakpoint.default}rem`, // 769px以上〜
  tablet: `${content.breakpoint.tablet}rem` // 481px以上〜
}
const BASE = {
  flexCol: 12,
  flexMaxWidth: 100,
  flexColGutters: 2
}
const flex = {
  col: BASE.flexCol,
  maxWidth: BASE.flexMaxWidth,
  gutters: BASE.flexColGutters,
  colWidth: BASE.flexMaxWidth / BASE.flexCol,
  colWidthGutters: (BASE.flexMaxWidth + BASE.flexColGutters) / BASE.flexCol
}
const transitions = {
  normal: '0.5s'
}
// Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro
// '"游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif'
const fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
export type Size = {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
}
const fontSize: Size = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '24px'
}
const colors = {
  default: '#f1f5f7',
  primary: '#2948ca',
  info: '#37cde6',
  success: '#1abc9c',
  warning: '#ffc107',
  danger: '#f1556c'
}
const snsColors = {
  instagram: '#E23667',
  twitter: '#1DA1F2',
  facebook: '#3b5999',
  line: '#00C300',
  tiktok: '#000000',

}
const btnColors = {
  primary: colors.primary,
  danger: colors.danger
}
const iconColors = {
  default: colors.default,
  success: colors.success,
  info: colors.info,
  warning: colors.warning,
  danger: colors.danger
}
const theme = {
  content,
  colors,
  btnColors,
  iconColors,
  snsColors,
  transitions,
  fontFamily,
  fontSize,
  flex,
  media
}
export type Theme = typeof theme
export default theme
