import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import assign from 'lodash/assign'
import Qs from 'qs'
import { ParsedUrlQueryInput } from 'querystring'
import { AxiosResponse, AxiosResponseTry } from 'types/axios'
import { apiOptions } from 'src/constants/api'
import { loggedNetworkSuccess, loggedNetworkError } from 'src/redux/middleware/dataDogLogger'
import { JSObject } from 'types/common'
import { TApiHeadersCommon } from 'src/modules/entity/API'

export class ApiClient {
  axiosInstance: AxiosInstance
  constructor(additionalOption: any = undefined) {
    const options = additionalOption ? assign(apiOptions, additionalOption) : apiOptions
    this.axiosInstance = axios.create(options)

    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        return config
      },
      (err: AxiosError) => {
        return Promise.reject(err)
      }
    )

    this.axiosInstance.interceptors.response.use(
      async v => {
        loggedNetworkSuccess({
          httpStatus: v.status,
          responseBody: v.data,
          path: v.config.url,
          requestParams: v.config.params,
          method: v.config.method.toUpperCase() as 'GET' | 'DELETE' | 'POST'
        })
        return v
      },
      (err: AxiosError) => {
        console.error('API Error:', { path: err.config?.url, params: { ...err.config?.params }, err })
        loggedNetworkError({
          httpStatus: err.response?.status,
          responseBody: err.response?.statusText || '',
          path: err.config?.url,
          requestParams: err.config?.params,
          method: err.config?.method.toUpperCase() as 'GET' | 'DELETE' | 'POST'
        })
        if (err.response?.status === 401) {
          return Promise.reject(err)
        }
        return err
      }
    )
  }

  async get<T = object>(
    path: string,
    params: ParsedUrlQueryInput,
    headers?: TApiHeadersCommon
  ): Promise<AxiosResponse<T>> {
    const l = { path, params, token: headers?.token, method: 'GET' as 'GET' }
    try {
      const result = await this.axiosInstance.get<T>(path, {
        params,
        paramsSerializer: function(params) {
          return Qs.stringify(params, { allowDots: true, encode: false })
        },
        ...this.getHeaders(headers)
      })
      // get (fetch) でエラーモーダルを出すとエラー頻度が高くなりすぎる
      // ただ当然エラーではあるので。。
      // if (this.isError(result)) {
      //   return this.createFailurePromise<T>(result, l)
      // }
      return this.createSuccessPromise<T>(result.data, result.headers, result.status, result.statusText, l)
    } catch (e) {
      return this.createFailurePromise<T>(e, l)
    }
  }
  async post<T = object>(path: string, params: object = {}, headers?: TApiHeadersCommon): Promise<AxiosResponse<T>> {
    const l = { path, params, method: 'POST' as 'POST' }
    try {
      const result = await this.axiosInstance.post<T, AxiosResponseTry<T>>(path, params, this.getHeaders(headers))
      if (this.isError(result)) {
        return this.createFailurePromise<T>(result, l)
      }
      return this.createSuccessPromise<T>(result.data, result.headers, result.status, result.statusText, l)
    } catch (e) {
      return this.createFailurePromise<T>(e, l)
    }
  }
  async postForm<T = object>(path: string, form: FormData, headers?: TApiHeadersCommon): Promise<AxiosResponse<T>> {
    const l = { path, params: form, method: 'POST' as 'POST' }
    try {
      const opt: AxiosRequestConfig = Object.assign(this.getHeaders(headers), { 'Content-Type': 'multipart/form-data' })
      const result = await this.axiosInstance.post<T, AxiosResponseTry<T>>(path, form, opt)
      if (this.isError(result)) {
        return this.createFailurePromise<T>(result, l)
      }
      return this.createSuccessPromise<T>(result.data, result.headers, result.status, result.statusText, l)
    } catch (e) {
      console.error(`path: ${path}, form: ${form}`, e)
      return this.createFailurePromise<T>(e, l)
    }
  }
  async delete<T = object>(path: string, params: object = {}, headers?: TApiHeadersCommon): Promise<AxiosResponse<T>> {
    const l = { path, params, method: 'DELETE' as 'DELETE' }
    try {
      const result = await this.axiosInstance.delete<T, AxiosResponseTry<T>>(path, {
        params,
        paramsSerializer: function(params) {
          return Qs.stringify(params, { allowDots: true, encode: false })
        },
        ...this.getHeaders(headers)
      })
      if (this.isError(result)) {
        return this.createFailurePromise<T>(result, l)
      }
      return this.createSuccessPromise<T>(result.data, result.headers, result.status, result.statusText, l)
    } catch (e) {
      return this.createFailurePromise<T>(e, l)
    }
  }
  getHeaders(param: TApiHeadersCommon): AxiosRequestConfig {
    return assign(param, {
      headers: {
        Authorization: param?.token || undefined
      }
    })
  }
  headerToCamelCase(str: string) {
    return str
      .split('-')
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join('')
  }
  isError<T>(res: AxiosResponseTry<T>): res is AxiosError<T> {
    return 'isAxiosError' in res && res.isAxiosError
  }
  async createSuccessPromise<T>(
    data: T,
    headers: { [x: string]: string }[],
    status: number,
    statusText: string,
    requestQuery: { path: string; params?: JSObject; method: 'GET' | 'POST' | 'DELETE' }
  ): Promise<AxiosResponse<T>> {
    return { data, headers, status, statusText, isSuccess: true, requestQuery }
  }
  async createFailurePromise<T>(
    error: AxiosError,
    requestQuery: { path: string; params?: JSObject; method: 'GET' | 'POST' | 'DELETE' }
  ): Promise<AxiosResponse<T>> {
    const headers = error?.response?.headers || {}
    const status = error?.response?.status || 500
    const statusText = error?.response?.statusText || error?.request?.responseText || ''
    return { error, headers, status, statusText, isSuccess: false, requestQuery }
  }
}
