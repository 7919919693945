import { useApprovalSettingsQuery, useApprovalStatusQuery, useApprovalTodoChecksQuery } from 'src/hooks/domain'

export const useApprovalSettings = (snsAccountId: number) => {
  const { data: settings, isLoading, refetch } = useApprovalSettingsQuery({
    payload: { postApprovalSetting: { snsAccountId } },
    options: { enabled: false }
  })
  return { settings: settings?.data, isLoading, refetch }
}

export const useApprovalStatus = (postId: number, snsAccountId: number) => {
  const { data: status, isLoading, refetch } = useApprovalStatusQuery({
    payload: { postApprovalStatus: { postId, snsAccountId } },
    options: { enabled: false }
  })
  return { status: status?.data, isLoading, refetch }
}

export const usePostApprovalTodoChecks = (snsAccountId: number, postId: number, stepNumber: number) => {
  const { data: postApprovalTodoChecks, isLoading, refetch } = useApprovalTodoChecksQuery({
    payload: {
      postApprovalTodo: {
        snsAccountId: snsAccountId
      },
      postApprovalTodoHistory: {
        postId: postId,
        stepNumber: stepNumber
      }
    },
    key: [{ postId: postId, snsAccountId: snsAccountId }],
    options: { enabled: !!snsAccountId }
  })
  return { postApprovalTodoChecks: postApprovalTodoChecks?.data, isLoading, refetch }
}
