import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { TLibraryOrder, TRegisterOrderGetQueries, TUpdateMediaStatusOrder } from 'src/redux/models/LibraryInfo'
import { getQueryString } from 'src/util/url'

export class LibraryApi extends CommonApi {
  static registerPostMedia = (form: FormData, getQueries: TRegisterOrderGetQueries, headers: TApiHeadersCommon) => {
    const getQuery = getQueryString(getQueries)
    return LibraryApi.postForm(`/resources/post_media${getQuery}`, form, headers)
  }
  static getIniLibrary = (params: TLibraryOrder, headers: TApiHeadersCommon) =>
    LibraryApi.get('/resources/post_media', params, headers)
  static getAddLibrary = (params: TLibraryOrder, headers: TApiHeadersCommon) =>
    LibraryApi.get('/resources/post_media', params, headers)
  static updateStatus = (params: TUpdateMediaStatusOrder, headers: TApiHeadersCommon) =>
    LibraryApi.post('/resources/post_media/status', params, headers)
}
