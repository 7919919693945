import { darken, getLuminance, lighten } from 'polished'
import React from 'react'
import theme, { Theme } from 'src/styles/Theme'
import styled, { css } from 'styled-components'
import { ButtonProps } from 'src/stories/atoms/Button'

export type ColoredButtonProps = {
  color?: keyof Theme['btnColors']
} & ButtonProps

const _Button: React.VFC<ColoredButtonProps> = props => {
  const { isSmall, ...rest } = props
  return <button {...rest} />
}

const disabledCss = css`
  opacity: 0.5;
  cursor: default;
`

const StyledButton = styled(_Button)`
  display: inline-block;
  font-weight: 400;
  color: ${props => (getLuminance(theme.btnColors[props.color]) > getLuminance('#999999') ? 'black' : 'white')};
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: ${props => theme.btnColors[props.color]};
  border: 1px solid ${props => darken(0.1, theme.btnColors[props.color])};
  font-size: ${props => (props.isSmall ? '12px' : '14px')};
  padding: 0.4em 0.6em;
  line-height: 1.5;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  :hover {
    background-color: ${props => !props.disabled && darken(0.1, theme.btnColors[props.color])};
    border-color: ${props => !props.disabled && darken(0.1, theme.btnColors[props.color])};
    text-decoration: none;
  }
  :focus {
    box-shadow: 0 0 0 0.1rem ${props => lighten(0.1, theme.btnColors[props.color])};
  }
  :not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  ${props => props.disabled && disabledCss}
`

/**
 * 色付きのボタンはこちらを使用してください。
 */
export const ColoredButton: React.VFC<ColoredButtonProps> = ({ color = 'primary', isSmall = false, ...props }) => {
  return <StyledButton {...{ ...props, color, isSmall }} />
}
