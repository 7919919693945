import { useMutationWrapper } from 'src/modules/core/mutation'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { FederateVariables } from 'src/modules/entity/line'
import { CommonApi } from 'src/redux/api/commonApi'

export class LineChannelApi extends CommonApi {
  static federate = (params: FederateVariables, headers: TApiHeadersCommon) =>
    LineChannelApi.post('/line/channels', params, headers)
}

export const useLineFederateMutation = () => {
  return useMutationWrapper<FederateVariables>({
    req: (payload, { token }) => LineChannelApi.federate(payload, { token })
  })
}
