import React, { ReactNode, useState } from 'react'
import Catch from './Error/ErrorBoundary'
import ReactError from './Error/ReactError'
import 'src/assets/scss/DefaultTheme.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactQueryDevtools } from 'react-query/devtools'
import { loggedFrontEndError } from 'src/redux/middleware/dataDogLogger'

type Props = { children: ReactNode }
const App = Catch(
  function App(props: Props, error?: Error) {
    const [reload, setReload] = useState(false)

    if (error && !reload) {
      return <ReactError message={error.message} setReload={setReload} />
    } else {
      return (
        <>
          {props.children}
          <ReactQueryDevtools />
        </>
      )
    }
  },
  (_error, info) => {
    loggedFrontEndError({ path: window.location.pathname, componentStack: info.componentStack })
  }
)

export default App
