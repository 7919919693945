import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { DaterangeActions } from 'src/redux/actions/DateRange'
import { DateRange } from 'src/redux/models/DaterangeInfo'

/** function  **/
function* getDaterange(action: ReturnType<typeof DaterangeActions.getDateRange>) {
  yield put(DaterangeActions.setDateRange(DateRange.fromInput(action.payload)))
}

/** watcher **/
export function* watchGetDateRange() {
  yield takeLatest(DaterangeActions.getDateRange, getDaterange)
}

/** saga **/
function* daterangeSaga(): any {
  yield all([fork(watchGetDateRange)])
}

export default daterangeSaga
