import { List, Record } from 'immutable'
import { LibraryActions } from 'src/redux/actions/Library'
import { Library, LibraryConditions } from 'src/redux/models/LibraryInfo'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { TResponseCommon } from 'src/modules/entity/API'

type TLibraryState = TResponseCommon & {
  media: List<Library>
  conditions: LibraryConditions
  nextPage: number
  hasMore: boolean
}
export class LibraryState extends Record<TLibraryState>({
  media: List(),
  conditions: new LibraryConditions(),
  nextPage: 1,
  hasMore: false,
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const LibraryReducer = reducerWithInitialState(new LibraryState())
  .case(LibraryActions.initializeLibrary, (state, payload) => new LibraryState())

  .case(LibraryActions.registerMedia, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )
  .case(LibraryActions.getIniLibrary, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )
  .case(LibraryActions.getAddLibrary, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )
  .case(LibraryActions.setLibrary, (state, payload) =>
    state.withMutations(s =>
      s
        .set('media', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(LibraryActions.setSearchConditions, (state, payload) =>
    state.withMutations(s =>
      s
        .set('conditions', payload)
        .set('error', null)
        .set('loading', false)
    )
  )

  // .case(LibraryActions.setNextPage, (state, payload) => state.withMutations(s => s.set('nextPage', payload)))
  .case(LibraryActions.setPagingState, (state, payload) =>
    state.withMutations(s => s.set('hasMore', payload.hasMore).set('nextPage', payload.nextPage))
  )
  .case(LibraryActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(LibraryActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
  .case(LibraryActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))
  .case(LibraryActions.setError, (state, payload) => state.withMutations(s => s.set('error', payload)))

export default LibraryReducer
