import { UseMutationResult, useMutation } from 'react-query'
import { useToast } from 'src/components/Toast/ToastProvider'
import { usePostDetail } from 'src/containers/Post/hooks'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  DeletePostCommentReactionVariables,
  FetchPostCommentReactionsQuery,
  FetchPostCommentReactionsVariables,
  FetchPostCommentsQuery,
  FetchPostCommentsVariables,
  FinishEditPostVariables,
  GetPostsVariables,
  ImmediatelyPostVariables,
  PostCompleteMediaUploadVariables,
  PostSignedUrlVariables,
  PostsQuery,
  RegisterPostCommentReactionVariables,
  RegisterPostCommentVariables,
  RegisterPostVariables,
  UpdatePostStatusVariables,
  GetPostVariables,
  PostData,
  FetchPendingPostCsvVariables,
  GenerateDraftPptxVariables
} from 'src/modules/entity/post'
import { CommonApi } from 'src/redux/api/commonApi'
import { genMessage } from 'src/modules/core/errors'
import { Report } from '../entity/report' // あとで変える
import { ApiClient } from 'src/redux/api/apiClient'
import { JSObject } from 'types/common'

const powerpointApiClient = new ApiClient({
  responseType: 'blob',
  dataType: 'binary'
})

class PostApi extends CommonApi {
  static fetchPost = (params: GetPostVariables, headers: TApiHeadersCommon) =>
    PostApi.get<PostData>(`/posts/${params.id}`, {}, headers)
  static fetchPosts = (params: GetPostsVariables, headers: TApiHeadersCommon) =>
    PostApi.get<PostsQuery>('/posts', params, headers)
  static registerPost = (params: RegisterPostVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts', params, headers)
  static finishEditPost = (params: FinishEditPostVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/finish/edit', params, headers)
  static updatePostStatus = (params: UpdatePostStatusVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/finish/publish', params, headers)
  static immediatelyPost = (params: ImmediatelyPostVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/publish', params, headers)
  static fetchPostComments = (params: FetchPostCommentsVariables, headers: TApiHeadersCommon) =>
    PostApi.get('/posts/comments', params, headers)
  static registerPostComment = (params: RegisterPostCommentVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/comments', params, headers)
  static getSignedUrl = (params: PostSignedUrlVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/comments/media/signed', params, headers)
  static completeMediaUpload = (params: PostCompleteMediaUploadVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/comments/media/complete', params, headers)
  static fetchPostCommentReactions = (params: FetchPostCommentReactionsVariables, headers: TApiHeadersCommon) =>
    PostApi.get('/posts/comments/reactions', params, headers)
  static registerPostCommentReaction = (params: RegisterPostCommentReactionVariables, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/comments/reaction/add', params, headers)
  static deletePostCommentReaction = (params: DeletePostCommentReactionVariables, headers: TApiHeadersCommon) =>
    PostApi.delete(`/posts/comments/reaction/${params.id}`, params, headers)
  static fetchPendingPostCsv = (params: FetchPendingPostCsvVariables, headers: TApiHeadersCommon) =>
    PostApi.get('/posts/organization/pending/csv', params, headers)
  static postPowerpoint = (path: string, params: JSObject, headers?: TApiHeadersCommon): Promise<{}> =>
    powerpointApiClient.post(path, params, headers)
  static generateDraftPptx = (params: GenerateDraftPptxVariables, headers: TApiHeadersCommon) =>
    PostApi.postPowerpoint('/posts/draft/download', params, headers)
}

export const usePostsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetPostsVariables>): ReactQueryResult<PostsQuery> => {
  return useQueryWrapper<PostsQuery>({
    queryKey: 'posts',
    deps: key,
    req: ({ token }) => PostApi.fetchPosts(payload, { token }),
    options
  })
}

// 1件のみ取得する
export const usePostQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetPostVariables>): ReactQueryResult<PostData> => {
  return useQueryWrapper<PostData>({
    queryKey: 'post',
    deps: key,
    req: ({ token }) => PostApi.fetchPost(payload, { token }),
    options
  })
}

export const useMngedPostsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetPostsVariables>): ReactQueryResult<PostsQuery> => {
  return useQueryWrapper<PostsQuery>({
    queryKey: 'posts',
    deps: key,
    req: ({ token }) => PostApi.fetchPosts(payload, { token }),
    options
  })
}

export const useFinishPostEditMutation = () => {
  return useMutationWrapper<FinishEditPostVariables>({
    req: (payload, { token }) => PostApi.finishEditPost(payload, { token })
  })
}

export const useUpdatePostStatusMutation = () => {
  return useMutationWrapper<UpdatePostStatusVariables>({
    req: (payload, { token }) => PostApi.updatePostStatus(payload, { token })
  })
}

export const useImmediatelyPostMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ImmediatelyPostVariables>({
    req: (payload, { token }) => PostApi.immediatelyPost(payload, { token }),
    toaster: res => {
      if (res.error || !res.data) {
        addToast({ result: 'error', message: genMessage(res.status, '失敗しました。'), status: res.status })
      }
    }
  })
}

export const usePostCommentsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchPostCommentsVariables>): ReactQueryResult<FetchPostCommentsQuery> => {
  return useQueryWrapper<FetchPostCommentsQuery>({
    queryKey: 'postComments',
    deps: key,
    req: ({ token }) => PostApi.fetchPostComments(payload, { token }),
    options
  })
}

export const usePostCommentReactionsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchPostCommentReactionsVariables>): ReactQueryResult<FetchPostCommentReactionsQuery> => {
  return useQueryWrapper<FetchPostCommentReactionsQuery>({
    deps: key,
    req: ({ token }) => PostApi.fetchPostCommentReactions(payload, { token }),
    options
  })
}

export const usePendingPostCsvQuery = ({ payload, key, options }: TUseQueryOptions<FetchPendingPostCsvVariables>) => {
  return useQueryWrapper<string>({
    deps: key,
    req: ({ token }) => PostApi.fetchPendingPostCsv(payload, { token }),
    options
  })
}

export const useRegisterPostCommentMutation = () => {
  return useMutationWrapper<RegisterPostCommentVariables>({
    req: (payload, { token }) => PostApi.registerPostComment(payload, { token })
  })
}

export const useGenerateDraftPptxMutation = (): UseMutationResult<{ data?: Report }, any, unknown> => {
  return useMutationWrapper<GenerateDraftPptxVariables>({
    req: (payload, { token }) => PostApi.generateDraftPptx(payload, { token })
  })
}

export const useRegisterPostCommentReactionMutation = () => {
  return useMutationWrapper<RegisterPostCommentReactionVariables>({
    req: (payload, { token }) => PostApi.registerPostCommentReaction(payload, { token })
  })
}

export const useDeletePostCommentReactionMutation = () => {
  return useMutationWrapper<DeletePostCommentReactionVariables>({
    req: (payload, { token }) => PostApi.deletePostCommentReaction(payload, { token })
  })
}

// TODO reactQueryに変換
export const useSignedUrlMutation = () => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useMutation<any, unknown, PostSignedUrlVariables, unknown>((payload: any) =>
    PostApi.getSignedUrl(payload, { token })
  )
}
// TODO reactQueryに変換
export const useCompleteMediaUploadMutation = () => {
  // eslint-disable-next-line max-len
  const { account, post } = usePostDetail() // 暫定対応：useMultipartFileUpload() に含めないため → https://github.com/CyberBuzz/slider-web/pull/1388/files#r607476935
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useMutation<any, unknown, PostCompleteMediaUploadVariables, unknown>(payload =>
    PostApi.completeMediaUpload({ ...payload, snsAccountId: account?.snsAccountId, postId: post?.post.id }, { token })
  )
}

export const useRegisterPost = () => {
  return useMutationWrapper<RegisterPostVariables>({
    req: (payload, { token }) => PostApi.registerPost(payload, { token })
  })
}
