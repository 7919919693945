import React, { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { useToast } from 'src/components/Toast/ToastProvider'
import { Button } from 'src/stories/atoms/Button'
import { ErrorMessage } from 'src/components/Error/ErrorMessage'

export type TResResult = 'error' | 'succeeded'
const getNotificationData = (result: TResResult, title?: string) => {
  return result === 'error'
    ? { result: 'error', color: 'error', icon: 'mdi-alert-octagon', title: 'エラー' }
    : { result: 'succeeded', color: 'info', icon: 'mdi-bell', title: title || '通知' }
}

export type TNotification = {
  id?: number
  result: TResResult
  message?: string
  status?: number | 'F'
  title?: string
  delay?: number
}
type Props = {
  toast: TNotification
}
export const ToastComponent: React.VFC<Props> = ({ toast }) => {
  const [modalShow, setModalShow] = React.useState(true)
  const modalToggle = () => setModalShow(!modalShow)
  const { removeToast } = useToast()

  useEffect(() => {
    if (toast.result === 'succeeded') {
      const timer = setTimeout(() => {
        removeToast(toast.id)
      }, toast.delay || 5000)
      return () => clearTimeout(timer)
    }
  }, [removeToast, toast])

  const notificationData = getNotificationData(toast?.result, toast.title)

  return (
    <>
      {notificationData?.result === 'succeeded' && (
        <Toast
          show={'true'}
          className={`toast-${notificationData.color}`}
          style={{ position: 'fixed', right: '0', top: '1rem', zIndex: 2000 }}
        >
          <ToastHeader className={`toast-${notificationData.color}-header`} toggle={() => removeToast(toast.id)}>
            <i className={`mdi ${notificationData.icon} rounded mr-2 font-weight-bold`} />
            <strong className={'mr-auto'}>{notificationData.title}</strong>
          </ToastHeader>
          <ToastBody className={'text-dark'} style={{ whiteSpace: 'pre-wrap' }}>{toast.message}</ToastBody>
        </Toast>
      )}
      {notificationData?.result === 'error' && (
        <Modal isOpen={modalShow} onClose={modalToggle} className={`modal-${notificationData.color}`}>
          <ModalHeader toggle={modalToggle} className={`modal-${notificationData.color}-header`}>
            <i className={`mdi ${notificationData.icon} rounded mr-2 font-weight-bold text-white`} />
            <strong className={'mr-auto text-white'}>{notificationData.title}</strong>
          </ModalHeader>
          <ModalBody className={'text-dark'}>
            <ErrorMessage notification={toast} />
            <Button onClick={modalToggle} className="d-block mx-auto mt-2">
              閉じる
            </Button>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
