/* eslint-disable array-callback-return */
import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import { PostActions } from 'src/redux/actions/Post'
import { PostApi } from 'src/redux/api/postApi'
import { State } from 'src/redux/reducers'

/** generator **/
function* updatePostStatus(action: ReturnType<typeof PostActions.updatePostStatus>) {
  yield put(PostActions.setLoading(true))
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  try {
    const response = yield PostApi.updatePostStatus(action.payload, { token })
    if (response.isSuccess) {
      yield put(PostActions.setPostRefresh(true))
    } else {
      yield put(PostActions.setError({ status: response.status, message: response.error.message }))
    }
  } catch (e) {
    yield put(PostActions.setError({ ...e }))
  } finally {
    yield put(PostActions.setLoading(false))
  }
}

/** watcher **/
export function* watchUpdatePostStatus() {
  yield takeLatest(PostActions.updatePostStatus, updatePostStatus)
}

/** saga **/
function* postSaga(): any {
  yield all([fork(watchUpdatePostStatus)])
}

export default postSaga
