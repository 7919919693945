import { List, Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PostCommentActions } from '../actions/PostComment'
import { TRegisterResult, TResponseCommon } from 'src/modules/entity/API'
import { TPostComment } from '../models/PostCommentInfo'

export type TPostCommentState = TResponseCommon & {
  postComments: List<TPostComment>
  postCommentsNextPage: number
  postCommentsRefresh: boolean
  morePostComments: boolean
  register: TRegisterResult
}
export class PostCommentState extends Record<TPostCommentState>({
  postComments: List(),
  postCommentsNextPage: 2,
  postCommentsRefresh: false,
  morePostComments: false,
  register: null,
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const PostCommentReducer = reducerWithInitialState(new PostCommentState())
  .case(PostCommentActions.initializePostComment, (state, payload) => new PostCommentState())

  .case(PostCommentActions.registerPostComment, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )
  .case(PostCommentActions.getIniPostComments, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )
  .case(PostCommentActions.getAddPostComments, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )

  .case(PostCommentActions.setPostComments, (state, payload) =>
    state.withMutations(s =>
      s
        .set('postComments', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(PostCommentActions.setPostCommentsNextPage, (state, payload) =>
    state.withMutations(s => s.set('postCommentsNextPage', payload))
  )
  .case(PostCommentActions.setMorePostComments, (state, payload) =>
    state.withMutations(s =>
      s
        .set('morePostComments', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(PostCommentActions.setRegisterPostComment, (state, payload) =>
    state.withMutations(s =>
      s
        .set('register', payload)
        .set('error', null)
        .set('loading', false)
    )
  )

  .case(PostCommentActions.setPostCommentsRefresh, (state, payload) =>
    state.withMutations(s => s.set('postCommentsRefresh', payload))
  )
  .case(PostCommentActions.setError, (state, payload) =>
    state.withMutations(s =>
      s
        .set('error', payload)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(PostCommentActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(PostCommentActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
  .case(PostCommentActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))

export default PostCommentReducer
