import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { useQueryWrapper } from '../core/query'
import { ReactQueryResult, TUseQueryOptions } from '../entity/API'
import { CalendarQuery, CalendarVariables } from '../entity/calendar'

export class CalendarApi extends CommonApi {
  static getIniCalendar = (params: CalendarVariables, headers: TApiHeadersCommon) =>
    CalendarApi.get<CalendarQuery>('/calendar', params, headers)
  static getAddCalendar = (params: CalendarVariables, headers: TApiHeadersCommon) =>
    CalendarApi.get('/calendar', params, headers)
}

export const useCalendarQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<CalendarVariables>): ReactQueryResult<CalendarQuery> => {
  return useQueryWrapper<CalendarQuery>({
    queryKey: 'calendar',
    deps: key,
    req: ({ token }) => CalendarApi.getIniCalendar(payload, { token }),
    options
  })
}
