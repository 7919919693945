import { PLAN_NUMBER } from 'src/constants/plan'
import { TSelectOption } from 'types/common'

export const BRAND_PLANS: TSelectOption[] = [
  { value: `${PLAN_NUMBER.CANVA}`, label: 'Canva' },
  { value: `${PLAN_NUMBER.REPORT}`, label: 'レポート' },
  { value: `${PLAN_NUMBER.BENCHMARK}`, label: '競合分析' },
  { value: `${PLAN_NUMBER.UGC}`, label: 'UGC' },
  { value: `${PLAN_NUMBER.CAMPAIGN}`, label: 'キャンペーン' },
  { value: `${PLAN_NUMBER.MENTION}`, label: 'メンション収集' },
  { value: `${PLAN_NUMBER.COMMENTS}`, label: 'コメント収集' },
  { value: `${PLAN_NUMBER.INSTANTWIN}`, label: 'インスタントウィン' },
  { value: `${PLAN_NUMBER.AUTO_REPLY}`, label: '事後抽選オートリプライ' },
  { value: `${PLAN_NUMBER.INSTANTWIN_AUTO_REPLY}`, label: '即時抽選オートリプライ' }
]

// TODO : 本当は繰り返しで生成したい
// export const BRAND_PLAN_SNS_ACCOUNT_NUMBERS: TSelectOption[] = [...Array(100)].map((_, i) => ({
//   value: `${PLAN_NUMBER.SNS_ACCOUNT_NUMBERS_ + i + 1}`,
//   label: `${i + 1} ユーザーまで`
// }))

export const BRAND_PLAN_SNS_ACCOUNT_NUMBERS: TSelectOption[] = [
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_1}`, label: '1 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_2}`, label: '2 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_3}`, label: '3 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_4}`, label: '4 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_5}`, label: '5 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_6}`, label: '6 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_7}`, label: '7 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_8}`, label: '8 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_9}`, label: '9 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_10}`, label: '10 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_11}`, label: '11 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_12}`, label: '12 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_13}`, label: '13 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_14}`, label: '14 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_15}`, label: '15 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_16}`, label: '16 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_17}`, label: '17 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_18}`, label: '18 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_19}`, label: '19 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_20}`, label: '20 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_21}`, label: '21 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_22}`, label: '22 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_23}`, label: '23 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_24}`, label: '24 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_25}`, label: '25 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_26}`, label: '26 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_27}`, label: '27 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_28}`, label: '28 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_29}`, label: '29 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_30}`, label: '30 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_31}`, label: '31 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_32}`, label: '32 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_33}`, label: '33 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_34}`, label: '34 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_35}`, label: '35 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_36}`, label: '36 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_37}`, label: '37 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_38}`, label: '38 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_39}`, label: '39 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_40}`, label: '40 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_41}`, label: '41 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_42}`, label: '42 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_43}`, label: '43 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_44}`, label: '44 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_45}`, label: '45 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_46}`, label: '46 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_47}`, label: '47 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_48}`, label: '48 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_49}`, label: '49 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_50}`, label: '50 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_51}`, label: '51 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_52}`, label: '52 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_53}`, label: '53 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_54}`, label: '54 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_55}`, label: '55 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_56}`, label: '56 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_57}`, label: '57 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_58}`, label: '58 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_59}`, label: '59 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_60}`, label: '60 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_61}`, label: '61 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_62}`, label: '62 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_63}`, label: '63 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_64}`, label: '64 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_65}`, label: '65 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_66}`, label: '66 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_67}`, label: '67 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_68}`, label: '68 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_69}`, label: '69 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_70}`, label: '70 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_71}`, label: '71 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_72}`, label: '72 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_73}`, label: '73 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_74}`, label: '74 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_75}`, label: '75 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_76}`, label: '76 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_77}`, label: '77 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_78}`, label: '78 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_79}`, label: '79 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_80}`, label: '80 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_81}`, label: '81 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_82}`, label: '82 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_83}`, label: '83 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_84}`, label: '84 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_85}`, label: '85 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_86}`, label: '86 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_87}`, label: '87 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_88}`, label: '88 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_89}`, label: '89 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_90}`, label: '90 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_91}`, label: '91 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_92}`, label: '92 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_93}`, label: '93 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_94}`, label: '94 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_95}`, label: '95 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_96}`, label: '96 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_97}`, label: '97 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_98}`, label: '98 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_99}`, label: '99 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_100}`, label: '100 アカウントまで' },
  { value: `${PLAN_NUMBER.SNS_ACCOUNT_UNLIMITED}`, label: '無制限' }
]

export const BRAND_PLAN_USER_NUMBERS: TSelectOption[] = [
  { value: `${PLAN_NUMBER.USER_6}`, label: '6 ユーザーまで' },
  { value: `${PLAN_NUMBER.USER_10}`, label: '10 ユーザーまで' },
  { value: `${PLAN_NUMBER.USER_15}`, label: '15 ユーザーまで' },
  { value: `${PLAN_NUMBER.USER_20}`, label: '20 ユーザーまで' },
  { value: `${PLAN_NUMBER.USER_50}`, label: '50 ユーザーまで' },
  { value: `${PLAN_NUMBER.USER_100}`, label: '100 ユーザーまで' },
  { value: `${PLAN_NUMBER.USER_UNLIMITED}`, label: '無制限' }
]

export const COMPANY_TYPES: TSelectOption[] = [
  { value: '1', label: '株式会社' },
  { value: '2', label: '有限会社' },
  { value: '3', label: '合弁会社' },
  { value: '4', label: '合弁会社' },
  { value: '5', label: 'その他' }
]

// 実際にリクエストする value は -1. 便宜的に react-selectの仕様上 0 を避ける
export const COMPANY_LABEL_POSITION_CODE: TSelectOption[] = [
  { value: '1', label: '前' },
  { value: '2', label: '後' }
]

export const BRAND_STATUS_TYPE = {
  active: 10,
  suspensionOfUse: 50
}

export const BRAND_STATUS_OPTIONS: TSelectOption[] = [
  { value: BRAND_STATUS_TYPE.suspensionOfUse, label: '利用停止中' },
  { value: BRAND_STATUS_TYPE.active, label: 'アクティブ' }
]
