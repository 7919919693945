import actionCreatorFactory from 'typescript-fsa'
import { TPostMedia } from '../models/PostMediaInfo'

const actionCreator = actionCreatorFactory('MEDIAS')

export type Medias = {
  name: string
  postMedia: TPostMedia
}
/***
 * get for saga
 * set for reducer
 */
export const MediasActions = {
  setMedias: actionCreator<Medias[]>('SET_MEDIA')
}
