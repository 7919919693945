import { TRoleType } from './AuthInfo'
import { JSObject } from '../../../types/common'
import { List, Record } from 'immutable'
import isEmpty from 'lodash/isEmpty'

/*** order models ***/
// get
type TApprovalCommonGetOrder = {
  snsAccountId: number
}
export type TApprovalRolesGetOrder = {
  snsRole: TApprovalCommonGetOrder
}
export type TApprovalSettingsGetOrder = {
  postApprovalSetting: TApprovalCommonGetOrder
}
export type TApprovalStatusGetOrder = {
  postApprovalStatus: {
    snsAccountId: number
    postId: number
  }
}
export type TApprovalOrder = {
  snsAccounts: TApprovalRolesGetOrder
  settings: TApprovalSettingsGetOrder
  status: TApprovalStatusGetOrder
}

/*** register models ***/
type TRole = {
  role: number
  userId: string
}
export type TApprovalSnsAccountsRegisterOrder = {
  snsAccountId: string
  snsRoles: TRole[] | []
}
export type TApprovalStatusRegisterOrder = {
  postApprovalStatus: {
    snsAccountId: number
    postId: number
    stepNumber: number
    status: number
  }
}
export type TApprovalSettingsRegisterOrder = {
  snsAccountId: number
  postApprovalSettings: TApprovalStepSettings[]
}
type TApprovalStepSettings = {
  userId: string
  stepNumber: number
}

/*** store models
 *
 * 投稿承認
 *
 * snsAccounts: TApprovalSnsAccount
 * settings: List<TApprovalSetting>
 * status: TApprovalStatus
 **/
// export type TApproval = {
//   snsAccounts: List<TApprovalSnsAccount>
//   settings?: List<TApprovalSetting>
//   status?: List<TApprovalStatus>
// }
type TApprovalAdmin = {
  id: number
  email: string
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  status: number // default 10
}
type TApprovalBrandRole = {
  id: number
  role: number
  roleType: TRoleType
  userId: number
  brandId: number
  belongs: string
  position: string
}
type TApprovalRole = {
  id: number
  role: number
  brandId: number
  snsAccountId: number
  userId: number
}
type TApprovalAdmins = {
  user: TApprovalAdmin
  brandRole: TApprovalBrandRole
  roleType: number
  roles?: TApprovalRole[]
}
export type TApprovalSnsAccount = {
  provider: string
  mId: string
  accountDetailPictureUrl: string
  nickname: string
  snsAccountId: string
  snsAccountMId: string
  users: TApprovalAdmins[]
}
export class ApprovalSnsAccount extends Record<TApprovalSnsAccount>({
  provider: '',
  mId: '',
  accountDetailPictureUrl: '',
  nickname: '',
  snsAccountId: '',
  snsAccountMId: '',
  users: []
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new ApprovalSnsAccount(params)
  }
  static iniList(params: JSObject[]): List<ApprovalSnsAccount> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => ApprovalSnsAccount.fromResponse(x)))
  }
  static addList(params: JSObject[], exist: List<ApprovalSnsAccount>): List<ApprovalSnsAccount> {
    if (isEmpty(params)) {
      return List()
    }
    return exist.concat(params.map(x => ApprovalSnsAccount.fromResponse(x)))
  }
}

/**
 * 承認フロー設定
 */
export type TApprovalSetting = {
  id: string
  stepNumber: number
  stepMaxNumber: number
  brandId: string
  snsAccountId: string
  userId: number
}
export class ApprovalSetting extends Record<TApprovalSetting>({
  id: '',
  stepNumber: 0,
  stepMaxNumber: 0,
  brandId: '',
  snsAccountId: '',
  userId: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new ApprovalSetting(params)
  }
  static iniList(params: JSObject[]): List<ApprovalSetting> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => ApprovalSetting.fromResponse(x.postApprovalSetting)))
  }
}

/**
 * 承認履歴
 */
export type TApprovalStatus = {
  id: string
  snsAccountId: string
  userId: string
  postId: string
  stepNumber: number
  status: number
}
export class ApprovalStatus extends Record<TApprovalStatus>({
  id: '',
  snsAccountId: '',
  userId: '',
  postId: '',
  stepNumber: 0,
  status: 10
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new ApprovalStatus(params)
  }
  static iniList(params: JSObject[]): List<ApprovalStatus> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => ApprovalStatus.fromResponse(x)))
  }
}

export type TApproval = {
  snsAccounts: List<TApprovalSnsAccount>
  settings: List<TApprovalSetting>
  status: List<TApprovalStatus>
}
export class Approval extends Record<TApproval>({
  snsAccounts: List(),
  settings: List(),
  status: List()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Approval(params)
  }
  static iniList(params: JSObject[]): List<Approval> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => Approval.fromResponse(x)))
  }
}

export type TRegisterApprovalResult = {
  note: string
  effectedId: string
}
export class RegisterApprovalResult extends Record<TRegisterApprovalResult>({
  note: '',
  effectedId: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new RegisterApprovalResult(params)
  }
  static iniList(params: JSObject[]): List<RegisterApprovalResult> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => RegisterApprovalResult.fromResponse(x)))
  }
}
