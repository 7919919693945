import { Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PostActions } from '../actions/Post'
import { TResponseCommon } from 'src/modules/entity/API'
import { Post, PostFormValues, RegisterPostResult } from '../models/PostInfo'

export type TPostState = TResponseCommon & {
  post: Post
  iniPostFormValues: PostFormValues
  postRefresh: boolean
  postMediaRefresh: boolean
  note: RegisterPostResult
}
export class PostState extends Record<TPostState>({
  post: new Post(),
  iniPostFormValues: new PostFormValues(),
  postRefresh: false,
  postMediaRefresh: false,
  note: new RegisterPostResult(),
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const PostReducer = reducerWithInitialState(new PostState())
  .case(PostActions.initializePost, (state, payload) => new PostState())

  .case(PostActions.updatePostStatus, (state, payload) => state.withMutations(s => s.set('error', null)))

  .case(PostActions.setPost, (state, payload) => state.withMutations(s => s.set('post', payload).set('error', null)))
  .case(PostActions.setRegisterPost, (state, payload) =>
    state.withMutations(s => s.set('note', payload).set('error', null))
  )
  .case(PostActions.setRegisterInstagramUserTags, (state, payload) => {
    return state.withMutations(s => {
      // 本当は postMediaRelationの中にinstagramUserTagsを入れたいが、叶わないので暫定対応
      // s.setIn(['iniPostFormValues', 'postMediaRelations', payload.index, 'instagramUserTags'], payload.tags).set('error', null)
      s.setIn(['iniPostFormValues', 'instagramUserTags'], payload)
    })
  })
  .case(PostActions.setRegisterInstagramProductTags, (state, payload) => {
    return state.withMutations(s => {
      s.setIn(['iniPostFormValues', 'instagramProductTags'], payload)
    })
  })

  .case(PostActions.setPostMediaRelations, (state, payload) =>
    state.withMutations(s => s.setIn(['iniPostFormValues', 'postMediaRelations'], payload).set('error', null))
  )
  .case(PostActions.setCarouselNum, (state, payload) =>
    state.withMutations(s =>
      s.setIn(['iniPostFormValues', 'carouselNum'], payload).setIn(['post', 'carouselNum'], payload)
    )
  )
  .case(PostActions.setPostFormValues, (state, payload) =>
    state.withMutations(s => s.set('iniPostFormValues', payload))
  )
  .case(PostActions.setPostRefresh, (state, payload) => state.withMutations(s => s.set('postRefresh', payload)))
  .case(PostActions.setPostMediaRefresh, (state, payload) =>
    state.withMutations(s => s.set('postMediaRefresh', payload))
  )
  .case(PostActions.setError, (state, payload) =>
    state.withMutations(s => s.set('error', payload).set('notified', false))
  )
  .case(PostActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('notified', false)
    )
  )
  .case(PostActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
  .case(PostActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))

export default PostReducer
