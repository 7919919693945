import { ConnectedRouter } from 'connected-react-router/immutable'
import React from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import ToastProvider from 'src/components/Toast/ToastProvider'
import { initializeCanva } from './modules/core/canva'
import store, { history, persistor } from './redux/configureStore'
import routes from './routes'
import * as serviceWorker from './serviceWorker'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

initializeCanva()

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={<p>loading...</p>} persistor={persistor}>
        <ConnectedRouter history={history}>
          <DndProvider backend={Backend}>
            <ToastProvider>{routes}</ToastProvider>
          </DndProvider>
        </ConnectedRouter>
      </PersistGate>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
