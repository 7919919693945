import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import App from 'src/components/App'
import Loader from 'src/components/atoms/Loader'
import { ROUTES } from 'src/constants/routes'
import PlanHOC from './PlanHOC'
import RoleHOC from './RoleHOC'

const WithTracker = React.lazy(() => import('./components/WithTracker'))
const Foundation = React.lazy(() => import('./components/Layout/Foundation'))
const Authenticate = React.lazy(() => import('./containers/Auth/Authenticate'))
const AccountRoute = React.lazy(() => import('./components/Routes/AccountRoute'))
const BrandBoundary = React.lazy(() => import('./components/Routes/BrandBoundary'))

/*** lazy load all the views ***/
// Auth
const SignIn = React.lazy(() => import('./containers/Auth/SignIn'))
const SignOut = React.lazy(() => import('./containers/Auth/SignOut'))
const SignUp = React.lazy(() => import('./containers/Auth/SignUp'))
const ForgetPassword = React.lazy(() => import('./containers/Auth/ForgetPassword'))
const ResetPassword = React.lazy(() => import('./containers/Auth/ResetPassword'))

// others
const Invitation = React.lazy(() => import('./containers/Invitation'))
const Error404 = React.lazy(() => import('./components/Error/Error404'))

// Pages
const OrganizationDashboard = React.lazy(() => import('./containers/OrganizationDashboard'))
const Dashboard = React.lazy(() => import('./containers/Dashboard'))
const AccountList = React.lazy(() => import('./containers/AccountList'))

// アカウントページ
const MngedPosts = React.lazy(() => import('./containers/MngedPosts'))
const AccountPosts = React.lazy(() => import('./containers/AccountPosts'))
const AccountHashtags = React.lazy(() => import('./containers/AccountHashtags'))
const Reels = React.lazy(() => import('./containers/InstagramReels'))
const Stories = React.lazy(() => import('./containers/InstagramStories'))
// const Mentions = React.lazy(() => import('./containers/AccountMentions'))
const Comments = React.lazy(() => import('./containers/InstagramComments'))
const AccountSettings = React.lazy(() => import('./containers/AccountSettings'))
const AccountTaggedPosts = React.lazy(() => import('./containers/AccountTaggedPosts'))
const Analytics = React.lazy(() => import('./containers/AccountAnalytics'))
const Complement = React.lazy(() => import('./containers/Complement'))

// 投稿
const PostDetail = React.lazy(() => import('./containers/Post/detail'))
const PostEdit = React.lazy(() => import('./containers/Post/edit'))
const PostCreate = React.lazy(() => import('./containers/Post/create'))

// 競合
const AccountsRivalAnalytics = React.lazy(() => import('./containers/AccountRivalAnalytics'))

// OAuth
const Oauth = React.lazy(() => import('./containers/Oauth'))
const OauthCallback = React.lazy(() => import('./containers/Oauth/OauthCallback'))

// カレンダー
const Calendar = React.lazy(() => import('./containers/Calendar'))

// ライブラリ
const Library = React.lazy(() => import('./containers/Library'))

// フォロワー収集
// const FollowerCollect = React.lazy(() => import('./containers/FollowerCollect'))

// UGC管理
const UgcSettingList = React.lazy(() => import('./containers/Ugc/list'))
const UgcNew = React.lazy(() => import('./containers/Ugc/new'))
const UgcDetail = React.lazy(() => import('./containers/Ugc/detail'))

// メディアタグ管理
const MediaTags = React.lazy(() => import('./containers/MediaTags/list'))

// 投稿カテゴリー管理
const PostCategoryList = React.lazy(() => import('./containers/PostCategories/list'))

// ユーザー管理
const Users = React.lazy(() => import('./containers/Users/list'))
const UserEdit = React.lazy(() => import('./containers/Users/edit'))

// ブランド管理
const BrandList = React.lazy(() => import('./containers/Brands/list'))
const BrandDetail = React.lazy(() => import('./containers/Brands/detail'))
const BrandNew = React.lazy(() => import('./containers/Brands/new'))

// 組織 管理
const OrganizationList = React.lazy(() => import('./containers/Organizations/list'))
const OrganizationDetail = React.lazy(() => import('./containers/Organizations/detail'))
const OrganizationNew = React.lazy(() => import('./containers/Organizations/new'))
const OrganizationPostCsv = React.lazy(() => import('./containers/Organizations/postCsv'))

// ツール
const Tools = React.lazy(() => import('./containers/Tools'))

// お知らせ
const Information = React.lazy(() => import('./containers/Information'))
const InformationDetail = React.lazy(() => import('./containers/Information/Detail'))

// エラーコード
const ErrorCodeDecoder = React.lazy(() => import('./containers/Tools/ErrorCodeDecoder'))

// アクティビティ
const Activities = React.lazy(() => import('./containers/Activities/list'))

// 利用規約
const Tos = React.lazy(() => import('src/containers/Tos'))

export default (
  <App>
    <Suspense fallback={<Loader />}>
      <WithTracker>
        <Switch>
          <Route exact path={ROUTES.signUp()} component={SignUp} />
          <Route exact path={ROUTES.signIn()} component={SignIn} />
          <Route exact path={ROUTES.signOut()} component={SignOut} />
          <Route exact path={ROUTES.forgetPassword()} component={ForgetPassword} />
          <Route exact path={ROUTES.passwordReset()} component={ResetPassword} />
          <Route exact path={ROUTES.oauthCallback()} component={OauthCallback} />
          <Route exact path={ROUTES.tos()} component={Tos} />
          <Authenticate>
            <Switch>
              <Route exact path={ROUTES.brandBoundary(':brandId', ':redirectTo')} component={BrandBoundary} />
              <Foundation>
                <Switch>
                  <Route exact path={ROUTES.organizationTop()} component={OrganizationDashboard} />
                  <Route exact path={ROUTES.brandTop(':brandId')} component={Dashboard} />
                  <Route exact path={ROUTES.accounts(':brandId')} component={AccountList} />
                  <Route exact path={ROUTES.invitation(':brandId')} component={Invitation} />
                  <Route exact path={ROUTES.oauth(':brandId')} component={Oauth} />
                  <Route exact path={ROUTES.oauthCallback()} component={OauthCallback} />
                  <Route exact path={ROUTES.library(':brandId')} component={Library} />
                  {/* <Route exact path={ROUTES.followerCollect(':brandId')} component={FollowerCollect} /> */}
                  <Route exact path={ROUTES.mediaTags(':brandId')} component={MediaTags} />
                  <Route exact path={ROUTES.calendar(':brandId')} component={Calendar} />
                  <Route exact path={ROUTES.post(':brandId', ':postId')} component={PostDetail} />
                  <Route exact path={`${ROUTES.post(':brandId', ':postId')}/edit`} component={PostEdit} />
                  <Route exact path={`${ROUTES.post(':brandId', ':postId')}/create`} component={PostCreate} />
                  <AccountRoute exact path={ROUTES.accountMngedPosts()} component={MngedPosts} />
                  <AccountRoute exact path={ROUTES.accountPosts()} component={AccountPosts} />
                  <AccountRoute exact path={ROUTES.accountHashtags()} component={AccountHashtags} />
                  <AccountRoute exact path={ROUTES.accountReels()} component={Reels} />
                  <AccountRoute exact path={ROUTES.accountStories()} component={Stories} />
                  {/* <AccountRoute exact path={ROUTES.accountMentions()} component={PlanHOC(Mentions, ['mention'])} /> */}
                  <AccountRoute exact path={ROUTES.accountComments()} component={PlanHOC(Comments, ['comments'])} />
                  <AccountRoute exact path={ROUTES.accountAnalytics()} component={Analytics} />
                  <AccountRoute
                    exact
                    path={ROUTES.accountRivalsAnalytics()}
                    component={PlanHOC(AccountsRivalAnalytics, ['benchmark'])}
                  />
                  <AccountRoute exact path={ROUTES.accountComplement()} component={Complement} />
                  <AccountRoute exact path={ROUTES.accountSettings()} component={AccountSettings} />
                  <AccountRoute exact path={ROUTES.accountTaggedPosts()} component={AccountTaggedPosts} />
                  <Route exact path={ROUTES.ugcList(':brandId')} component={PlanHOC(UgcSettingList, ['ugc'])} />
                  <Route exact path={ROUTES.ugcNew(':brandId')} component={PlanHOC(UgcNew, ['ugc'])} />
                  <Route exact path={ROUTES.ugc(':brandId', ':ugcId')} component={PlanHOC(UgcDetail, ['ugc'])} />
                  <Route exact path={ROUTES.postCategory(':brandId')} component={PostCategoryList} />
                  <Route exact path={ROUTES.users(':brandId')} component={Users} />
                  <Route exact path={ROUTES.userEdit(':brandId', ':userId')} component={UserEdit} />
                  <Route exact path={ROUTES.brands()} component={RoleHOC(BrandList, 'system')} />
                  <Route exact path={ROUTES.brandNew()} component={RoleHOC(BrandNew, 'system')} />
                  <Route exact path={ROUTES.brandDetail(':brandId')} component={RoleHOC(BrandDetail, 'system')} />
                  <Route exact path={ROUTES.organizations()} component={RoleHOC(OrganizationList, 'system')} />
                  <Route exact path={ROUTES.organizationNew()} component={RoleHOC(OrganizationNew, 'system')} />
                  <Route exact path={ROUTES.organizationPostCsv()} component={RoleHOC(OrganizationPostCsv, 'system')} />
                  <Route
                    exact
                    path={ROUTES.organizationDetail(':organizationSlug')}
                    component={RoleHOC(OrganizationDetail, 'system')}
                  />
                  <Route exact path={ROUTES.tools()} component={Tools} />
                  <Route exact path={ROUTES.information()} component={Information} />
                  <Route exact path={ROUTES.informationDetail(':infoId')} component={InformationDetail} />
                  <Route exact path={ROUTES.activities(':brandId')} component={Activities} />
                  <Route exact path={ROUTES.errorCodeDecoder()} component={ErrorCodeDecoder} />
                  <Route component={Error404} />
                </Switch>
              </Foundation>
            </Switch>
          </Authenticate>
        </Switch>
      </WithTracker>
    </Suspense>
  </App>
)
