import dayjs from 'dayjs'
import ja from 'date-fns/locale/ja'

export const DEFAULT_INSIGHT_DATE_RANGE: { from: string; to: string } = {
  from: dayjs(dayjs(), { locale: 'ja', utc: false })
    .add(-1, 'month')
    .startOf('day')
    .format('YYYY-MM-DD'),
  to: dayjs(dayjs(), { locale: 'ja', utc: false })
    .add(-1, 'day')
    .endOf('date')
    .format('YYYY-MM-DD')
}

export const jaLocale = { ...{ ...ja, ...{ options: { weekStartsOn: 0 } } } } as Locale

dayjs.locale('ja')
export const dayjsJa = (date?: dayjs.ConfigType) => dayjs(dayjs(date), { locale: 'ja', utc: false })
export const DATE_RANGE_DEFAULT = {
  from: dayjsJa().add(-1, 'month'),
  to: dayjsJa(),
  reportMonthStartDay: dayjsJa()
    .add(-6, 'month')
    .startOf('month'),
  reportMonthEndDay: dayjsJa()
    .add(-1, 'month')
    .endOf('month'),
  reportOfThisMonthStartDay: dayjsJa().startOf('month'),
  reportOfThisMonthEndDay: dayjsJa()
}
export const DATE_FORMAT = {
  ja: 'YYYY年M月D日',
  ja2digits: 'YYYY年MM月DD日',
  jaMD: 'M月D日',
  jaDateTime: 'YYYY年M月D日 H時m分',
  slash: 'YYYY/MM/DD',
  slashDateTime: 'YYYY/MM/DD HH:mm',
  time: 'HH:mm',
  hyphen: 'YYYY-MM-DD',
  plane: 'YYYYMMDD'
} as const

export const DATE_FORMATTED = {
  // for report
  reportFrom: DATE_RANGE_DEFAULT.reportMonthStartDay.format(),
  reportTo: DATE_RANGE_DEFAULT.reportMonthEndDay.format(),
  reportOfThisMonthFrom: DATE_RANGE_DEFAULT.reportOfThisMonthStartDay.format(),
  reportOfThisMonthTo: DATE_RANGE_DEFAULT.reportOfThisMonthEndDay.format(),
  reportDate: DATE_RANGE_DEFAULT.to.format(DATE_FORMAT.plane),

  // for date range picker & complement
  isoFrom: DATE_RANGE_DEFAULT.from.startOf('day').toISOString(),
  isoTo: DATE_RANGE_DEFAULT.to
    .subtract(1, 'day')
    .endOf('date')
    .toISOString(),

  // for complement
  dateTimeNow: DATE_RANGE_DEFAULT.to.toDate(),
  postPublishDate: dayjs()
    .startOf('minute')
    .add(30, 'minute')
}
