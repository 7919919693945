import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from 'src/components/Toast/ToastProvider'
import dayjs from 'dayjs'

export const genMessage = (status: number | 'F', customMessage?: string) => {
  if (customMessage) return customMessage
  if (status === 'F') return 'クライアントエラーが発生しました'
  if (status === 401) return 'ログインしてください'
  if (status === 403) return '権限がありません'
  if (status === 408) return 'タイムアウトが発生しました'
  if (status === 422) return '入力エラーが発生しました' // ??
  if (status === 429) return 'リクエストが多すぎます'
  if (status >= 500) return '障害エラーが発生しました。時間を置いて再度アクセスしてください。'
  return 'エラーが発生しました'
}

export const genErrorCode = (status: number | 'F') => {
  // F -> frontend, U -> unknown
  const prefix = !status ? 'U' : status
  return `E${prefix}-T${dayjs().format('MMDDHHmm')}`
}

export const getErrorStatus = (data: any, e?: any) => {
  return data?.status || e?.status
}

export const useErrors = () => {
  const [error, setErrors] = useState()
  const { addToast } = useToast()
  const history = useHistory()
  const setError = useCallback(
    err => {
      setErrors(err)
      if (!error) {
        addToast({ result: 'error', message: genMessage(err.status), status: err?.status })
      }
      if (!error && err.status === 401) {
        history.push('/signout')
      }
    },
    [addToast, error, history]
  )
  return { error, setError }
}
