import React, { useCallback, useContext, useState } from 'react'
import { TNotification } from 'src/components/Toast/ToastComponent'
import ToastContainer from 'src/components/Toast/ToastContainer'

type ToastContext = {
  toasts: TNotification[]
  addToast: (state: TNotification) => void
  removeToast: (id: number) => void
}
const ToastContext = React.createContext<ToastContext>(null)

/**
 * 使用方法
 * - 読み込み `const { addToast } = useToast()`
 * - トースト表示 `addToast({ result: 'error', message: 'hogefuga' })`
 *
 * 従来のように return で `{isError && addToastt({ result: 'error', message: 'hogefuga' })` みたいにすると無限ループ突入。
 * mutate 時、あるいは useEffect `if(isError)` などの中で一度だけ使用する想定です。
 * (useEffect の deps に  addToast を入れるとこれまた無限ループ)
 */
let id = 1
const ToastProvider: React.VFC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<TNotification[]>([])

  const addToast = useCallback((state: TNotification) => setToasts([{ ...toasts, ...{ ...state, ...{ id: id++ } } }]), [
    toasts,
    setToasts
  ])

  const removeToast = useCallback(
    (id: number) => {
      setToasts((state: TNotification[]) => state.filter((s: TNotification) => s.id !== id))
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      <ToastContainer toasts={toasts}>{children}</ToastContainer>
    </ToastContext.Provider>
  )
}

const useToast = () => useContext(ToastContext)

export { ToastContext, useToast }
export default ToastProvider
