import { List } from 'immutable'
import { TPagingState, TError } from 'src/modules/entity/API'
import {
  Library,
  LibraryConditions,
  TLibraryOrder,
  RegisterPostMediaVariables,
  TUpdateMediaStatusOrder
} from 'src/redux/models/LibraryInfo'
import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('LIBRARY')

/***
 * get for saga
 * set for reducer
 */
export const LibraryActions = {
  initializeLibrary: actionCreator('INITIALIZE_LIBRARY'),

  registerMedia: actionCreator<RegisterPostMediaVariables[]>('REGISE_LIBRARY'),
  getIniLibrary: actionCreator<TLibraryOrder>('GET_INI_LIBRARY'),
  getAddLibrary: actionCreator<TLibraryOrder>('GET_ADD_LIBRARY'),
  updateStatus: actionCreator<TUpdateMediaStatusOrder>('UPDATE_STATUS'),

  setLibrary: actionCreator<List<Library>>('SET_LIBRARY'),
  setSearchConditions: actionCreator<LibraryConditions>('SET_SEACH_CONDITIONS'),
  setPagingState: actionCreator<TPagingState>('SET_PAGING_STATE'),

  setError: actionCreator<TError>('SET_ERROR'),
  setLoading: actionCreator<boolean>('SET_LOADING'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
