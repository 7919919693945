import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from 'src/modules/core/errors'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import { CommonApi } from 'src/redux/api/commonApi'
import {
  RegisterOrganizationVariables,
  OrganizationsVariables,
  DeleteOrganizationVariables,
  OrganizationVariables,
  UpdateOrganizationBrandRelationVariables,
  RegisterOrganizationIpWhiteListVariables,
  RemoveOrganizationIpWhiteListVariables,
  OrganizationsQuery,
  OrganizationDetailQuery
} from 'src/modules/entity/organization'

export class OrganizationsApi extends CommonApi {
  static fetchList = (params: OrganizationsVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.get('/organizations', params, headers)
  static fetchDetail = (params: OrganizationVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.get(`/organizations/${params.slug}`, {}, headers)
  static register = (params: RegisterOrganizationVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.post('/organizations', params, headers)
  static remove = (params: DeleteOrganizationVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.delete(`/organizations/${params.slug}`, {}, headers)
  static updateBrandRelation = (params: UpdateOrganizationBrandRelationVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.post('/organizations/brand/relation', params, headers)
  static registerIpWhiteList = (params: RegisterOrganizationIpWhiteListVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.post('/organizations/ip/white-list', params, headers)
  static removeIpWhiteList = (params: RemoveOrganizationIpWhiteListVariables, headers: TApiHeadersCommon) =>
    OrganizationsApi.post('/organizations/ip/white-list/remove', params, headers)
}

export const useOrganizationsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<OrganizationsVariables>): ReactQueryResult<OrganizationsQuery[]> => {
  return useQueryWrapper<OrganizationsQuery[]>({
    queryKey: 'organizations',
    deps: key,
    req: ({ token }) => OrganizationsApi.fetchList(payload, { token }),
    options
  })
}
export const useOrganizationDetailQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<OrganizationVariables>): ReactQueryResult<OrganizationDetailQuery> => {
  return useQueryWrapper<OrganizationDetailQuery>({
    queryKey: 'organization',
    deps: key,
    req: ({ token }) => OrganizationsApi.fetchDetail(payload, { token }),
    options
  })
}

export const useRegisterOrganizationMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RegisterOrganizationVariables>({
    req: (payload, { token }) => OrganizationsApi.register(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '登録しました' })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '登録できませんでした'), status: res.status })
      }
    }
  })
}

export const useDeleteOrganizationMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<DeleteOrganizationVariables>({
    req: (payload, { token }) => OrganizationsApi.remove(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '削除しました' })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
    }
  })
}
export const useUpdateBrandRelationMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<UpdateOrganizationBrandRelationVariables>({
    req: (payload, { token }) => OrganizationsApi.updateBrandRelation(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '登録しました', status: res.status })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '登録できませんでした'), status: res.status })
      }
    }
  })
}
export const useRegisterIpWhiteListMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RegisterOrganizationIpWhiteListVariables>({
    req: (payload, { token }) => OrganizationsApi.registerIpWhiteList(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '登録しました' })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '登録できませんでした'), status: res.status })
      }
    }
  })
}
export const useRemoveIpWhiteListMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RemoveOrganizationIpWhiteListVariables>({
    req: (payload, { token }) => OrganizationsApi.removeIpWhiteList(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '登録しました' })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '登録できませんでした'), status: res.status })
      }
    }
  })
}
