import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { List, Record } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import { TPaging } from 'src/modules/entity/API'
import { JSObject } from 'types/common'
dayjs.locale('ja')

/*** register models ***/
export type TProviderValidities = {
  isTwitter: boolean
  isInstagram: boolean
  isInstagramReel: boolean
  isFacebook: boolean
}
export type TUgcContentId = {
  ugcMediaContentId?: string
}
export type TRegisterOrderGetQueries = TProviderValidities & TUgcContentId
export type TRegisterPostMediaOrder = {
  upload: File
  thumbnail?: File
}
export type RegisterPostMediaVariables = TRegisterPostMediaOrder & TRegisterOrderGetQueries

/*** order models ***/
export type MediaStatus = 10 | 20 | 99 // 10: active, 20: archive, 99: drop
export type TLibraryOrder = {
  kind?: MediaKind
  post?: {
    snsAccountId: number
  }
  postMediaTagRelation?: {
    postMediaTagIds: number[]
  }
  postMedia?: {
    status?: MediaStatus
    storageAtFrom?: string
    storageAtTo?: string
  }
  ugcSetting?: {
    id: number
  }
  sort?: string | string[]
  paging?: TPaging
}

export type TUpdateMediaStatusOrder = {
  postMedia: {
    id: number
    ids: number[]
    status: MediaStatus
  }
}

/*** store models ***/
export type MediaStatusFilter = 'upload' | 'archive' | ''
// 0: none, 10: upload, 20: ゴミ箱
export type MediaKind = 0 | 10 | 20
export type TLibraryConditions = {
  kind: MediaStatusFilter
  tags?: number[]
  hashtag?: string
  ugcSettingId?: number
  from: string | Date
  to: string | Date
}
export class LibraryConditions extends Record<TLibraryConditions>({
  kind: '',
  tags: [],
  // hashtag: '',
  ugcSettingId: 0,
  from: dayjs()
    .add(-3, 'month')
    .startOf('day')
    .format(),
  to: dayjs()
    .endOf('day')
    .format()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new LibraryConditions(params)
  }
}

type TUgcMediaContent = 10 | 20 | 30 // 10: 許諾なし, 20: 交渉中, 30: 許諾OK
export type TUgcMediaContentRelation = {
  ugcMedia: {
    mediaId: string
    link: string
  }
  ugcMediaContent: {
    status: TUgcMediaContent
  }
}

export type TLibraryPostMedia = {
  id: number
  brandId?: number
  status: MediaStatus
  contentType: string
  url: string
  filename: string
  thumbnailUrl: string | null
  thumbnailFilename: string | null
  storageAt: string
  isInstagram: boolean
  isTwitter: boolean
  isFacebook: boolean
  postMediaCompliantTypes?: {
    postMediaTag: string
    compliantType: number
  }[]
  duration?: number
  tags?: {
    id: number
    name: string
    relationId: number
  }[]
}

export class LibraryPostMedia extends Record<TLibraryPostMedia>({
  id: 0,
  brandId: 0,
  status: 10,
  contentType: '',
  url: '',
  filename: '',
  thumbnailUrl: '',
  thumbnailFilename: '',
  storageAt: '',
  isInstagram: false,
  isTwitter: false,
  isFacebook: false,
  duration: null,
  tags: []
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new LibraryPostMedia(params)
  }
}
export type TLibrary = {
  postMedia: TLibraryPostMedia
  ugcMediaContentRelation: TUgcMediaContentRelation | null
}
export class Library extends Record<TLibrary>({
  postMedia: new LibraryPostMedia(),
  ugcMediaContentRelation: {
    ugcMedia: {
      mediaId: '',
      link: ''
    },
    ugcMediaContent: {
      status: 10 // 10: 許諾なし, 20: 交渉中, 30: 許諾OK
    }
  }
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Library(params)
  }
  static iniList(params: JSObject[]): List<Library> {
    if (isEmpty(params)) {
      return List()
    }
    const ret = List(params.map(x => Library.fromResponse(x)))
      .sortBy(c => c.postMedia.storageAt)
      .groupBy(c => c.postMedia.id)
      .map(x => x.first<Library>())
      .toList()
      .reverse()
    return ret
  }
  static addList(params: JSObject[], exist: List<Library>): { list: List<Library>; hasMore: boolean } {
    if (isEmpty(params)) {
      return {
        list: exist
          .sortBy(c => c.postMedia.storageAt)
          .groupBy(c => c.postMedia.id)
          .map(x => x.first<Library>())
          .toList()
          .reverse(),
        hasMore: false
      }
    }
    return {
      list: exist
        .concat(Library.iniList(params))
        .sortBy(c => c.postMedia.storageAt)
        .groupBy(c => c.postMedia.id)
        .map(x => x.first<Library>())
        .toList()
        .reverse(),
      hasMore: true
    }
  }
}
