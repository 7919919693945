import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  PostCategoryEditRelationsByPostIdVariables,
  PostCategoryEditVariables,
  PostCategoryQuery,
  PostCategoryQueryVariables,
  PostCategoryRemoveVariables,
  PostCategoryEditRelationsByMediaIdVariables,
  PostCategoryEditRelationsByShortcodeVariables,
  PostCategoryEditRelationsByLinkVariables
} from 'src/modules/entity/postCategory'
import { CommonApi } from 'src/redux/api/commonApi'
import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from 'src/modules/core/errors'

export class PostCategoryApi extends CommonApi {
  static fetch = (params: PostCategoryQueryVariables, headers: TApiHeadersCommon) =>
    PostCategoryApi.get('/posts/category/templates', params, headers)
  static upsert = (params: PostCategoryEditVariables, headers: TApiHeadersCommon) =>
    PostCategoryApi.post('/posts/category/templates', params, headers)
  static remove = (params: PostCategoryRemoveVariables, headers: TApiHeadersCommon) =>
    PostCategoryApi.delete(`/posts/category/templates/${params.id}`, {}, headers)
  static editRelationsByPostId = (params: PostCategoryEditRelationsByPostIdVariables, headers: TApiHeadersCommon) =>
    PostCategoryApi.post('/posts/category/relations/postId', params, headers)
  static editRelationsByMediaId = (params: PostCategoryEditRelationsByMediaIdVariables, headers: TApiHeadersCommon) =>
    PostCategoryApi.post('/posts/category/relations/mediaId', params, headers)
  static editRelationsByShortcode = (
    params: PostCategoryEditRelationsByShortcodeVariables,
    headers: TApiHeadersCommon
  ) => PostCategoryApi.post('/posts/category/relations/shortcode', params, headers)
  static editRelationsByLink = (params: PostCategoryEditRelationsByLinkVariables, headers: TApiHeadersCommon) =>
    PostCategoryApi.post('/posts/category/relations/link', params, headers)
}

export const useFetchPostCategory = ({
  payload,
  key,
  options
}: TUseQueryOptions<PostCategoryQueryVariables>): ReactQueryResult<PostCategoryQuery[]> => {
  return useQueryWrapper({
    queryKey: 'postCategory',
    deps: key,
    options,
    req: ({ token }) => PostCategoryApi.fetch(payload, { token })
  })
}
export const useUpsertPostCategoryMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<PostCategoryEditVariables>({
    req: (payload, { token }) => PostCategoryApi.upsert(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '成功しました' })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '失敗しました。'), status: res.status })
      }
    }
  })
}
export const useRemovePostCategoryMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<PostCategoryRemoveVariables>({
    req: (payload, { token }) => PostCategoryApi.remove(payload, { token }),
    toaster: res => {
      if (res.status === 200) {
        addToast({ result: 'succeeded', message: '成功しました' })
      } else {
        addToast({ result: 'error', message: genMessage(res.status, '失敗しました。'), status: res.status })
      }
    }
  })
}

export const useEditPostCategoryRelationsByPostIdMutation = () => {
  return useMutationWrapper<PostCategoryEditRelationsByPostIdVariables>({
    req: (payload, { token }) => PostCategoryApi.editRelationsByPostId(payload, { token })
  })
}

// 補完
export const useEditPostCategoryRelationsByMediaIdMutation = () => {
  return useMutationWrapper<PostCategoryEditRelationsByMediaIdVariables>({
    req: (payload, { token }) => PostCategoryApi.editRelationsByMediaId(payload, { token })
  })
}
export const useEditPostCategoryRelationsByShortcodeMutation = () => {
  return useMutationWrapper<PostCategoryEditRelationsByShortcodeVariables>({
    req: (payload, { token }) => PostCategoryApi.editRelationsByShortcode(payload, { token })
  })
}
export const useEditPostCategoryRelationsByLinkMutation = () => {
  return useMutationWrapper<PostCategoryEditRelationsByLinkVariables>({
    req: (payload, { token }) => PostCategoryApi.editRelationsByLink(payload, { token })
  })
}
