import { useToast } from 'src/components/Toast/ToastProvider'
import { genMessage } from 'src/modules/core/errors'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  ComplementCSVQueryVariables,
  ComplementDeleteMutationVariables,
  ComplementIGAccountMutationVariables,
  ComplementIGAccountQuery,
  ComplementIGAccountQueryVariables,
  ComplementIGAudienceMutationVariables,
  ComplementIGAudienceQuery,
  ComplementIGAudienceQueryVariables,
  ComplementIGMediaMutationVariables,
  ComplementIGMediaQuery,
  ComplementIGMediaQueryVariables,
  ComplementIGStoriesMutationVariables,
  ComplementIGStoriesQuery,
  ComplementIGStoriesQueryVariables,
  ComplementIGReelsMutationVariables,
  ComplementIGReelQuery,
  ComplementIGReelsQueryVariables,
  ComplementTWAccountMutationVariables,
  ComplementTWAccountQuery,
  ComplementTWAccountQueryVariables,
  ComplementTWApiMediaMutationVariables,
  ComplementTWMediaMutationVariables,
  ComplementTWMediaQuery,
  ComplementTWMediaQueryVariables
} from 'src/modules/entity/complement'
import { CommonApi } from 'src/redux/api/commonApi'

export class ComplementApi extends CommonApi {
  // instagram
  static registerIGAccount = (params: ComplementIGAccountMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/ig/accounts', params, headers)
  static registerIGAudience = (params: ComplementIGAudienceMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/ig/audiences', params, headers)
  static registerIGMedia = (params: ComplementIGMediaMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/ig/medias', params, headers)
  static registerIGStories = (params: ComplementIGStoriesMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/ig/stories', params, headers)
  static registerIGReels = (params: ComplementIGReelsMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/ig/reels', params, headers)
  static igAccounts = (params: ComplementIGAccountQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/ig/accounts', params, headers)
  static igAudiences = (params: ComplementIGAudienceQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/ig/audiences', params, headers)
  static igMedias = (params: ComplementIGMediaQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/ig/medias', params, headers)
  static igStories = (params: ComplementIGStoriesQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/ig/stories', params, headers)
  static igReels = (params: ComplementIGReelsQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/ig/reels', params, headers)
  static deleteIGAccount = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/ig/accounts/${params.id}`, params, headers)
  static deleteIGAudience = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/ig/audiences/${params.id}`, params, headers)
  static deleteIGMedia = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/ig/medias/${params.id}`, params, headers)
  static deleteIGStories = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/ig/stories/${params.id}`, params, headers)
  static deleteIGReels = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/ig/reels/${params.id}`, params, headers)

  // twitter
  static registerTWAccount = (params: ComplementTWAccountMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/tw/accounts', params, headers)
  static registerTWMedia = (params: ComplementTWMediaMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/tw/medias', params, headers)
  static registerTWApiMedia = (params: ComplementTWApiMediaMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.post('/complement/tw/medias/api', params, headers)
  static twAccounts = (params: ComplementTWAccountQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/tw/accounts', params, headers)
  static twMedias = (params: ComplementTWMediaQueryVariables, headers: TApiHeadersCommon) =>
    ComplementApi.get('/complement/tw/medias', params, headers)
  static deleteTWAccount = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/tw/accounts/${params.id}`, params, headers)
  static deleteTWMedia = (params: ComplementDeleteMutationVariables, headers: TApiHeadersCommon) =>
    ComplementApi.delete(`/complement/tw/medias/${params.id}`, params, headers)

  // csv
  static fetchCsv = (params: ComplementCSVQueryVariables, headers: TApiHeadersCommon) => {
    const { provider, type, ...others } = params
    const complementObjectMap = {
      ig: {
        medias: 'complementInstagramMedia',
        accounts: 'complementInstagramAccount',
        reels: 'complementInstagramReel'
      },
      tw: {
        medias: 'complementTwitterMedia',
        accounts: 'complementTwitterAccount',
        reels: 'dummy'
      }
    }

    const generatePayload = { [complementObjectMap[provider][type]]: { ...others } }
    return ComplementApi.get(`/complement/${provider}/${type}/csv`, generatePayload, headers)
  }
}

// instagram
export const useComplementIgAccountQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementIGAccountQueryVariables>): ReactQueryResult<ComplementIGAccountQuery[]> => {
  return useQueryWrapper<ComplementIGAccountQuery[]>({
    queryKey: 'complementIgAccount',
    deps: key,
    req: ({ token }) => ComplementApi.igAccounts(payload, { token }),
    options
  })
}
export const useComplementIgAudienceQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementIGAudienceQueryVariables>): ReactQueryResult<ComplementIGAudienceQuery[]> => {
  return useQueryWrapper<ComplementIGAudienceQuery[]>({
    queryKey: 'complementIgAudience',
    deps: key,
    req: ({ token }) => ComplementApi.igAudiences(payload, { token }),
    options
  })
}
export const useComplementIgMediasQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementIGMediaQueryVariables>): ReactQueryResult<ComplementIGMediaQuery[]> => {
  return useQueryWrapper<ComplementIGMediaQuery[]>({
    queryKey: 'complementIgMedias',
    deps: key,
    req: ({ token }) => ComplementApi.igMedias(payload, { token }),
    options
  })
}
export const useComplementIgStoriesQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementIGStoriesQueryVariables>): ReactQueryResult<ComplementIGStoriesQuery[]> => {
  return useQueryWrapper<ComplementIGStoriesQuery[]>({
    queryKey: 'complementIgStories',
    deps: key,
    req: ({ token }) => ComplementApi.igStories(payload, { token }),
    options
  })
}
export const useComplementIgReelsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementIGReelsQueryVariables>): ReactQueryResult<ComplementIGReelQuery[]> => {
  return useQueryWrapper<ComplementIGReelQuery[]>({
    queryKey: 'complementIgReels',
    deps: key,
    req: ({ token }) => ComplementApi.igReels(payload, { token }),
    options
  })
}
export const useIgAccountMutation = () => {
  return useMutationWrapper<ComplementIGAccountMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerIGAccount(payload, { token })
  })
}
export const useIgAudienceMutation = () => {
  return useMutationWrapper<ComplementIGAudienceMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerIGAudience(payload, { token })
  })
}
export const useIgMediaMutation = () => {
  return useMutationWrapper<ComplementIGMediaMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerIGMedia(payload, { token })
  })
}
export const useIgStoriesMutation = () => {
  return useMutationWrapper<ComplementIGStoriesMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerIGStories(payload, { token })
  })
}
export const useIgReelsMutation = () => {
  return useMutationWrapper<ComplementIGReelsMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerIGReels(payload, { token })
  })
}
export const useIgAccountDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteIGAccount(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}
export const useIgAudienceDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteIGAudience(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}
export const useIgMediaDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteIGMedia(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}
export const useIgStoriesDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteIGStories(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}

export const useIgReelDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteIGReels(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}
// twitter
export const useComplementTwAccountQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementTWAccountQueryVariables>): ReactQueryResult<ComplementTWAccountQuery[]> => {
  return useQueryWrapper<ComplementTWAccountQuery[]>({
    queryKey: 'complementTwAccount',
    deps: key,
    req: ({ token }) => ComplementApi.twAccounts(payload, { token }),
    options
  })
}
export const useComplementTwMediasQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<ComplementTWMediaQueryVariables>): ReactQueryResult<ComplementTWMediaQuery[]> => {
  return useQueryWrapper<ComplementTWMediaQuery[]>({
    queryKey: 'complementTwMedias',
    deps: key,
    req: ({ token }) => ComplementApi.twMedias(payload, { token }),
    options
  })
}
export const useTwAccountMutation = () => {
  return useMutationWrapper<ComplementTWAccountMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerTWAccount(payload, { token })
  })
}
export const useTwMediaMutation = () => {
  return useMutationWrapper<ComplementTWMediaMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerTWMedia(payload, { token })
  })
}
export const useTwApiMediaMutation = () => {
  return useMutationWrapper<ComplementTWApiMediaMutationVariables>({
    req: (payload, { token }) => ComplementApi.registerTWApiMedia(payload, { token })
  })
}
export const useTwAccountDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteTWAccount(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}
export const useTwMediaDeleteMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<ComplementDeleteMutationVariables>({
    req: (payload, { token }) => ComplementApi.deleteTWMedia(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({ result: 'error', message: genMessage(res.status, '削除できませんでした'), status: res.status })
      }
      if (res.isSuccess) {
        addToast({ result: 'succeeded', message: '削除しました' })
      }
    }
  })
}

export const useComplementsCsvQuery = ({ payload, key, options }: TUseQueryOptions<ComplementCSVQueryVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'complementsCsv',
    deps: key,
    req: ({ token }) => ComplementApi.fetchCsv(payload, { token }),
    options
  })
}
