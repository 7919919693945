export const isRegExp = (obj: unknown) => obj instanceof RegExp

/**
 * Used to match `RegExp`
 */
const reRegExpChar = /[.*+?^=!:${}()|[\]/\\]/g,
  reHasRegExpChar = new RegExp(reRegExpChar.source)
export const escapeRegExp = (string: string) => {
  return reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string
}
