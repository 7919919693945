import { all } from 'redux-saga/effects'
import authSaga from 'src/redux/sagas/AuthSaga'
import commonSaga from 'src/redux/sagas/CommonSaga'
import daterangeSaga from 'src/redux/sagas/DaterangeSaga'
import insightSaga from 'src/redux/sagas/InsightSaga'
import librarySaga from 'src/redux/sagas/LibrarySaga'
import oauthSaga from 'src/redux/sagas/OauthSaga'
import postCommentSaga from 'src/redux/sagas/PostCommentSaga'
import postSaga from 'src/redux/sagas/PostSaga'

export default function* rootSaga() {
  yield all([
    commonSaga(),
    authSaga(),
    insightSaga(),
    oauthSaga(),
    daterangeSaga(),
    postSaga(),
    postCommentSaga(),
    librarySaga()
  ])
}
