import dayjs from 'dayjs'
import { List, Record } from 'immutable'
import { JSObject } from '../../../types/common'
import { Maybe } from 'src/types'

dayjs.locale('ja')

/*** order models ***/
// 1: system. 2: owner, 3, manager, 4: member, 99: none
export type TRoleType = 10 | 20 | 30 | 40 | 99
export type TRoleTypeString = '10' | '20' | '30' | '40' | '99'
export type TSignUpOrder = {
  hash: string
  user: {
    password: string
    lastName: string
    firstName: string
    lastNameKana: string
    firstNameKana: string
    status?: number
  }
}
export type TSignInOrder = { user: { email: string; password: string }; redirectTo?: string; brandId?: string }
export type TInvitationOrder = {
  user: {
    email: string
  }
  userBrandRole: {
    roleType: number | string
  }
}
export type TChangeBrandOrder = { userBrandRole: { brandId: number } }
export type TResetPasswordOrder = { hash: string; user: { password: string } }
export type TSentForgetPasswordMail = { password: string }
export type TSignOutOrder = {
  pushToTop?: boolean
}

/*** store models ***/
type TInvitation = {
  message: string
}
export class Invitation extends Record<TInvitation>({
  message: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Invitation(params)
  }
}

export type TBrand = {
  id: number
  name?: string
  nameKana?: string
  displayName: string
  email?: string
  tel?: string
  representativeLastName?: string
  representativeFirstName?: string
  representativeLastNameKana?: string
  representativeFirstNameKana?: string
  companyType?: number
  companyLabelPositionCode?: number
  status: number
}
export class Brand extends Record<TBrand>({
  id: 0,
  name: '',
  nameKana: '',
  displayName: '',
  email: '',
  tel: '',
  representativeLastName: '',
  representativeFirstName: '',
  representativeLastNameKana: '',
  representativeFirstNameKana: '',
  companyType: 0,
  companyLabelPositionCode: 0,
  status: 10
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Brand(params)
  }
}

type TUserBrandRoles = {
  roleType: TRoleType
  role: string
  brand: Brand
}
export class UserBrandRoles extends Record<TUserBrandRoles>({
  roleType: 99,
  role: '',
  brand: new Brand()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.brand = Brand.fromResponse(params.brand)
    return new UserBrandRoles(params)
  }
}

export type TUser = {
  id: number
  email: string
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  phoneNumber: string
  status: number
  password?: string
  belongs?: string
  position?: string
  userBrandRoles?: TUserBrandRoles[]
}
export class User extends Record<TUser>({
  id: 0,
  email: '',
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  phoneNumber: '',
  status: 0,
  userBrandRoles: []
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.userBrandRoles = params.userBrandRoles?.map((item: JSObject) => UserBrandRoles.fromResponse(item))
    return new User(params)
  }
}

export type TSigned = {
  token: string
  roleType: TRoleType
  user: User
  currentBrand: Brand
  selectableBrands: List<Brand>
  scopes: string[]
  hsVisitorToken?: string
  allowedIp: boolean
  organizationId: Maybe<number>
}
export class Signed extends Record<TSigned>({
  token: '',
  roleType: 99,
  user: new User(),
  currentBrand: new Brand(),
  selectableBrands: List(),
  scopes: [''],
  hsVisitorToken: null,
  allowedIp: false,
  organizationId: null
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    params.user = User.fromResponse(params.user)
    params.currentBrand = Brand.fromResponse(params.currentBrand.brand)
    params.selectableBrands = List(params.selectableBrands.map((item: JSObject) => Brand.fromResponse(item.brand)))
    return new Signed(params)
  }

  static signOut() {
    return new Signed()
  }
}

export type TForgetPassword = {
  message: string
}
export class ForgetPassword extends Record<TForgetPassword>({
  message: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new ForgetPassword(params)
  }
}

export type TResetPassword = {
  message: string
}
export class ResetPassword extends Record<TResetPassword>({
  message: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new ResetPassword(params)
  }
}
