import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { List } from 'immutable'
import { all, AllEffect, fork, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { PAGING_SIZE } from 'src/constants/query'
import { formatConditions } from 'src/helpers/library'
import { LibraryActions } from 'src/redux/actions/Library'
import { LibraryApi } from 'src/redux/api/libraryApi'
import {
  Library,
  LibraryConditions,
  TRegisterOrderGetQueries,
  RegisterPostMediaVariables
} from 'src/redux/models/LibraryInfo'
import { State } from 'src/redux/reducers'
import { AxiosResponse } from 'types/axios'
import { JSObject } from 'types/common'
dayjs.locale('ja')

export type AppendFormData = {
  formData: FormData
  rest: TRegisterOrderGetQueries
}

const translateErrorMessage = (httpStatus?: number) => {
  if (!httpStatus) return ''
  switch (httpStatus) {
    case 413:
      return 'ファイルのサイズが大きすぎます'
    default:
      return 'エラーが発生しました'
  }
}
export const appendFormData = async (file: RegisterPostMediaVariables): Promise<AppendFormData> => {
  const formData = new FormData()
  const { upload, thumbnail, ...rest } = file
  formData.append('upload', upload)
  thumbnail && formData.append('thumbnail', thumbnail)

  return { formData, rest }
}
export const registerLibraryMedia = async (files: RegisterPostMediaVariables[], token: string) =>
  await Promise.all(
    files.map(async (f: RegisterPostMediaVariables) => {
      const { formData, rest } = await appendFormData(f)
      return LibraryApi.registerPostMedia(formData, { ...rest }, { token })
    })
  )

/** generator  **/
function* registerMedia(action: ReturnType<typeof LibraryActions.registerMedia>) {
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const files = action.payload

  try {
    const responses: AxiosResponse<JSObject[]>[] = yield registerLibraryMedia(files, token)
    const invalidResponse: AxiosResponse<JSObject[]> = responses.find((r: AxiosResponse<JSObject[]>) => !r.isSuccess)
    if (!invalidResponse) {
      const conditions: LibraryConditions = yield select((state: State) => state.Library.get('conditions'))
      yield put(
        LibraryActions.getIniLibrary({
          ...formatConditions(conditions.toObject()),
          ...{ paging: { page: 1, size: PAGING_SIZE.lg } }
        })
      )
      yield put(LibraryActions.setSucceeded(true))
    } else {
      yield put(
        LibraryActions.setError({
          status: invalidResponse.status,
          message: translateErrorMessage(responses.find(r => r.error).status) || 'Error register media resources'
        })
      )
    }
  } catch (e) {
    yield put(LibraryActions.setError({ ...e }))
  } finally {
    yield put(LibraryActions.setLoading(false))
  }
}

function* getIniLibrary(action: ReturnType<typeof LibraryActions.getIniLibrary>) {
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const response: AxiosResponse<JSObject[]> = yield LibraryApi.getIniLibrary(action.payload, { token })
  if (response.isSuccess) {
    yield put(LibraryActions.setLibrary(Library.iniList(response.data)))
    yield put(LibraryActions.setPagingState({ hasMore: true, nextPage: 2 }))
  } else {
    yield put(LibraryActions.setError({ status: response.status, message: response.error.message }))
  }
}

function* getAddLibrary(action: ReturnType<typeof LibraryActions.getAddLibrary>) {
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const nextPage: number = yield select((state: State) => state.Library.get('nextPage'))
  action.payload = { ...action.payload, ...{ paging: { page: nextPage, size: PAGING_SIZE.lg } } }
  const response = yield LibraryApi.getAddLibrary(action.payload, { token })
  if (response.isSuccess) {
    const exists: List<Library> = yield select((state: State) => state.Library.get('media'))
    const res = Library.addList(response.data, exists)
    yield put(LibraryActions.setLibrary(res.list))
    yield put(LibraryActions.setPagingState({ hasMore: res.hasMore, nextPage: nextPage + 1 || 1 }))
  } else {
    yield put(LibraryActions.setError(response.error.message))
  }
}

function* updateStatus(action: ReturnType<typeof LibraryActions.updateStatus>) {
  const token: string = yield select((state: State) => state.Auth.getIn(['user', 'token']))
  const response = yield LibraryApi.updateStatus(action.payload, { token })
  if (response.isSuccess) {
    const conditions: LibraryConditions = yield select((state: State) => state.Library.get('conditions'))
    yield put(
      LibraryActions.getIniLibrary({
        ...formatConditions(conditions),
        ...{ paging: { page: 1, size: PAGING_SIZE.lg } }
      })
    )
    yield put(LibraryActions.setSucceeded(true))
  } else {
    yield put(LibraryActions.setError({ status: response.status, message: response.error.message }))
  }
}

/** watcher **/
export function* watchRegisterPostMedia() {
  yield takeLatest(LibraryActions.registerMedia, registerMedia)
}
export function* watchGetIniLibrary() {
  yield takeLatest(LibraryActions.getIniLibrary, getIniLibrary)
}
export function* watchGetAddLibrary() {
  yield takeLatest(LibraryActions.getAddLibrary, getAddLibrary)
}
export function* watchUpdateStatus() {
  yield takeLatest(LibraryActions.updateStatus, updateStatus)
}

/** saga **/
function* librarySaga(): Generator<AllEffect<ForkEffect<void>>> {
  yield all([fork(watchRegisterPostMedia), fork(watchGetIniLibrary), fork(watchGetAddLibrary), fork(watchUpdateStatus)])
}

export default librarySaga
