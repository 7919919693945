import { useCallback } from 'react'
import { QueryKey, useQueryClient } from 'react-query'
import { QUERY_KEYS } from './queryKeys'

export function useWrapperQueryClient(): {
  invalidateQueries: (k: QUERY_KEYS, deps?: QueryKey) => void
  getQueryData<TData = unknown>(k: QUERY_KEYS, deps?: QueryKey): TData | undefined
  isFetching(k: QUERY_KEYS, deps?: QueryKey): number
} {
  const queryClient = useQueryClient()
  const invalidateQueries = useCallback(
    (key, deps) => {
      const k = Array.isArray(deps) ? [key, ...deps] : [key]
      queryClient.invalidateQueries(k)
    },
    [queryClient]
  )
  const getQueryData = useCallback(
    (key, deps) => {
      const k = Array.isArray(deps) ? [key, ...deps] : [key]
      return queryClient.getQueryData(k)
    },
    [queryClient]
  )

  const isFetching = useCallback(
    (key, deps) => {
      const k = Array.isArray(deps) ? [key, ...deps] : [key]
      return queryClient.isFetching({ queryKey: k })
    },
    [queryClient]
  )
  return { invalidateQueries, getQueryData, isFetching }
}
