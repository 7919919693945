import { Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { AuthActions } from '../actions/Auth'
import { ForgetPassword, Invitation, ResetPassword, Signed } from '../models/AuthInfo'
import { TResponseCommon } from 'src/modules/entity/API'

type TAuthState = TResponseCommon & {
  user: Signed
  invite: Invitation
  forgetPassword: ForgetPassword
  resetPassword: ResetPassword
  redirect: string
  brandRefresh: boolean
}
export class AuthState extends Record<TAuthState>({
  user: new Signed(),
  invite: new Invitation(),
  forgetPassword: new ForgetPassword(),
  resetPassword: new ResetPassword(),
  redirect: '',
  brandRefresh: false,
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const AuthReducer = reducerWithInitialState(new AuthState())
  .case(AuthActions.initializeAuth, (state, payload) => new AuthState())

  .cases(
    [
      AuthActions.getInvite,
      AuthActions.getSignOut,
      AuthActions.getSentForgetPasswordMail,
      AuthActions.getSentResetPasswordOrder
    ],
    (state, payload) => state.withMutations(s => s.set('error', null))
  )
  .case(AuthActions.getSignUp, (state, payload) => state.withMutations(s => s.set('error', null).set('loading', true)))
  .case(AuthActions.getSigned, (state, payload) => state.withMutations(s => s.set('error', null).set('loading', true)))
  .case(AuthActions.getChangeBrand, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )

  .case(AuthActions.setSigned, (state, payload) =>
    state.withMutations(s =>
      s
        .set('user', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(AuthActions.setBrand, (state, payload) => state.withMutations(s => s.setIn(['user', 'brand'], payload)))
  .case(AuthActions.setInvite, (state, payload) =>
    state.withMutations(s =>
      s
        .set('invite', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(AuthActions.setError, (state, payload) =>
    state.withMutations(s =>
      s
        .set('error', payload)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(AuthActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))
  .case(AuthActions.setResetPassword, (state, payload) =>
    state.withMutations(s =>
      s
        .set('resetPassword', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(AuthActions.setRedirect, (state, payload) =>
    state.withMutations(s =>
      s
        .set('redirect', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(AuthActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(AuthActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
export default AuthReducer
