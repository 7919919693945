import { CanvaButtonApi } from 'types/canva'

export let CanvaApi: CanvaButtonApi | undefined = undefined

export const initializeCanva = () => {
  if (window.Canva && window.Canva.DesignButton) {
    window.Canva.DesignButton.initialize({
      // eslint-disable-next-line no-undef
      apiKey: process.env.REACT_APP_CANVA_API_KEY
    })
      .then((api: CanvaButtonApi) => {
        CanvaApi = api
      })
      .catch((e: Error) => console.error('Error on canva initialize: ', e))
  } else {
    console.error('Error on canva initialize desing button')
  }
}
