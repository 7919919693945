import { VerifyUrls, Federate, FederateFacebook, TFederateOrder } from '../models/OauthInfo'
import actionCreatorFactory from 'typescript-fsa'
import { TError } from 'src/modules/entity/API'
const actionCreator = actionCreatorFactory('OAUTH')

/***
 * get for saga
 * set for reducer
 */
export const OauthActions = {
  initializeOauth: actionCreator('INITIALIZE_OAUTH'),

  getVerifyUrls: actionCreator('GET_VERIFY_URLS'),
  getFederate: actionCreator<TFederateOrder>('GET_FEDERATE'),
  getFederateFacebook: actionCreator<TFederateOrder>('GET_FEDERATE_FACEBOOK'),
  getFederateSecond: actionCreator<TFederateOrder>('GET_FEDERATE_SECOND'),

  setVerifyUrls: actionCreator<VerifyUrls>('SET_VERIFY_URLS'),
  setFederate: actionCreator<Federate>('SET_FEDERATE'),
  setFederateFacebook: actionCreator<FederateFacebook>('SET_FEDERATE_FACEBOOK'),
  setFederateSecond: actionCreator<Federate>('SET_FEDERATE_SECOND'), // NOIN_twitter
  setFederateClear: actionCreator<any>('SET_FEDERATE_CLEAR'),

  setError: actionCreator<TError>('SET_OAUTH_ERROR'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
