import { List, Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ApprovalActions } from '../actions/Approval'
import { RegisterApprovalResult, TApprovalSetting, TApprovalSnsAccount, TApprovalStatus } from '../models/ApprovalInfo'
import { TResponseCommon } from 'src/modules/entity/API'

export type TApprovalState = TResponseCommon & {
  snsAccounts: List<TApprovalSnsAccount>
  settings: List<TApprovalSetting>
  status: List<TApprovalStatus>
  register: RegisterApprovalResult
  refresh: boolean
}
export class ApprovalState extends Record<TApprovalState>({
  snsAccounts: List(),
  settings: List(),
  status: List(),
  register: new RegisterApprovalResult(),
  loading: false,
  error: null,
  succeeded: false,
  refresh: false,
  notified: true
}) {}

const ApprovalReducer = reducerWithInitialState(new ApprovalState())
  .case(ApprovalActions.initializeApproval, (state, payload) => new ApprovalState())
  .case(ApprovalActions.setIniApproval, (state, payload) =>
    state.withMutations(s =>
      s
        .set('snsAccounts', payload.snsAccounts)
        .set('settings', payload.settings)
        .set('status', payload.status)
        .set('error', null)
    )
  )
  .case(ApprovalActions.setError, (state, payload) =>
    state.withMutations(s =>
      s
        .set('error', payload)
        .set('notified', false)
        .set('loading', false)
    )
  )
  .case(ApprovalActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('notified', false)
        .set('loading', false)
    )
  )
  .case(ApprovalActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
  .case(ApprovalActions.setLoading, (state, payload) => state.withMutations(s => s.set('loading', payload)))
export default ApprovalReducer
