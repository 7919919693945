import { ApiClient } from 'src/redux/api/apiClient'
import { JSObject } from 'types/common'
import { AxiosResponse } from 'types/axios'
import { TApiHeadersCommon } from 'src/modules/entity/API'
const apiClient = new ApiClient()

export class CommonApi {
  static post(path: string, params: JSObject, headers?: TApiHeadersCommon): Promise<any> {
    return apiClient.post(path, params, headers)
  }
  static get<T = any>(path: string, params: JSObject, headers?: TApiHeadersCommon): Promise<AxiosResponse<T>> {
    return apiClient.get<T>(path, params, headers)
  }
  static delete(path: string, params: JSObject, headers?: TApiHeadersCommon): Promise<any> {
    return apiClient.delete(path, params, headers)
  }
  static postForm(path: string, form: FormData, headers?: TApiHeadersCommon): Promise<any> {
    return apiClient.postForm(path, form, headers)
  }
}
