import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { TPostCommentOrder, TRegisterPostCommentOrder } from 'src/redux/models/PostCommentInfo'
import {
  TFinishEditPostOrder,
  TPostsOrder,
  TRegisterPostOrder,
  TUpdatePostStatusOrder
} from 'src/redux/models/PostInfo'

export class PostApi extends CommonApi {
  /*** post ***/
  static registerPost = (params: TRegisterPostOrder, headers: TApiHeadersCommon) =>
    PostApi.post('/posts', params, headers)
  static getPosts = (params: TPostsOrder, headers: TApiHeadersCommon) => PostApi.get('/posts', params, headers)
  static getPost = (params: TPostsOrder, headers: TApiHeadersCommon) => PostApi.get('/posts', params, headers)
  static finishEditPost = (params: TFinishEditPostOrder, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/finish/edit', params, headers)
  static updatePostStatus = (params: TUpdatePostStatusOrder, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/finish/publish', params, headers)

  /*** comment ***/
  static registerPostComment = (params: TRegisterPostCommentOrder, headers: TApiHeadersCommon) =>
    PostApi.post('/posts/comments', params, headers)
  static getPostComments = (params: TPostCommentOrder, headers: TApiHeadersCommon) =>
    PostApi.get('/posts/comments', params, headers)
}
