import { useMutationWrapper } from 'src/modules/core/mutation'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  InjectionMutationVariables,
  InjectionPostsQueryVariables,
  InjectionQuery,
  InjectionQueryVariables, InjectionReelMutationVariables,
  InjectionsCsvUploadMutationVariables,
  InjectionStoriesMutationVariables
} from 'src/modules/entity/injection'
import { CommonApi } from 'src/redux/api/commonApi'
import { useQueryWrapper } from '../core/query'

export class InjectionApi extends CommonApi {
  static register = (params: InjectionMutationVariables, headers?: TApiHeadersCommon) =>
    InjectionApi.post('/injection/posts', params, headers)
  static injections = (params: InjectionQueryVariables, headers?: TApiHeadersCommon) =>
    InjectionApi.get('/injection/', params, headers)
  static postInjections = (params: InjectionPostsQueryVariables, headers?: TApiHeadersCommon) =>
    InjectionApi.get('/injection/posts', params, headers)

  static storiesInjections = (params: InjectionStoriesMutationVariables, headers?: TApiHeadersCommon) =>
    InjectionApi.post('/injection/stories', params, headers)
  static reelInjections = (params: InjectionReelMutationVariables, headers?: TApiHeadersCommon) =>
    InjectionApi.post('/injection/reels', params, headers)

  static uploadCsv = (params: InjectionsCsvUploadMutationVariables, headers: TApiHeadersCommon) => {
    const formData = new FormData()
    formData.append('upload', params.upload)
    return InjectionApi.postForm(`/upload/injectional-metrics-for-posts/${params.mId}`, formData, headers)
  }
  static uploadStoriesCsv = (params: InjectionsCsvUploadMutationVariables, headers: TApiHeadersCommon) => {
    const formData = new FormData()
    formData.append('upload', params.upload)
    return InjectionApi.postForm(`/upload/injectional-metrics-for-stories/${params.mId}`, formData, headers)
  }
}

export const useInjectionsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<InjectionPostsQueryVariables>): ReactQueryResult<InjectionQuery[]> => {
  return useQueryWrapper<InjectionQuery[]>({
    queryKey: 'injection',
    deps: key,
    req: ({ token }) => InjectionApi.postInjections(payload, { token }),
    options
  })
}

export const useInjectionRegisterMutation = () => {
  return useMutationWrapper<InjectionMutationVariables>({
    req: (payload, { token }) => InjectionApi.register(payload, { token })
  })
}

export const useInjectionStoriesRegisterMutation = () => {
  return useMutationWrapper<InjectionStoriesMutationVariables>({
    req: (payload, { token }) => InjectionApi.storiesInjections(payload, { token })
  })
}

export const useInjectionReelRegisterMutation = () => {
  return useMutationWrapper<InjectionReelMutationVariables>({
    req: (payload, { token }) => InjectionApi.reelInjections(payload, { token })
  })
}

export const useInjectionsCsvUploadMutation = () => {
  return useMutationWrapper<InjectionsCsvUploadMutationVariables>({
    req: (payload, { token }) => InjectionApi.uploadCsv(payload, { token })
  })
}

export const useInjectionsStoriesCsvUploadMutation = () => {
  return useMutationWrapper<InjectionsCsvUploadMutationVariables>({
    req: (payload, { token }) => InjectionApi.uploadStoriesCsv(payload, { token })
  })
}
