import { ApiClient } from './apiClient'
import { JSObject } from '../../../types/common'
import { TInvitationOrder, TSignInOrder, TSignUpOrder } from '../models/AuthInfo'
import { TApiHeadersCommon } from 'src/modules/entity/API'

const apiClient = new ApiClient()

export class SignApi {
  static post(path: string, params: JSObject, headers?: TApiHeadersCommon): Promise<{}> {
    return apiClient.post(path, params, headers)
  }
  static signUp = (params: TSignUpOrder) => SignApi.post('/sign/up', params)
  static signIn = (params: TSignInOrder) => SignApi.post('/sign/in', params)
  static invite = (params: TInvitationOrder, headers: TApiHeadersCommon) =>
    SignApi.post('/sign/invitation', params, headers)
  static changeBrand = (params: JSObject, headers: TApiHeadersCommon) =>
    SignApi.post('sign/changebrand', params, headers)
  static sentForgetPasswordMail = (params: JSObject) => SignApi.post('/sign/forgetpassword', params)
  static sentResetPasswordOrder = (params: JSObject) => SignApi.post('/sign/passwordreset', params)
}
