import React from 'react'
import { TNotification } from 'src/components/Toast/ToastComponent'
import { genMessage, genErrorCode } from 'src/modules/core/errors'
import { Button } from 'src/stories/atoms/Button'
import { useLocation } from 'react-router-dom'
import { useCopyText } from 'src/hooks/useCopyText'

type Props = {
  notification: TNotification
}

export const ErrorMessage: React.VFC<Props> = ({ notification }) => {
  const { copyText } = useCopyText()
  const { status, message } = notification
  const errorMessage = genMessage(status, message)
  const errorCodes = genErrorCode(status)
  const location = useLocation()
  const errorContent = `エラーコード: ${errorCodes}\nパス: ${location.pathname}${location.search}`
  const handleCopyError = React.useCallback(
    text => {
      copyText(text)
    },
    [copyText]
  )
  return (
    <>
      <p style={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</p>
      <h6>エラー詳細</h6>
      <textarea
        rows={4}
        disabled
        className="bg-white w-100 text-muted"
        style={{ whiteSpace: 'pre', fontSize: '0.8rem' }}
        defaultValue={errorContent}
      >
      </textarea>
      <div className="d-flex justify-content-end">
        <Button isSmall onClick={() => handleCopyError(errorContent)}>
          エラー詳細をコピーする
        </Button>
      </div>
    </>
  )
}
