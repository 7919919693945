export type CopyText = {
  copyText: (text: string) => void
}
export const useCopyText = (): CopyText => {
  const copyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert('クリップボードにコピーしました'))
      .catch(() => {
        alert('クリップボードにコピーできませんでした')
      })
  }
  return { copyText }
}
