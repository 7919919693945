import jwt_decode from 'jwt-decode'
import { Cookies } from 'react-cookie'
import { TSigned } from 'src/redux/models/AuthInfo'

declare global {
  interface Window {
    hsConversationsSettings: {
      loadImmediately?: boolean
      identificationEmail?: string
      identificationToken?: string
    }
    hsConversationsOnReady: (() => void)[]
    HubSpotConversations: {
      clear(arg?: { resetWidget: boolean }): void
      resetAndReloadWidget(): void
      widget: {
        status(): { loaded: boolean }
        load(): void
        remove(): void
        refresh(): void
      }
    }
  }
}

const getCookiesOpt = (name: string) => {
  if (name === 'oauth') {
    return { path: '/' }
  }
  return { path: '/', secure: false, sameSite: true }
}

export const isUserAuthenticated = (user: TSigned): boolean => {
  if (!user || !user.token) return false
  const decoded: any = jwt_decode(user.token)
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    console.warn('access token expired')
    return false
  } else {
    return true
  }
}

export function setHsToken(email: string, token: string) {
  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: email,
    identificationToken: token
  }
  if (window.HubSpotConversations) window.HubSpotConversations.resetAndReloadWidget()
  else window.hsConversationsOnReady = [() => window.HubSpotConversations.widget.load()]
}

export function delHsToken() {
  window.hsConversationsSettings = {
    loadImmediately: false
  }
  const clear = () => {
    window.HubSpotConversations.clear()
    window.HubSpotConversations.widget.remove()
  }
  if (window.HubSpotConversations) clear()
}

/**
 * Sets the session
 * @param name
 * @param content
 */
export function setSession(name: string, content: any) {
  const cookies = new Cookies()
  if (content) cookies.set(name, JSON.stringify(content), getCookiesOpt(name))
  else cookies.remove(name, getCookiesOpt(name))
}

export function getSession(name: string) {
  const cookies = new Cookies()
  const c = cookies.get(name)
  return c
}
