import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { useQuery, UseQueryOptions, UseQueryResult, useQueries } from 'react-query'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { useQueryWrapper } from '../core/query'
import {
  FetchAccountsQuery,
  FetchAccountHistoryVariables,
  FetchAccountsHistoryVariables,
  FetchAccountsVariables,
  RemoveAccountVariables,
  RegisterAccountVariables,
  FetchAccountsGraphVariables,
  FetchAccountPostsVariables,
  FetchRivalPostsCsvVariables,
  RivalAccountsGraphQuery,
  RivalAccountPostQuery
} from 'src/modules/entity/rival'
import { ReactQueryResult, TUseQueryOptions } from 'src/modules/entity/API'
import { useToast } from 'src/components/Toast/ToastProvider'
import { useMutationWrapper } from '../core/mutation'
import { genMessage } from '../core/errors'
import { PAGING_SIZE } from 'src/constants/query'
export class RivalAPI extends CommonApi {
  static fetchAccounts = (params: FetchAccountsVariables, headers: TApiHeadersCommon) =>
    RivalAPI.get<FetchAccountsQuery>('/rivals', params, headers)
  static removeAccount = (params: RemoveAccountVariables, headers: TApiHeadersCommon) =>
    RivalAPI.delete(`/rivals/${params.id}`, params, headers)
  static registerAccount = (params: RegisterAccountVariables, headers: TApiHeadersCommon) =>
    RivalAPI.post('/rivals', params, headers)
  static fetchAccountsGraph = (params: FetchAccountsGraphVariables, headers: TApiHeadersCommon) =>
    RivalAPI.get('/rivals/graph', params, headers)
  static fetchAccountHistory = (params: FetchAccountHistoryVariables, headers: TApiHeadersCommon) =>
    RivalAPI.get(`/rivals/${params.id}/history`, params, headers)
  static fetchAccountPosts = (params: FetchAccountPostsVariables, headers: TApiHeadersCommon) =>
    RivalAPI.get(`/rivals/${params.id}/posts`, params, headers)
  static fetchRivalPostsCsv = (params: FetchRivalPostsCsvVariables, headers: TApiHeadersCommon) =>
    RivalAPI.get('rivals/csv', params, headers)
}

export const useRivalAccountHistoryQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchAccountHistoryVariables>): ReactQueryResult<any[]> => {
  return useQueryWrapper<any[]>({
    queryKey: 'rivalAccountHistory',
    deps: key,
    req: ({ token }) => RivalAPI.fetchAccountHistory(payload, { token }),
    options
  })
}

export const useRivalAccountsHistoryQuery = (
  query: FetchAccountsHistoryVariables,
  opts?: UseQueryOptions,
  key?: (string | number | boolean | {})[]
): UseQueryResult<{ data?: [] }, any> => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useQuery(
    Array.isArray(key) && ['rivalAccountsHistory', ...key],
    async () => {
      const responses = await Promise.all(
        query.rivalIds.map(rivalId => {
          return RivalAPI.fetchAccountHistory(
            { id: query.snsAccountId, history: { snsAccountRivalId: rivalId } },
            { token }
          )
        })
      )
      if (responses.every(res => res.isSuccess)) {
        return { data: responses.map(res => res.data) }
      } else {
        return new Error('useRivalAccountsHistoryQuery error')
      }
    },
    {
      ...opts
    }
  )
}

export const useRivalAccountsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchAccountsVariables>): ReactQueryResult<FetchAccountsQuery> => {
  return useQueryWrapper<FetchAccountsQuery>({
    queryKey: 'rivals',
    deps: key,
    req: ({ token }) => RivalAPI.fetchAccounts(payload, { token }),
    options
  })
}

export const useRivalAccountsGraphQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchAccountsGraphVariables>): ReactQueryResult<RivalAccountsGraphQuery> => {
  return useQueryWrapper<RivalAccountsGraphQuery>({
    queryKey: 'rivalsGraph',
    deps: key,
    req: ({ token }) => RivalAPI.fetchAccountsGraph(payload, { token }),
    options
  })
}

export const useRivalAccountsGraphQueries = (
  rivals: FetchAccountsQuery,
  daterange: { from: string; to: string }
): UseQueryResult<{ data?: RivalAccountsGraphQuery }, any>[] => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  const queries =
    rivals?.length > 0
      ? rivals?.map(e => {
          return {
            queryKey: ['rivalsGraph', { id: e.id, daterange }],
            queryFn: () =>
              RivalAPI.fetchAccountsGraph(
                {
                  graph: {
                    mIds: [e.rivalMId],
                    provider: e.provider,
                    from: daterange.from,
                    to: daterange.to
                  }
                },
                { token }
              ),
            options: {
              enabled: !!rivals && !!daterange && !!e.rivalMId
            }
          }
        })
      : []
  return useQueries([...queries])
}

export const useRivalAccountPostsQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchAccountPostsVariables>): ReactQueryResult<RivalAccountPostQuery> => {
  return useQueryWrapper<RivalAccountPostQuery>({
    queryKey: 'rivalPost',
    deps: key,
    req: ({ token }) => RivalAPI.fetchAccountPosts(payload, { token }),
    options
  })
}

export const useRivalAccountPostsQueries = (
  rivals: FetchAccountsQuery,
  daterange: { from: string; to: string }
): UseQueryResult<{ data?: RivalAccountPostQuery }, any>[] => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  const queries =
    rivals?.length > 0
      ? rivals?.map(e => {
          return {
            queryKey: ['rivalPost', { id: e.id, daterange }],
            queryFn: () =>
              RivalAPI.fetchAccountPosts(
                {
                  id: e.id,
                  posts: {
                    rivalId: e.id,
                    from: daterange.from,
                    to: daterange.to,
                    sort: ['createdTime.desc'],
                    paging: { size: PAGING_SIZE.all }
                  }
                },
                { token }
              ),
            options: {
              enabled: !!rivals && !!daterange && !!e.id
            }
          }
        })
      : []
  return useQueries([...queries])
}

export const useRegisterRivalAccountMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RegisterAccountVariables>({
    req: (payload, { token }) => RivalAPI.registerAccount(payload, { token }),
    toaster: res => {
      if (res.error || !res.data) {
        addToast({
          result: 'error',
          message: genMessage(res.status, '競合アカウントの登録に失敗しました'),
          status: res.status
        })
      }
      if (res.isSuccess && res.data) {
        addToast({ result: 'succeeded', message: '競合アカウントの登録に成功しました' })
      }
    }
  })
}

export const useRemoveRivalAccountMutation = () => {
  const { addToast } = useToast()
  return useMutationWrapper<RemoveAccountVariables>({
    req: (payload, { token }) => RivalAPI.removeAccount(payload, { token }),
    toaster: res => {
      if (res.error) {
        addToast({
          result: 'error',
          message: genMessage(res.status, '競合アカウントの削除に失敗しました'),
          status: res.status
        })
      }
      if (res.isSuccess && res.data) {
        addToast({ result: 'succeeded', message: '競合アカウントの削除に成功しました' })
      }
    }
  })
}

export const useRivalPostsCsvQuery = ({ payload, key, options }: TUseQueryOptions<FetchRivalPostsCsvVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'rivalPostsCsv',
    deps: key,
    req: ({ token }) => RivalAPI.fetchRivalPostsCsv(payload, { token }),
    options
  })
}
