import { useMutation } from 'react-query'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  UgcSettingResponse,
  UgcSettingsVariables,
  UgcDetailVariables,
  UgcUpdateStatusVariables,
  UgcFetchMediaVariables,
  UgcMedia,
  UgcRemoveVariables
} from 'src/modules/entity/ugc'
import { CommonApi } from 'src/redux/api/commonApi'
import { State } from 'src/redux/reducers'
import { UgcMediaCsvMutationVariables, UgcRegisterSettingsVariables } from '../entity/ugc'

export class UgcApi extends CommonApi {
  static registerSettings = (params: UgcRegisterSettingsVariables, headers: TApiHeadersCommon) =>
    UgcApi.post('/ugc_settings', params, headers)
  static fetchDetail = (params: UgcDetailVariables, headers: TApiHeadersCommon) =>
    UgcApi.get(`/ugc_settings/${params.id}`, {}, headers)
  static getSettings = (params: UgcSettingsVariables, headers: TApiHeadersCommon) =>
    UgcApi.get('/ugc_settings', params, headers)
  static fetchMedia = (params: UgcFetchMediaVariables, headers: TApiHeadersCommon) =>
    UgcApi.get('/ugc_medias', params, headers)
  static remove = (params: UgcRemoveVariables, headers: TApiHeadersCommon) =>
    UgcApi.post('/ugc_settings/remove', params, headers)
  static updateStatus = (params: UgcUpdateStatusVariables, headers: TApiHeadersCommon) =>
    UgcApi.post('/ugc_settings/status', params, headers)
  static csv = (params: UgcMediaCsvMutationVariables, headers: TApiHeadersCommon) =>
    UgcApi.get('/ugc_medias/csv', params, headers)
}

export const useFetchUgcDetail = ({
  payload,
  key,
  options
}: TUseQueryOptions<UgcDetailVariables>): ReactQueryResult<UgcSettingResponse> => {
  return useQueryWrapper({
    queryKey: 'ugcDetail',
    deps: key,
    options,
    req: ({ token }) => UgcApi.fetchDetail(payload, { token })
  })
}
export const useFetchUgcSettings = ({
  payload,
  key,
  options
}: TUseQueryOptions<UgcSettingsVariables>): ReactQueryResult<UgcSettingResponse[]> => {
  return useQueryWrapper({
    queryKey: 'ugcSettings',
    deps: key,
    options,
    req: ({ token }) => UgcApi.getSettings(payload, { token })
  })
}
export const useFetchUgcMedias = ({
  payload,
  key,
  options
}: TUseQueryOptions<UgcFetchMediaVariables>): ReactQueryResult<UgcMedia[]> => {
  return useQueryWrapper({
    queryKey: 'ugcMedias',
    deps: key,
    options,
    req: ({ token }) => UgcApi.fetchMedia(payload, { token })
  })
}

export const useRegisterUgcSettingsMutation = () => {
  return useMutationWrapper<UgcRegisterSettingsVariables>({
    req: (payload, { token }) => UgcApi.registerSettings(payload, { token })
  })
}
export const useUpdateUgcStatusMutation = () => {
  return useMutationWrapper<UgcUpdateStatusVariables>({
    req: (payload, { token }) => UgcApi.updateStatus(payload, { token })
  })
}
export const useRemoveUgcMutation = () => {
  return useMutationWrapper<UgcRemoveVariables>({
    req: (payload, { token }) => UgcApi.remove(payload, { token })
  })
}
export const useUgcCsvMutation = () => {
  const token: string = useShallowEqualSelector((state: State) => state.Auth.getIn(['user', 'token']))
  return useMutation((payload: UgcMediaCsvMutationVariables) => UgcApi.csv(payload, { token }))
}
