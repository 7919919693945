import { useParams } from 'react-router-dom'
import {
  useDeletePostCommentReactionMutation,
  usePostCommentReactionsQuery,
  usePostCommentsQuery,
  usePostQuery,
  useRegisterPostCommentMutation,
  useRegisterPostCommentReactionMutation
} from 'src/hooks/domain'
import { useCatalogProductsQuery } from "../../../modules/repositories/commerce";

type UsePostOption = {
  refetchInterval?: number
}
const usePost = ({ refetchInterval }: UsePostOption) => {
  const { postId } = useParams<{ postId: string }>()
  const { data: post, isLoading, refetch } = usePostQuery({
    payload: { id: postId },
    key: [{ postId }],
    options: {
      refetchInterval,
      enabled: !!postId
    }
  })
  return { post: post?.data, isLoading, refetch }
}
export { usePost }
export { useRegisterPostCommentMutation }
export { useRegisterPostCommentReactionMutation }
export { useDeletePostCommentReactionMutation }

export const usePostComments = (snsAccountId: number) => {
  const { postId } = useParams<{ mId: string; postId: string }>()
  const { data: postComments, isLoading, refetch } = usePostCommentsQuery({
    payload: {
      postComment: {
        snsAccountId,
        postId: +postId
      },
      paging: {
        size: 0
      },
      sort: ['id.asc']
    },
    options: { enabled: !!snsAccountId }
  })
  return { postComments: postComments?.data, isLoading, refetch }
}

export const usePostCommentReactions = (postId: number) => {
  const { data: postCommentReactions, isLoading, refetch } = usePostCommentReactionsQuery({
    payload: {
      postCommentReaction: {
        postId
      },
      paging: {
        size: 0
      }
    },
    options: { enabled: !!postId }
  })
  return { postCommentReactions: postCommentReactions?.data, isLoading, refetch }
}

export const useCommerceCatalogProducts = (mId: string) => {
  const { data, isLoading } = useCatalogProductsQuery({payload: { mId }, key: [mId]})
  return { catalogProducts: data?.data || [], isLoading }
}