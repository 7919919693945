import { TSnses } from 'src/modules/entity/API'
import { JSObject } from '../../../types/common'
import { List, Record } from 'immutable'
import isEmpty from 'lodash/isEmpty'

/*** order models ***/
export type TFederateOrder = {
  code: string
  hash: string
  oauthToken?: string
}
// export type TErrorPayload = { error: string }

/*** store models ***/
type TVerifyUrl = { authUrl: string; hash: string }
export class VerifyUrl extends Record<TVerifyUrl>({
  authUrl: '',
  hash: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new VerifyUrl(params)
  }
}

type TVerifyUrls = {
  instagram: TVerifyUrl
  twitter: TVerifyUrl
  facebook: TVerifyUrl
  tiktok: TVerifyUrl
}
export class VerifyUrls extends Record<TVerifyUrls>({
  instagram: new VerifyUrl(),
  twitter: new VerifyUrl(),
  facebook: new VerifyUrl(),
  tiktok: new VerifyUrl()
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    const data = {
      instagram: VerifyUrl.fromResponse(params.instagram),
      twitter: VerifyUrl.fromResponse(params.twitter),
      facebook: VerifyUrl.fromResponse(params.facebook),
      tiktok: VerifyUrl.fromResponse(params.tiktok)
    }
    return new VerifyUrls(data)
  }
}

export type TAccount = {
  provider: string
  mId: string
  uniqueId: string
  nickname: string
  accessToken: string
  refreshToken: string
  tokenSecret: string
  status: number
}
export class Account extends Record<TAccount>({
  provider: '',
  mId: '',
  uniqueId: '',
  nickname: '',
  accessToken: '',
  refreshToken: '',
  tokenSecret: '',
  status: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Account(params)
  }
}

type TAccountDetail = { provider: string; mId: string; url: string }
export class AccountDetail extends Record<TAccountDetail>({
  provider: '',
  mId: '',
  url: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new AccountDetail(params)
  }
  static iniList(params: JSObject[]): List<AccountDetail> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => AccountDetail.fromResponse(x)))
  }
}

type TAppAccount = {
  status: number
}
export class AppAccount extends Record<TAppAccount>({
  status: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new AppAccount(params)
  }
}

export type TAccountMigrations = {
  mId: string
  oldMId: string
  oldAccountId: number
  // id: null,
  // accountId: null,
  // createUserId: null,
  // updateUserId: null
}
export class AccountMigrations extends Record<TAccountMigrations>({
  mId: '',
  oldMId: '',
  oldAccountId: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new AccountMigrations(params)
  }
  static iniList(params: JSObject[]): List<AccountMigrations> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => AccountMigrations.fromResponse(x)))
  }
}

type TFederate = {
  accountId: number
  // instagramInsightMedia: [] // 返ってくる？
  // instagramInsightUser: []
  mId: string
  nickname: string
  provider: TSnses
  // twitterInsightMedia: []
  // twitterInsightUser: []
  uniqueId: string
}
export class Federate extends Record<TFederate>({
  accountId: 0,
  // instagramInsightMedia: [],
  // instagramInsightUser: [],
  mId: '',
  nickname: '',
  provider: '',
  // twitterInsightMedia: [],
  // twitterInsightUser: [],
  uniqueId: ''
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new Federate(params)
  }
}

type TFederateFacebook = {
  hash: string
  pages: {
    id: string
    name: string
    pictureUrl: string
  }[]
}
export class FederateFacebook extends Record<TFederateFacebook>({
  hash: '',
  pages: []
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new FederateFacebook(params)
  }
}
