import React, { FC } from 'react'

/**
 * Renders the preloader
 */
const Loader: FC = () => {
  return (
    <div className={'preloader'}>
      <div className={'status'}>
        <div className={'spinner-border avatar-sm text-primary'} role="status" />
      </div>
    </div>
  )
}

export default Loader
