import { FetchInsightMediaCsvVariables, FetchInsightQuery, FetchInsightVariables } from 'src/modules/entity/insight'
import { CommonApi } from 'src/redux/api/commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TUseQueryOptions } from 'src/modules/entity/API'

class InsgihtApi extends CommonApi {
  static fetch = (params: FetchInsightVariables, headers: TApiHeadersCommon) =>
    InsgihtApi.get<FetchInsightQuery>('/insight', params, headers)
  static fetchMediaCsv = (params: FetchInsightMediaCsvVariables, headers: TApiHeadersCommon) =>
    InsgihtApi.get('/insight/medias/csv', params, headers)
}

export const useInsightQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<FetchInsightVariables>): ReactQueryResult<FetchInsightQuery> => {
  return useQueryWrapper<FetchInsightQuery>({
    queryKey: 'insight',
    deps: key,
    req: ({ token }) => InsgihtApi.fetch(payload, { token }),
    options
  })
}

export const useInsightMediaCsvQuery = ({ payload, key, options }: TUseQueryOptions<FetchInsightVariables>) => {
  return useQueryWrapper<string>({
    queryKey: 'insightMediaCsv',
    deps: key,
    req: ({ token }) => InsgihtApi.fetchMediaCsv(payload, { token }),
    options
  })
}
