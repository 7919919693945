import { TSnses } from 'src/modules/entity/API'
import { JSObject } from '../../../types/common'
import { List, Record } from 'immutable'
import isEmpty from 'lodash/isEmpty'

/*** register models ***/
export type TAutoPublishSettingOrder = {
  snsAccount: {
    mId: string
    isAutoPublish: boolean
  }
}

/*** store models ***/
export type TSnsAccount = {
  id: number
  provider: TSnses
  accountId: number
  mId: string
  status: number
  isBusiness: number
  brandId: number
}
export class SnsAccount extends Record<TSnsAccount>({
  id: 0,
  provider: '',
  accountId: 0,
  mId: '',
  status: 0,
  isBusiness: 0,
  brandId: 0
}) {
  static fromResponse(response: JSObject) {
    const params = { ...response }
    return new SnsAccount(params)
  }
  static iniList(params: JSObject[]): List<SnsAccount> {
    if (isEmpty(params)) {
      return List()
    }
    return List(params.map(x => SnsAccount.fromResponse(x)))
  }
}
