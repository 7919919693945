import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { TSnses } from 'src/modules/entity/API'
import { isRegExp } from 'src/util/regexp'
import { isString } from 'src/util/string'

/**
 * URLを生成する
 * hashtag や user にはそれぞれ具体的なタグ名、ユーザ名を入れる
 * どれか1つのみを渡し、他は falsy な値を入れる
 *
 * @param provider string
 * @param hashtag string
 * @param user string uniqueId を設定する
 * @param mediaId string
 * @param url
 */
type GetLink = {
  provider?: TSnses
  hashtag?: string
  user?: string
  mediaId?: string
  url?: string
  mId?: string
}
export const getLink = ({ provider, hashtag = '', user = '', mediaId = '', url = '', mId = '' }: GetLink): string => {
  const linkUser = user ? user.replace(/^@/, '') : ''
  const linkHashtag = hashtag ? hashtag.replace(/^#/, '') : ''
  let link: string = url ? url : '#'
  switch (provider) {
    case 'instagram':
      if (hashtag) link = `https://www.instagram.com/explore/tags/${linkHashtag}/`
      if (user) link = `https://www.instagram.com/${linkUser}/`
      break
    case 'twitter':
      if (hashtag) link = `https://twitter.com/hashtag/${linkHashtag}`
      if (!mediaId && user) link = `https://twitter.com/${linkUser}`
      if (mediaId && user) link = `https://twitter.com/${linkUser}/status/${mediaId}`
      break
    case 'facebook':
      if (hashtag) link = `https://www.facebook.com/hashtag/${linkHashtag}`
      if (user) link = `https://www.facebook.com/${linkUser}`
      if (mId) link = `https://www.facebook.com/${mId}`
      break
  }
  return link
}

export const sliceText = (text: any, length: number) => {
  if (isEmpty(text)) return ''
  const textArray = [...text]
  if (length > size(textArray)) {
    return text
  } else {
    return textArray.slice(0, -(length - size(textArray))).join('') + '...'
  }
}
type OmitText = { text: string; maxLength?: number }
export const omitText = ({ text, maxLength = 140 }: OmitText) =>
  text?.length > maxLength ? text.substr(0, maxLength) + '...' : text

export const round = (number: number, precision: number) => {
  const shift = (number: string | number, precision: number, reverseShift: boolean) => {
    if (reverseShift) {
      precision = -precision
    }
    const numArray = ('' + number).split('e')
    return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision))
  }
  return shift(Math.round(shift(number, precision, false)), precision, true)
}

export const escapeRegExp = (str: string) => {
  str.replace(/[/\\^$.*+?()[\]{}|]/g, '\\$&')
  return str
}

export const isValidCond = (cond: boolean) => (message?: string) => (cond ? message : false)

/**
 * Given a string, replace every substring that is matched by the `match` regex
 * with the result of calling `fn` on matched substring. The result will be an
 * array with all odd indexed elements containing the replacements. The primary
 * use case is similar to using String.prototype.replace except for React.
 *
 * React will happily render an array as children of a react element, which
 * makes this approach very useful for tasks like surrounding certain text
 * within a string with react elements.
 *
 * Example:
 * matchReplace(
 *   'Emphasize all phone numbers like 884-555-4443.',
 *   /([\d|-]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
 *
 * @param {string} str
 * @param {regexp|str} match Must contain a matching group
 * @param {function} fn
 * @return {array}
 */
const replaceString = (str: string | React.ReactNode, match: RegExp | string, fn: Function): Array<any> => {
  let curCharStart = 0
  let curCharLen = 0

  if (str === '') {
    return ['']
  } else if (!str || !isString(str) || typeof str !== 'string') {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string')
  }

  let re = match

  if (!isRegExp(re) && typeof re === 'string') {
    re = new RegExp('(' + escapeRegExp(re) + ')', 'gi')
  }

  let result: string[] | React.ReactElement[] = str.split(re)

  // Apply fn to all odd elements
  for (let i = 0, length = result.length; i < length; i += 1) {
    curCharLen = result[i].length
    curCharStart += result[i - 1]?.length || 1
    result[i] = fn(result[i], i, curCharStart)
    curCharStart += curCharLen
  }

  return result
}

export const reactStringReplace = (
  source: string[] | React.ReactNode[],
  regex: string | RegExp,
  fn: (match: string, index: number, offset: number) => React.ReactNode
): Array<string | React.ReactElement> => {
  const sourceArray = !Array.isArray(source) ? [source] : source
  return sourceArray.map<any>(x => (isString(x) ? replaceString(x, regex, fn) : x))
}
