import { CheckType, TodoSelectOptions } from 'src/modules/entity/approval'

export const CHECKBOX: CheckType = 'CHECKBOX'
export const INPUT: CheckType = 'INPUT'

export const TODO_CHECK_OPTIONS = [
  { value: CHECKBOX, label: 'チェック' },
  { value: INPUT, label: '文字' }
]

export const CHECKED: TodoSelectOptions = 'checked'
export const UNCHECKED: TodoSelectOptions = 'unchecked'
export const UNNECESSARY: TodoSelectOptions = 'unnecessary'

export const TODO_CHECKBOX_OPTIONS: { value: TodoSelectOptions; label: string }[] = [
  { value: CHECKED, label: '○' },
  { value: UNNECESSARY, label: '-' },
  { value: UNCHECKED, label: ' ' }
]
