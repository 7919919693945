import actionCreatorFactory from 'typescript-fsa'
import { DateRange, TDateRange } from 'src/redux/models/DaterangeInfo'
import { TError } from 'src/modules/entity/API'
const actionCreator = actionCreatorFactory('DATERANGE')

/***
 * get for saga
 * set for reducer
 */
export const DaterangeActions = {
  initializeDateRange: actionCreator('INITIALIZE_DATE_RANGE'),

  getDateRange: actionCreator<TDateRange>('GET_DATE_RANGE'),
  setDateRange: actionCreator<DateRange>('SET_DATE_RANGE'),

  setError: actionCreator<TError>('SET_ERROR'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
