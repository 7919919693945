import { TRoleType } from 'src/redux/models/AuthInfo'

export type TRoleTypeOptions = { roleType: string; name: string; label: string }
export const ROLE: TRoleTypeOptions[] = [
  { roleType: '10', name: 'system', label: 'システム権限' },
  { roleType: '20', name: 'owner', label: 'オーナー' },
  { roleType: '30', name: 'manager', label: 'マネージャー' },
  { roleType: '40', name: 'member', label: 'メンバー' }
]

type TUserBrandRoleType = {
  system: TRoleType
  owner: TRoleType
  manager: TRoleType
  member: TRoleType
}
export const userBrandRoleType: TUserBrandRoleType = {
  system: 10,
  owner: 20,
  manager: 30,
  member: 40
}

export const USER_ROLE_OPTIONS = [
  { label: '管理者', value: userBrandRoleType.system },
  { label: 'オーナー', value: userBrandRoleType.owner },
  { label: 'マネージャー', value: userBrandRoleType.manager },
  { label: 'メンバー', value: userBrandRoleType.member }
]

export const MAIL_SETTING_OPTIONS = [
  //valueがbooleanでは React-hook-formにおいてReact-Selectが機能不全になるため、歪だがvalueに文字列を設定
  { label: 'ON', value: 'true' },
  { label: 'OFF', value: 'false' }
]
