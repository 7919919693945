import { useMutationWrapper } from 'src/modules/core/mutation'
import { useQueryWrapper } from 'src/modules/core/query'
import { ReactQueryResult, TApiHeadersCommon, TUseQueryOptions } from 'src/modules/entity/API'
import {
  AutoPublishSettingVariables,
  GetSnsAccountTokenDebugVariables,
  SnsAccountTokenDebugQuery,
  RegisterExternalEmailVariables,
  DeleteExternalEmailVariables,
  GetExternalEmailVariables,
  SnsAccountExternalEmailQuery,
  RegisterPostCaptionRulesVariables,
  DeletePostCaptionRuleVariables,
  GetPostCaptionRulesVariables,
  SnsAccountPostCaptionRulesQuery
} from 'src/modules/entity/snsAccounts'
import { CommonApi } from 'src/redux/api/commonApi'

export class SnsAccountsApi extends CommonApi {
  static autoPublishSetting = (params: AutoPublishSettingVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.post('/sns_accounts/autopublish', params, headers)
  static tokenDebug = (params: GetSnsAccountTokenDebugVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.get('/sns_accounts/token-debug', params, headers)
  static registerExternalEmail = (params: RegisterExternalEmailVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.post('/sns_accounts/external-email', params, headers)
  static deleteExternalEmail = (params: DeleteExternalEmailVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.delete(`/sns_accounts/external-email/${params.snsAccountExternalEmailId}`, {}, headers)
  static fetchExternalEmail = (params: GetExternalEmailVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.get('/sns_accounts/external-emails', params, headers)
  static registerPostCaptionRules = (params: RegisterPostCaptionRulesVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.post('/sns_accounts/post-cation-rules', params, headers)
  static deletePostCaptionRule = (params: DeletePostCaptionRuleVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.delete(`/sns_accounts/post-cation-rule/${params.snsAccountPostCaptionRuleId}`, {}, headers)
  static fetchPostCaptionRules = (params: GetPostCaptionRulesVariables, headers: TApiHeadersCommon) =>
    SnsAccountsApi.get('/sns_accounts/post-cation-rules', params, headers)
}

export const useAutoPublishSettingMutation = () => {
  return useMutationWrapper<AutoPublishSettingVariables>({
    req: (payload, { token }) => SnsAccountsApi.autoPublishSetting(payload, { token })
  })
}

export const useTokenDebugQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetSnsAccountTokenDebugVariables>): ReactQueryResult<SnsAccountTokenDebugQuery> => {
  return useQueryWrapper<SnsAccountTokenDebugQuery>({
    deps: key,
    req: ({ token }) => SnsAccountsApi.tokenDebug(payload, { token }),
    options
  })
}

export const useRegisterExternalEmailMutation = () => {
  return useMutationWrapper<RegisterExternalEmailVariables>({
    req: (payload, { token }) => SnsAccountsApi.registerExternalEmail(payload, { token })
  })
}

export const useDeleteExternalEmailMutation = () => {
  return useMutationWrapper<DeleteExternalEmailVariables>({
    req: (payload, { token }) => SnsAccountsApi.deleteExternalEmail(payload, { token })
  })
}

export const useExternalEmailQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetExternalEmailVariables>): ReactQueryResult<SnsAccountExternalEmailQuery[]> => {
  return useQueryWrapper<SnsAccountExternalEmailQuery[]>({
    queryKey: 'externalEmails',
    deps: key,
    req: ({ token }) => SnsAccountsApi.fetchExternalEmail(payload, { token }),
    options
  })
}

export const useRegisterPostCaptionRulesMutation = () => {
  return useMutationWrapper<RegisterPostCaptionRulesVariables>({
    req: (payload, { token }) => SnsAccountsApi.registerPostCaptionRules(payload, { token })
  })
}

export const useDeletePostCaptionRuleMutation = () => {
  return useMutationWrapper<DeletePostCaptionRuleVariables>({
    req: (payload, { token }) => SnsAccountsApi.deletePostCaptionRule(payload, { token })
  })
}

export const usePostCaptionRulesQuery = ({
  payload,
  key,
  options
}: TUseQueryOptions<GetPostCaptionRulesVariables>): ReactQueryResult<SnsAccountPostCaptionRulesQuery> => {
  return useQueryWrapper<SnsAccountPostCaptionRulesQuery>({
    queryKey: 'postCaptionRules',
    deps: key,
    req: ({ token }) => SnsAccountsApi.fetchPostCaptionRules(payload, { token }),
    options
  })
}
