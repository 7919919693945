import dayjs from 'dayjs'
import { SNS } from 'src/constants/posts'
import { round } from 'src/helpers/common'
import { TSnses } from 'src/modules/entity/API'
import { TPostMediaThumbnails } from 'src/redux/models/PostMediaInfo'
import { RequireOne } from 'types/common'
import { TPostFormValuesPostMediaRelations } from 'src/redux/models/PostInfo'

/**
 * 動画 File からサムネイルを作成します。
 *
 * @param file (files.*.file)
 * @param callback
 */
let image: string
export const getThumbnail = (file: File, divider: number, fileType: string): Promise<TPostMediaThumbnails> =>
  new Promise((resolve, reject) => {
    let fileReader = new FileReader()
    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type || fileType })
      const url = URL.createObjectURL(blob)
      const video = document.createElement('video')

      const timeupdate = async () => {
        if (await snapImage()) {
          video.removeEventListener('timeupdate', timeupdate)
          video.pause()
        }
      }
      video.addEventListener('loadeddata', async () => {
        snapImage()
          .then(img => resolve({ filename: file.name, thumbnail: img }))
          .finally(() => video.removeEventListener('timeupdate', timeupdate))
      })
      const snapImage = async () => {
        let canvas = document.createElement('canvas')
        canvas.width = video.videoWidth / divider
        canvas.height = video.videoHeight / divider
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
        image = canvas.toDataURL()
        return image
      }
      video.addEventListener('timeupdate', timeupdate)
      video.preload = 'metadata'
      video.src = url
      // Load video in Safari / IE11
      video.muted = true
      video.play().catch(e => console.log('Error play video in getThumbnail ', e))
    }
    fileReader.readAsArrayBuffer(file)
  })

/**
 * 動画 File からサムネイルを作成します。
 *
 * @param file (files.*.file)
 * @param callback
 */
export const getOffsetThumbnail = (
  file: File,
  divider: number,
  fileType: string,
  offsetMs: number = 0
): Promise<TPostMediaThumbnails> =>
  new Promise((resolve, reject) => {
    let fileReader = new FileReader()
    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type || fileType })
      const url = URL.createObjectURL(blob)
      const video = document.createElement('video')
      video.addEventListener('loadeddata', () => {
        video.currentTime = offsetMs === 0 ? 0 : offsetMs / 1000
      })

      video.addEventListener('seeked', async () => {
        let canvas = document.createElement('canvas')
        canvas.width = video.videoWidth / divider
        canvas.height = video.videoHeight / divider
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
        const image = canvas.toDataURL()
        resolve({ filename: file.name, thumbnail: image })
        URL.revokeObjectURL(url)
      })

      video.preload = 'metadata'
      video.src = url
      video.muted = true
      video.play().catch(e => console.log('Error playing video in getThumbnail', e))
    }
    fileReader.readAsArrayBuffer(file)
  })

/**
 * 画像の width, height を取得します。
 *
 * @param img
 */
export const getImageWH = (img: string): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    let image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = () => {
      resolve({ width: image.naturalWidth, height: image.naturalHeight })
    }
    image.onerror = (error: any) => reject(error)
    imageUrlToBase64({ imgUrl: img, getNew: true }).then(img => (image.src = img))
  })

export const dataURLtoFile = async (base64: string, filename: string): Promise<File> => {
  if (!base64 || !filename) throw Error('Error on data url to file')
  const arr = base64.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

/**
 * File を base64 へ変換します。
 *
 * @param file
 */
export const convertFileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(`${reader.result}`)
    reader.onerror = error => reject(error)
  })

/**
 * 画像のリサイズを行います。
 * img param は base64、fileUrl に対応
 *
 * @param img
 * @param maxLength
 */
export const resizeImage = (img: string, maxLength: number): Promise<string> =>
  new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    let image = new Image()
    image.crossOrigin = 'Anonymous'

    try {
      image.onload = () => {
        let dstWidth: number, dstHeight: number
        if (image.width > image.height) {
          dstWidth = maxLength
          dstHeight = (image.height * maxLength) / image.width
        } else {
          dstHeight = maxLength
          dstWidth = (image.width * maxLength) / image.height
        }
        canvas.width = dstWidth
        canvas.height = dstHeight
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, dstWidth, dstHeight)
        resolve(canvas.toDataURL())
      }
      imageUrlToBase64({ imgUrl: img, getNew: true }).then(img => (image.src = img))
    } catch {
      reject(new Error('fail resize image'))
    }
  })

type ImageUrlToBase64 = { imgUrl: string; provider?: TSnses; getNew?: boolean }
export const imageUrlToBase64 = async ({ imgUrl, provider, getNew }: ImageUrlToBase64): Promise<string> => {
  return isDataUri(imgUrl)
    ? imgUrl
    : fetch(`${imgUrl}${getNew && provider !== 'twitter' ? `?${encodeURIComponent(dayjs().toISOString())}` : ''}`)
        .then(response => response.blob())
        .then(
          blob =>
            new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(`${reader.result}`)
              reader.onerror = reject
              reader.readAsDataURL(blob)
            })
        )
}

type ImageUrlToFile = { imgUrl: string; filename: string; provider?: TSnses; getNew?: boolean }
export const imageUrlToFile = async ({ imgUrl, filename, provider, getNew }: ImageUrlToFile) =>
  imageUrlToBase64({ imgUrl, provider, getNew })
    .then(base64 => dataURLtoFile(base64, filename))
    .catch(e => console.error('Error image url to file: ', e))

export const getIGImageMaxWH = (img: any, maxLength: any = SNS.instagram.media.width) =>
  new Promise<{ width: number; height: number }>((resolve, reject) => {
    let dstWidth: number, dstHeight: number
    try {
      if (img.width > img.height && img.width / img.height > 1.91) {
        // 横長の画像で width:height = 1.91:1 の比率を超える場合は 1.91:1比率の height を求める
        dstWidth = maxLength
        dstHeight = maxLength * (1 / 1.91)
      } else if (img.width < img.height && img.width / img.height < 0.8) {
        // 縦長の画像で width:height = 4:5 の比率を超える場合は 4:5比率の height を求める
        dstWidth = maxLength
        dstHeight = maxLength * (5 / 4)
      } else if (img.width === img.height) {
        // 正方形画像の場合は縦横を maxLength に
        dstWidth = maxLength
        dstHeight = maxLength
      } else {
        dstWidth = maxLength
        dstHeight = (img.height * maxLength) / img.width
      }
      const result = {
        width: round(dstWidth, 0),
        height: round(dstHeight, 0)
      }
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })

export const getIGImageReelMaxWH = (img: any, maxLength: any = SNS.instagramReel.media.width) =>
  new Promise<{ width: number; height: number }>((resolve, reject) => {
    let dstWidth: number, dstHeight: number
    try {
      if (img.width > img.height && img.width / img.height > 1.91) {
        // 横長の画像で width:height = 1.91:1 の比率を超える場合は 1.91:1比率の height を求める
        dstWidth = maxLength
        dstHeight = maxLength * (1 / 1.91)
      } else if (img.width < img.height && img.width / img.height < 0.8) {
        // 縦長の画像で width:height = 4:5 の比率を超える場合は 4:5比率の height を求める
        dstWidth = maxLength
        dstHeight = maxLength * (5 / 4)
      } else if (img.width === img.height) {
        // 正方形画像の場合は縦横を maxLength に
        dstWidth = maxLength
        dstHeight = maxLength
      } else {
        dstWidth = maxLength
        dstHeight = (img.height * maxLength) / img.width
      }
      const result = {
        width: round(dstWidth, 0),
        height: round(dstHeight, 0)
      }
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })

/**
 * NOTE DEPRECATED
 * 一つ↓にある getVideoMeta 使用を推奨
 */
export const getVideoDuration = (file: File | Blob) =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.ondurationchange = () => {
      try {
        resolve(video.duration)
      } catch (e) {
        reject(e)
      } finally {
        URL.revokeObjectURL(video.src)
      }
    }
  })

/**
 * video のメタ情報を取得する
 * video.videoWidth, video.videoHeight, video.duration etc...
 *
 * @param {(File | Blob)} file
 */
type GetVideoMeta = {
  file?: File | Blob
}
export const getVideoMeta = ({ file }: RequireOne<GetVideoMeta>) =>
  new Promise<HTMLVideoElement>((resolve, reject) => {
    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.ondurationchange = () => {
      try {
        resolve(video)
      } catch (e) {
        reject(e)
      } finally {
        URL.revokeObjectURL(video.src)
      }
    }
  })

type TCheckMedia = RequireOne<{
  filename?: string
  mimeType?: string
}>
export const isImage = ({ filename, mimeType }: TCheckMedia): boolean => {
  return filename
    ? /^.+\.(?:jpe?g|png|gif|tiff|bmp)(?:$|\?)/i.test(filename)
    : /^image\/(?:jpeg|png|gif|tiff|bmp)$/.test(mimeType)
}
export const isImageWithoutGif = ({ filename, mimeType }: TCheckMedia): boolean => {
  return filename ? /^.+\.(?:jpe?g|png)(?:$|\?)/i.test(filename) : /^image\/(?:jpeg|png)$/.test(mimeType)
}
export const isGif = ({ filename, mimeType }: TCheckMedia): boolean => {
  return filename ? /^.+\.gif(?:$|\?)/i.test(filename) : /^image\/gif$/.test(mimeType)
}
export const isVideo = ({ filename, mimeType }: TCheckMedia): boolean => {
  return filename ? /^.+\.(?:mp4|mov)(?:$|\?)/i.test(filename) : /^video\/(?:mp4|quicktime)$/.test(mimeType)
}

export const getMimeTypeFromFileName = (filename: string) =>
  /\.(?:jpg|jpeg)$/i.test(filename)
    ? 'image/jpeg'
    : /\.png$/i.test(filename)
    ? 'image/png'
    : /\.gif$/i.test(filename)
    ? 'image/gif'
    : /\.mp4$/i.test(filename)
    ? 'image/mp4'
    : /\.mov/i.test(filename)
    ? 'video/quicktime'
    : ''

export const isDataUri = (url: string): boolean => /^data:[a-z0-9\\/\-.+]+?;base64,/.test(url)

export const mediaCountValidator = (length: number, provider: TSnses): string | boolean => {
  let mediaMax = 0
  switch (provider) {
    case 'twitter':
      mediaMax = SNS.twitter.media.max
      break
    case 'instagram':
      mediaMax = SNS.instagram.media.max
      break
    case 'facebook':
      mediaMax = SNS.facebook.media.max
  }
  if (length > mediaMax) return `添付できるメディアは${mediaMax}枚までです。`
  return false
}

export const convertJsonStringMediaToArray = (postMedia: string | TPostFormValuesPostMediaRelations[]) => {
  if (Array.isArray(postMedia)) return postMedia
  if (postMedia === "") return []
  return JSON.parse(postMedia) as []
}
