import { Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { OauthActions } from '../actions/Oauth'
import { TResponseCommon } from 'src/modules/entity/API'
import { Federate, FederateFacebook, VerifyUrls } from '../models/OauthInfo'

type TOauthState = TResponseCommon & {
  verifyUrls: VerifyUrls
  federate: Federate
  federateFacebook: FederateFacebook
}
export class OauthState extends Record<TOauthState>({
  verifyUrls: new VerifyUrls(),
  federate: new Federate(),
  federateFacebook: new FederateFacebook(),
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const OauthReducer = reducerWithInitialState(new OauthState())
  .case(OauthActions.initializeOauth, (state, payload) => new OauthState())

  .cases([OauthActions.getFederate, OauthActions.getFederateFacebook, OauthActions.getVerifyUrls], (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )

  .case(OauthActions.setVerifyUrls, (state, payload) =>
    state.withMutations(s =>
      s
        .set('verifyUrls', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(OauthActions.setFederate, (state, payload) =>
    state.withMutations(s =>
      s
        .set('federate', payload)
        .set('error', null)
        .set('loading', false)
        .set('succeeded', true)
    )
  )
  .case(OauthActions.setFederateFacebook, (state, payload) =>
    state.withMutations(s =>
      s
        .set('federateFacebook', payload)
        .set('error', null)
        .set('loading', false)
        .set('succeeded', true)
    )
  )
  .case(OauthActions.setFederateClear, (state, payload) =>
    state.withMutations(s =>
      s
        .set('federate', null)
        .set('error', null)
        .set('loading', false)
    )
  )

  .case(OauthActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(OauthActions.setError, (state, payload) =>
    state.withMutations(s =>
      s
        .set('error', payload)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(OauthActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
export default OauthReducer
