import { useEffect } from 'react'
import { useQuery, UseQueryOptions, UseQueryResult, useQueryClient, FetchQueryOptions } from 'react-query'
import useShallowEqualSelector from 'src/hooks/useShallowEqualSelector'
import {
  FetchUsersVariables,
  DeleteUserVariables,
  UpdateUserBrandRoleMetaVariables,
  UpdateUserBrandRoleVariables,
  UpdateUserMetaVariables,
  UpdateUserNameVariables,
  UsersFetchQuery,
  ExcludeUserVariables
} from 'src/modules/entity/user'
import { TUseQueryOptions, TApiHeadersCommon } from 'src/modules/entity/API'
import { PAGING_SIZE } from 'src/constants/query'
import { DEFAULT_QUERY_OPTIONS } from 'src/constants/query'
import { CommonApi } from 'src/redux/api/commonApi'
import { useMutationWrapper } from 'src/modules/core/mutation'

export class UsersApi extends CommonApi {
  static fetch = (params: FetchUsersVariables, headers: TApiHeadersCommon) =>
    UsersApi.get<UsersFetchQuery>('/users', params, headers)
  static remove = (params: DeleteUserVariables, headers: TApiHeadersCommon) =>
    UsersApi.delete(`/users/${params.id}`, params, headers)
  static updateUserBrandRole = (params: UpdateUserBrandRoleVariables, headers: TApiHeadersCommon) =>
    UsersApi.post('/user_brand_roles', params, headers)
  static updateUserBrandRoleMeta = (params: UpdateUserBrandRoleMetaVariables, headers: TApiHeadersCommon) =>
    UsersApi.post('/user_brand_roles/meta', params, headers)
  static updateUserName = (params: UpdateUserNameVariables, headers: TApiHeadersCommon) => {
    const { id, ...rest } = params
    return UsersApi.post(`/users/${id}/name`, rest, headers)
  }
  static updateUserMeta = (params: UpdateUserMetaVariables, headers: TApiHeadersCommon) => {
    const { id, ...rest } = params
    return UsersApi.post(`/users/${id}/meta`, rest, headers)
  }
  static exclude = (params: ExcludeUserVariables, headers: TApiHeadersCommon) =>
    UsersApi.post('/user_brand_roles/expulsion', params, headers)
}

export const useUsersFetchQuery = (
  query?: FetchUsersVariables,
  opts?: UseQueryOptions,
  key?: (string | number | boolean | {})[]
): UseQueryResult<
  { data?: UsersFetchQuery; headers?: { 'content-length': string; 'content-type': string; 'x-max-size': string } },
  any
> => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  return useQuery(Array.isArray(key) ? ['fetchUsers', ...key] : 'fetchUsers', () => UsersApi.fetch(query, { token }), {
    ...opts
  })
}

export const useAllUsersQuery = ({
  key,
  options
}: TUseQueryOptions<FetchUsersVariables>): UseQueryResult<{ data?: UsersFetchQuery }> => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  const currentBrand = useShallowEqualSelector(state => state.Auth.getIn(['user', 'currentBrand'])).toObject()
  const brandId = currentBrand?.id
  const queryKey = Array.isArray(key) ? ['allUsers', { brandId }, ...key] : ['allUsers', { brandId }]
  return useQuery(
    queryKey,
    async () => {
      const status: { page: number; users: UsersFetchQuery; hasMore: boolean } = {
        page: 1,
        users: [],
        hasMore: true
      }
      while (status.hasMore) {
        const paging = { page: status.page, size: PAGING_SIZE.sm }
        const res = await UsersApi.fetch({ paging }, { token })
        status.users = [...status.users, ...res.data]
        status.page++
        status.hasMore =
          hasProperty(res.headers, 'x-max-size') &&
          typeof res.headers['x-max-size'] === 'string' &&
          +res.headers['x-max-size'] > status.users.length
      }
      return { data: status.users }
    },
    { ...DEFAULT_QUERY_OPTIONS, ...options }
  )
}
const hasProperty = <K extends string>(x: unknown, name: K): x is { [M in K]: unknown } => {
  return x instanceof Object && name in x
}

export const useUserPreFetchQuery = (
  deps: { brandId: number; maxNum: number; page: number },
  query?: FetchUsersVariables,
  opts?: FetchQueryOptions,
  key?: (string | number | boolean | {})[],
  waitFor?: number
) => {
  const token: string = useShallowEqualSelector(state => state.Auth.getIn(['user', 'token']))
  const queryClient = useQueryClient()
  useEffect(() => {
    const prefetch = async () => {
      await new Promise(r => setTimeout(r, waitFor || 1000))
      queryClient.prefetchQuery(
        Array.isArray(key) ? ['fetchUsers', ...key] : 'fetchUsers',
        () => UsersApi.fetch(query || { paging: { page: deps.page + 1 } }, { token }),
        { ...opts }
      )
    }
    if (deps.page + 1 <= deps.maxNum) {
      prefetch()
    }
  }, [deps.maxNum, deps.page, deps.brandId, opts, query, queryClient, token, key, waitFor])
}
export const useUserExcludeMutation = () => {
  return useMutationWrapper<ExcludeUserVariables>({
    req: (payload, { token }) => UsersApi.exclude(payload, { token })
  })
}
export const useUserUpdateBrandRoleMutation = () => {
  return useMutationWrapper<UpdateUserBrandRoleVariables>({
    req: (payload, { token }) => UsersApi.updateUserBrandRole(payload, { token })
  })
}
export const useUserUpdateBrandRoleMetaMutation = () => {
  return useMutationWrapper<UpdateUserBrandRoleMetaVariables>({
    req: (payload, { token }) => UsersApi.updateUserBrandRoleMeta(payload, { token })
  })
}
export const useUserUpdateNameMutation = () => {
  return useMutationWrapper<UpdateUserNameVariables>({
    req: (payload, { token }) => UsersApi.updateUserName(payload, { token })
  })
}
export const useUserUpdateMetaMutation = () => {
  return useMutationWrapper<UpdateUserMetaVariables>({
    req: (payload, { token }) => UsersApi.updateUserMeta(payload, { token })
  })
}
