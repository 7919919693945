import { CommonApi } from './commonApi'
import { TApiHeadersCommon } from 'src/modules/entity/API'
import { TAbolishOrder, TAccountsOrder } from '../models/InsightInfo'

export class InsightApi extends CommonApi {
  static accounts = (params: TAccountsOrder, headers: TApiHeadersCommon) =>
    InsightApi.get('/api/accounts', params, headers)
  static abolish = (params: TAbolishOrder, headers: TApiHeadersCommon) =>
    InsightApi.post('/sns_accounts/abolish', params, headers)
}
