import { Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DaterangeActions } from '../actions/DateRange'
import { TResponseCommon } from 'src/modules/entity/API'
import { DateRange, TDateRange } from '../models/DaterangeInfo'

type TDaterangeState = TResponseCommon & {
  daterange: TDateRange
  refresh: boolean
}
export class DaterangeState extends Record<TDaterangeState>({
  daterange: new DateRange(),
  refresh: false,
  loading: false,
  error: null,
  succeeded: false,
  notified: true
}) {}

const DaterangeReducer = reducerWithInitialState(new DaterangeState())
  .case(DaterangeActions.initializeDateRange, (state, payload) => new DaterangeState())

  .case(DaterangeActions.getDateRange, (state, payload) =>
    state.withMutations(s => s.set('error', null).set('loading', true))
  )
  .case(DaterangeActions.setDateRange, (state, payload) =>
    state.withMutations(s =>
      s
        .set('daterange', payload)
        .set('error', null)
        .set('loading', false)
    )
  )
  .case(DaterangeActions.setSucceeded, (state, payload) =>
    state.withMutations(s =>
      s
        .set('succeeded', payload)
        .set('error', null)
        .set('loading', false)
        .set('notified', false)
    )
  )
  .case(DaterangeActions.setNotified, (state, payload) => state.withMutations(s => s.set('notified', payload)))
export default DaterangeReducer
