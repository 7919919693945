import { Record } from 'immutable'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Medias, MediasActions } from '../actions/Medias'

type TMediasState = {
  medias: Medias[]
}

export class MediasState extends Record<TMediasState>({
  medias: []
}) {}

const MediasReducer = reducerWithInitialState(new MediasState()).case(MediasActions.setMedias, (state, payload) =>
  state.withMutations(s => s.set('medias', payload))
)
export default MediasReducer
