import React from 'react'
import { createPortal } from 'react-dom'
import { ToastComponent, TNotification } from 'src/components/Toast/ToastComponent'
import { existObject } from 'src/util/object'

type Props = {
  toasts: TNotification[]
  children: React.ReactNode
}
const ToastContainer: React.VFC<Props> = ({ toasts, children }) => {
  return createPortal(
    <>
      {existObject(toasts) && toasts.map((toast: TNotification) => <ToastComponent key={toast.id} toast={toast} />)}
      {children}
    </>,
    document.body
  )
}

export default ToastContainer
