import { TCalendar } from '../models/CalendarInfo'
import actionCreatorFactory from 'typescript-fsa'
import { List } from 'immutable'
import { TError } from 'src/modules/entity/API'
const actionCreator = actionCreatorFactory('CALENDER')

/***
 * get for saga
 * set for reducer
 */
export const CalendarActions = {
  initializeCalendar: actionCreator('INITIALIZE_CALENDER'),

  setCalendar: actionCreator<List<TCalendar>>('SET_CALENDER'),
  setDisplayTodo: actionCreator<boolean>('SET_DISPLAY_TODO'),
  setTargetDay: actionCreator<string>('SET_TARGET_DAY'),
  setInitial: actionCreator<boolean>('SET_INITIAL'),
  updateByScroll: actionCreator<boolean>('UPDATE_BY_SCROLL'),
  setCalendarRefresh: actionCreator<boolean>('SET_CALENDER_REFRESH'),

  setLoading: actionCreator<boolean>('SET_LOADING'),
  setError: actionCreator<TError>('SET_ERROR'),
  setSucceeded: actionCreator<boolean>('SET_SUCCEEDED'),
  setNotified: actionCreator<boolean>('SET_NOTIFIED')
}
